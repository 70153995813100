import React, { useState } from "react";
import {
  FAQContainer,
  FAQWrapper,
  FAQItem,
  IconWrapper,
  TitleWrapper,
  DetailWrapper,
  FAQItems,
  FAQTitle,
  FAQText,
} from "../../styles/common/about_us/FAQ.styles";
import Collapse from "@mui/material/Collapse";
import FaqArrowUp from "../../assets/faq/faq-arrow-up.svg";
import FaqArrowDown from "../../assets/faq/faq-arrow-down.svg";
import { faqData } from "../../data/data";

function FAQ() {
  const [expanded, setExpanded] = useState(false);
  const [expandedId, setExpandedId] = useState(null);

  return (
    <FAQContainer>
      <FAQWrapper>
        <FAQTitle>Explora Los Servicios</FAQTitle>
        <br />
        {/* <FAQText>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sit
          amet placerat ipsum, ac vulputate mi. Praesent risus velit, facilisis
          vel massa id,
        </FAQText> */}
        <FAQItems>
          {faqData.map(({ id, title, detail, icon }, index) => (
            <FAQItem
              key={id}
              onClick={() => {
                setExpanded(expandedId === index && expanded ? false : true);
                setExpandedId(index);
              }}
            >
              <TitleWrapper>
                <IconWrapper>
                  <img src={icon} alt="icon-1" />
                  <h1>{title}</h1>
                </IconWrapper>
                {expandedId === index && expanded ? (
                  <img src={FaqArrowUp} alt="arrow" />
                ) : (
                  <img src={FaqArrowDown} alt="arrow" />
                )}
              </TitleWrapper>

              <Collapse
                in={expandedId === index && expanded}
                timeout="auto"
                unmountOnExit
              >
                <DetailWrapper>{detail}</DetailWrapper>
              </Collapse>
            </FAQItem>
          ))}
        </FAQItems>
      </FAQWrapper>
    </FAQContainer>
  );
}

export default FAQ;
