import { api } from "../utils/api";
import { apiRoutes } from "../constant.routes";
import { useFetch } from "../utils/reactQuery";

export const useGetServiceProfile = (id) => {
  const context = useFetch(
    apiRoutes.apiPrefix +
      apiRoutes.service.path +
      apiRoutes.service.profile +
      `/${id}`,
    {},
    { retry: false }
  );
  return { ...context, data: context.data?.service };
};

export const useGetService = (id) => {
  const context = useFetch(
    apiRoutes.apiPrefix + apiRoutes.service.path + apiRoutes.service.get + id,
    {},
    { retry: false }
  );

  return { ...context, data: context.data?.service };
};

export const useGetServices = (query, service_id, obj) => {
  const context = useFetch(
    apiRoutes.apiPrefix +
      apiRoutes.service.path +
      apiRoutes.service.list +
      "/" +
      service_id +
      query,
    { retry: false, ...obj }
  );
  return { ...context, data: context.data?.services };
};

export const useGetServicesManager = () => {
  const context = useFetch(
    apiRoutes.apiPrefix + apiRoutes.service.path + apiRoutes.service.manager,
    {},
    { retry: false }
  );
  return { ...context, data: context.data?.services };
};

export const createService = (body) =>
  api.post(
    apiRoutes.apiPrefix + apiRoutes.service.path + apiRoutes.service.create,
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const updateService = (id, body) =>
  api.put(
    apiRoutes.apiPrefix +
      apiRoutes.service.path +
      apiRoutes.service.update +
      `/${id}`,
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
