import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";

function PaymenCancel() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, []);

  return (
    <Fragment>
      <Navbar />

      <Footer />
    </Fragment>
  );
}

export default PaymenCancel;
