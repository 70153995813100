import { apiRoutes } from "../constant.routes";
import { api } from "../utils/api";

export const createOrder = (body) =>
  api.post(
    apiRoutes.apiPrefix +
      apiRoutes.payment.path +
      apiRoutes.payment.createOrder,
    body
  );

export const captureOrder = (query) =>
  api.get(
    apiRoutes.apiPrefix +
      apiRoutes.payment.path +
      apiRoutes.payment.captureOrder +
      query
  );

export const withdrawPaypal = (body) =>
  api.post(
    apiRoutes.apiPrefix +
      apiRoutes.payment.path +
      apiRoutes.payment.withdrawPaypal,
    body
  );
