import React, { Fragment, useState } from "react";
import { AuthWrapper } from "../styles/auth/Auth.styles";
import {
  AuthContainer,
  FormContainer,
  InputWrapper,
  InputContainer,
  ContinueBtn,
  IconsContainer,
  IconWrapper,
  SignupText,
  JoinText,
  BackWrapper,
} from "../styles/auth/Auth.styles";
import ViewIcon from "../assets/icons/view.svg";
import { Link, useNavigate } from "react-router-dom";
import FacebookIcon from "../assets/social/facebook.svg";
import AppleIcon from "../assets/social/apple.svg";
import TwitterIcon from "../assets/social/twitter.svg";
import GoogleIcon from "../assets/social/google.png";
import BackIcon from "../assets/icons/arrow-left-account.svg";
import { register, useUser } from "../api/auth";
import { toast } from "react-toastify";
import { app } from "../utils/config";

function Register() {
  const [passwordType, setPasswordType] = useState(false);
  const [isCotinue, setIsContinue] = useState(false);
  const [signupFields, setSignupFields] = useState({
    name: "",
    email: "",
    password: "",
    matchPassword: "",
  });
  const navigate = useNavigate();
  const { refetch } = useUser();

  const onChangeHandler = (e) => {
    setSignupFields({
      ...signupFields,
      [e.target.name]: e.target.value,
    });
  };

  const togglePasswordType = () => {
    setPasswordType(!passwordType);
  };

  const onContinue = () => {
    setIsContinue(true);
  };
  const onBack = () => {
    setIsContinue(false);
  };

  const onSubmit = async () => {
    if (signupFields.password !== signupFields.matchPassword) {
      return toast.warning("Las contraseñas no coinciden.");
    }

    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(
        signupFields.password
      )
    ) {
      return toast.warning("Las contraseña no es válida.");
    }

    try {
      const resp = await register(signupFields);
      if (resp.data.user) {
        localStorage.setItem("token", resp.data.user.access_token);
        localStorage.setItem("refresh_token", resp.data.user.refresh_token);
        refetch();
        // updateUser();
        navigate("/");
      } else {
      }
    } catch (err) {
      const status = err.response.status;
      if (status === 400) {
        toast.error("Faltan agregar algunos campos.");
      }
      if (status === 500) {
        toast.error("El correo ya existe.");
      }
    }
  };

  const { name, email, password, matchPassword } = signupFields;

  return (
    <Fragment>
      {!isCotinue && (
        <AuthContainer>
          <AuthWrapper>
            <h1>Únete a BeeBuk</h1>
            <FormContainer>
              <InputContainer>
                <label>Nombre de usuario </label>
                <InputWrapper>
                  <input
                    type="text"
                    placeholder="Crear nombre de usuario"
                    name="name"
                    value={name}
                    onChange={onChangeHandler}
                    autoComplete={"off"}
                  />
                </InputWrapper>
              </InputContainer>
              <InputContainer sx={{ mt: "1.5rem" }}>
                <label>Correo electrónico </label>
                <InputWrapper>
                  <input
                    type="email"
                    placeholder="Escribe tu correo electrónico"
                    name="email"
                    value={email}
                    onChange={onChangeHandler}
                    autoComplete={"off"}
                  />
                </InputWrapper>
              </InputContainer>

              <ContinueBtn sx={{ mt: "1.5rem" }} onClick={onContinue}>
                Continuar
              </ContinueBtn>
              <SignupText>
                Al unirme, acepto recibir correos electrónicos de BeeBuk.
              </SignupText>
            </FormContainer>
            <p className="sigin-with">O regístrate con</p>
            <IconsContainer>
              <a href={`${app.serverURL}/${app.apiURL}/auth/facebook`}>
                <IconWrapper>
                  <img src={FacebookIcon} alt="facebook-icon" />
                </IconWrapper>
              </a>
              <a href={`${app.serverURL}/${app.apiURL}/auth/google`}>
                <IconWrapper>
                  <img src={GoogleIcon} alt="google-icon" />
                </IconWrapper>
              </a>

              {/* <IconWrapper>
            <img src={AppleIcon} alt="apple-icon" />
          </IconWrapper> */}

              <a href={`${app.serverURL}/${app.apiURL}/auth/twitter`}>
                <IconWrapper>
                  <img src={TwitterIcon} alt="twitter-icon" />
                </IconWrapper>
              </a>
            </IconsContainer>
            <Link to="/login" style={{ textDecoration: "none" }}>
              <p className="sigin-with">
                Ya tienes una cuenta ? <span>Inicia sesión</span>
              </p>
            </Link>
          </AuthWrapper>
        </AuthContainer>
      )}

      {isCotinue && (
        <AuthContainer>
          <AuthWrapper>
            <BackWrapper>
              <img src={BackIcon} alt="back" onClick={onBack} />
              <h1>Únete a BeeBuk</h1>
            </BackWrapper>
            <FormContainer>
              <InputContainer>
                <label>Contraseña </label>
                <InputWrapper>
                  <input
                    type={passwordType ? "text" : "password"}
                    placeholder="Crear contraseña"
                    name="password"
                    value={password}
                    onChange={onChangeHandler}
                  />
                  <img
                    src={ViewIcon}
                    alt="view-icon"
                    onClick={togglePasswordType}
                  />
                </InputWrapper>
              </InputContainer>
              <InputContainer sx={{ mt: "1.5rem" }}>
                <label>Escriba de nuevo su contraseña </label>
                <InputWrapper>
                  <input
                    type={passwordType ? "text" : "password"}
                    placeholder="Escribe tu correo electrónico"
                    name="matchPassword"
                    value={matchPassword}
                    onChange={onChangeHandler}
                  />
                  <img
                    src={ViewIcon}
                    alt="view-icon"
                    onClick={togglePasswordType}
                  />
                </InputWrapper>
              </InputContainer>

              <SignupText sx={{ textAlign: "justify" }}>
                8 caracteres o más. Combina letras mayúsculas y minúsculas y
                números.
              </SignupText>
              <ContinueBtn sx={{ mt: "1.5rem" }} onClick={onSubmit}>
                Únete
              </ContinueBtn>
            </FormContainer>
            <JoinText>
              Al unirte, aceptas los Términos de Comunidad de BeeBuk, así como
              recibir correos electrónicos ocasionales de nuestra parte.
            </JoinText>

            <Link to="/login" style={{ textDecoration: "none" }}>
              <p className="sigin-with">
                Ya tienes una cuenta ? <span>Inicia sesión</span>
              </p>
            </Link>
          </AuthWrapper>
        </AuthContainer>
      )}
    </Fragment>
  );
}

export default Register;
