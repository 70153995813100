import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@emotion/react";
import {
  TableHeading,
  TablePricing,
  TableText,
  SelectBtn,
} from "../../styles/common/profile/PricingTable.styles";
import TickIcon from "../../assets/icons/tick.svg";
import { useNavigate } from "react-router";

function createData(name, basic, standard, premium) {
  return { name, basic, standard, premium };
}

// const rows = [
//   createData(
//     "Usuario describe",
//     <img src={TickIcon} alt="tick-icon" />,
//     <img src={TickIcon} alt="tick-icon" />,
//     <img src={TickIcon} alt="tick-icon" />
//   ),
//   createData(
//     "Usuario describe",
//     <img src={TickIcon} alt="tick-icon" />,
//     <img src={TickIcon} alt="tick-icon" />,
//     <img src={TickIcon} alt="tick-icon" />
//   ),
//   createData(
//     "Usuario describe",
//     "",
//     <img src={TickIcon} alt="tick-icon" />,
//     <img src={TickIcon} alt="tick-icon" />
//   ),
//   createData(
//     "Usuario añadirá filas de descripción que necesite pero solo podrá ofrecer 3 planes (básico, standard y premium)",
//     "",
//     "",
//     <img src={TickIcon} alt="tick-icon" />
//   ),
//   createData(
//     "Actions",
//     <SelectBtn>Select</SelectBtn>,
//     <SelectBtn>Select</SelectBtn>,
//     <SelectBtn>Select</SelectBtn>
//   ),
// ];

export default function BasicTable({ plans, plansObj }) {
  const [price, setPrice] = useState({});
  const [rowPlans, setRowPlans] = useState([]);

  const theme = useTheme();
  const { primary } = theme.palette.colors;
  const { header, tableBg } = theme.palette.pricing;
  const navigate = useNavigate();

  const borderBottomRight = {
    borderBottom: `1px solid ${primary}`,
    borderRight: `1px solid ${primary}`,
  };
  const borderTopRightLeft = {
    borderTop: `1px solid ${primary}`,
    borderRight: `1px solid ${primary}`,
    borderLeft: `1px solid ${primary}`,
  };

  useEffect(() => {
    const rowPlansData = plans.map((feat) => {
      if (feat.id === "price") {
        setPrice({
          basic: feat.basic,
          standard: feat.standard,
          premium: feat.premium,
        });
      }
      if (feat.id !== "price") {
        if (feat.id === "delivery_time") {
          return createData(feat.name, feat.basic, feat.standard, feat.premium);
        }

        return createData(
          feat.name,
          feat.basic ? <img src={TickIcon} alt="tick-icon" /> : "",
          feat.standard ? <img src={TickIcon} alt="tick-icon" /> : "",
          feat.premium ? <img src={TickIcon} alt="tick-icon" /> : ""
        );
      }
    });

    setRowPlans(rowPlansData);
  }, [plans]);

  const rows = [
    ...rowPlans,
    createData(
      "Actions",
      <SelectBtn onClick={() => navigate("/checkout/" + plansObj.basic._id)}>
        Select
      </SelectBtn>,
      <SelectBtn onClick={() => navigate("/checkout/" + plansObj.standard._id)}>
        Select
      </SelectBtn>,
      <SelectBtn onClick={() => navigate("/checkout/" + plansObj.premium._id)}>
        Select
      </SelectBtn>
    ),
  ];

  return (
    <TableContainer sx={{ ...borderTopRightLeft, borderRadius: ".5rem" }}>
      <Table sx={{ minWidth: 550 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ background: header }}>
            <TableCell
              sx={{
                ...borderBottomRight,
              }}
            >
              <TableHeading>Plans</TableHeading>
            </TableCell>
            <TableCell
              align="left"
              sx={{
                ...borderBottomRight,
                minWidth: "100px",
              }}
            >
              <TableHeading>BÁSICO</TableHeading>
              <TablePricing>US ${price?.basic}</TablePricing>
            </TableCell>
            <TableCell
              align="left"
              sx={{
                ...borderBottomRight,
                minWidth: "100px",
              }}
            >
              <TableHeading>STANDARD</TableHeading>
              <TablePricing>US ${price?.standard}</TablePricing>
            </TableCell>
            <TableCell
              align="left"
              sx={{
                borderBottom: `1px solid ${primary}`,
                minWidth: "100px",
              }}
            >
              <TableHeading>PREMIUM</TableHeading>
              <TablePricing>US ${price?.premium}</TablePricing>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(
            (row, index) =>
              row && (
                <TableRow
                  key={index}
                  sx={{ background: index % 2 === 0 ? "transparent" : tableBg }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ ...borderBottomRight }}
                  >
                    <TableText>{row.name}</TableText>
                  </TableCell>
                  <TableCell align="center" sx={{ ...borderBottomRight }}>
                    <TableText>{row.basic}</TableText>
                  </TableCell>
                  <TableCell align="center" sx={{ ...borderBottomRight }}>
                    <TableText>{row.standard}</TableText>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderBottom: `1px solid ${primary}` }}
                  >
                    <TableText>{row.premium}</TableText>
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
