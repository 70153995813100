import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  PaymentContainer,
  PaymentWrapper,
} from "../../styles/common/payment/Payment.styles.";
import { captureOrder } from "../../api/payment";

const Success = () => {
  const location = useLocation();
  const queryLocation = location.search;

  useEffect(() => {
    async function fetchSession() {
      const response = captureOrder(queryLocation);
    }
    fetchSession();
  }, [queryLocation]);
  return (
    <PaymentContainer>
      <PaymentWrapper>
        <h1>Tu pago fue exitoso</h1>
      </PaymentWrapper>
    </PaymentContainer>
  );
};

export default Success;
