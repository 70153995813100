import React, { Fragment } from "react";
import OrderDetail from "../common/orders/OrderDetail";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";

function Order() {
  return (
    <Fragment>
      <Navbar />
      <OrderDetail />
      <Footer margintop="7rem" />
    </Fragment>
  );
}

export default Order;
