import React from "react";
// import {
//   OrderCardContainer,
//   UserDetailContainer,
//   DetailWrapper,
//   StatusBox,
//   OrderBtn,
// } from "../../styles/components/cards/OrderCard.styles";
import {
  ServiceManagerContainer,
  DetailWrapper,
  ServiceBtn,
  ServiceContent,
} from "../../styles/components/cards/ServicesManagerCard.styles";
import { Link } from "react-router-dom";

function ServicesManagerCard({
  id,
  profilePic,
  userPic,
  userName,
  userLocation,
  price,
  dueIn,
  status,
  link,
  deliveryAt,
}) {
  return (
    <ServiceManagerContainer>
      <ServiceContent>
        <img src={profilePic} alt="profile-pic" />

        <DetailWrapper>
          <h1>{userName}</h1>
        </DetailWrapper>
      </ServiceContent>

      <Link to={link} style={{ textDecoration: "none" }}>
        <ServiceBtn>Editar Servicio</ServiceBtn>
      </Link>
    </ServiceManagerContainer>
  );
}

export default ServicesManagerCard;
