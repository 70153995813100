import React from "react";
import SearchIcon from "../../assets/icons/search.svg";
import { SearchInputContainer } from "../../styles/components/customs/SearchInput.styles";

function SearchInput({ showbg, showborder, handleChange }) {
  return (
    <SearchInputContainer showbg={showbg} showborder={showborder}>
      <input type="text" placeholder="Buscar" onChange={handleChange} />
      <img src={SearchIcon} alt="search-icon" />
    </SearchInputContainer>
  );
}

export default SearchInput;
