import React from "react";
import {
  OrderManagerContainer,
  OrderManagerWrapper,
  OrderHeader,
  OrderCardWrapper,
} from "../../styles/common/orders/OrderManager.styles";
import Filter from "../../components/customs/Filter";
import { filterData } from "../../data/data";
import { Divider } from "../../styles/Common.styles";
import OrderCard from "../../components/cards/OrderCard";
import { orderData } from "../../data/data";
import { useGetOrders } from "../../api/order";
import moment from "moment";
import { useUser } from "../../api/auth";
import { useNavigate } from "react-router";
import { app } from "../../utils/config";

const constant = {
  PROGRESS: "En progreso",
  COMPLETED: "Completado",
  DELIVERY: "Entregado",
};

function OrderManager() {
  const { data } = useGetOrders();
  const { user } = useUser();
  const navigate = useNavigate();

  const dueIn = (date, num) => {
    const time = moment(date).add(num, "days");
    const diffTime = time.diff(moment());
    const duration = moment.duration(diffTime);

    const days = duration.days();
    const hours = duration.hours();
    return `${days} D, ${hours}H`;
  };

  const own = user;

  const getImage = (image) => {
    console.log("image", image);
    if (image) {
      if (image.includes("http") || image.includes("https")) {
        return image;
      }
      return `${app.serverURL}/${image}`;
    }
  };

  if (!user) {
    return navigate("/");
  }

  if (!data && !user) {
    return;
  }

  return (
    <OrderManagerContainer>
      <OrderManagerWrapper>
        <OrderHeader>
          <h1>Tú administrador de pedidos</h1>
          {/* <Filter filterData={filterData} filterWidth="300px" /> */}
        </OrderHeader>
        <Divider />

        <OrderCardWrapper>
          {data &&
            data.map(
              ({
                _id,
                userName,
                userPic,
                profilePic,
                price,
                status,
                userLocation,
                link,
                created_at,
                service,
                user_id,
                seller_id,
                delivery_date,
              }) => (
                <OrderCard
                  key={_id}
                  id={_id}
                  profilePic={service && getImage(service.service_id.images[0])}
                  userPic={
                    service && own._id == user_id._id
                      ? getImage(service.service_id.userId.image_url)
                      : getImage(user_id.image_url)
                  }
                  userName={
                    service && own._id == user_id._id
                      ? service.service_id.userId.name
                      : user_id.name
                  }
                  price={service && "$" + service.price}
                  status={constant[status]}
                  dueIn={
                    status != "COMPLETED" &&
                    service.timelapse &&
                    dueIn(created_at, service.timelapse)
                  }
                  deliveryAt={
                    status == "COMPLETED" &&
                    moment(delivery_date).format("DD MMM")
                  }
                  userLocation={
                    service && own._id == user_id._id
                      ? service.service_id.userId.country
                      : user_id.country
                  }
                  link={"/orders/" + _id}
                />
              )
            )}
          {/* {orderData.map(
            ({
              id,
              userName,
              userPic,
              profilePic,
              price,
              status,
              dueIn,
              userLocation,
              link,
            }) => (
              <OrderCard
                key={id}
                id={id}
                profilePic={profilePic}
                userPic={userPic}
                userName={userName}
                price={price}
                status={status}
                dueIn={dueIn}
                userLocation={userLocation}
                link={link}
              />
            )
          )} */}
        </OrderCardWrapper>
      </OrderManagerWrapper>
    </OrderManagerContainer>
  );
}

export default OrderManager;
