import React from "react";
import UserProfilePic from "../../assets/images/user-profile-pic.svg";
import HeartIcon from "../../assets/icons/heart.svg";
import HeartIconSelected from "../../assets/icons/heart-selected.svg";
import {
  ProfileCardContainer,
  ProfileHeading,
  ProfileStatus,
  ContactContainer,
  ProfileDetail,
  LevelContainer,
  LevelBox,
  RatingContainer,
  StarsWrapper,
  ProfileCardDivider,
  DescriptionContainer,
  InfoWrapper,
  AboutContainer,
} from "../../styles/components/cards/ProfileCard.styles";
import StarIcon from "../../assets/icons/star.svg";
import { OutlineBtn } from "../../styles/Common.styles";
import moment from "moment";
import { updateFavorite } from "../../api/auth";
import { useUser } from "../../api/auth";
import { useNavigate } from "react-router";
import { app } from "../../utils/config";

function ProfileCard({ profile }) {
  const { refetch, user } = useUser();
  const navigate = useNavigate();

  const updateFavorites = async (user) => {
    await updateFavorite({ user_id: user });
    refetch();
  };

  const handleFavorites = (favorites, destination) => {
    if (user) {
      const selected = favorites.find((f) => f === destination);

      if (selected) {
        return (
          <div
            onClick={() => updateFavorites(destination)}
            style={{ cursor: "pointer" }}
          >
            <img src={HeartIconSelected} alt="heart-icon-selected" />
          </div>
        );
      } else {
        return (
          <div
            onClick={() => updateFavorites(destination)}
            style={{ cursor: "pointer" }}
          >
            <img src={HeartIcon} alt="heart-icon" />
          </div>
        );
      }
    } else {
      return (
        <div style={{ cursor: "pointer" }} onClick={() => navigate("/login")}>
          <img src={HeartIcon} alt="heart-icon" />
        </div>
      );
    }
  };

  const getImage = (image) => {
    if (image.includes("http") || image.includes("https")) {
      return image;
    }
    return `${app.serverURL}/${image}`;
  };

  return (
    <ProfileCardContainer>
      <ProfileHeading>
        <ProfileStatus>
          <img src={getImage(profile.user.image_url)} alt="profile-pic" />
          <ProfileDetail>
            <h1>{profile.user.name}</h1>
            <LevelContainer>
              <p>{profile.user.info}</p>
              {profile.user.level > 0 && (
                <LevelBox>Nivel {profile.user.level}</LevelBox>
              )}
            </LevelContainer>
            <RatingContainer>
              <StarsWrapper>
                <img src={StarIcon} alt="star" />
                <img src={StarIcon} alt="star" />
                <img src={StarIcon} alt="star" />
                <img src={StarIcon} alt="star" />
                <img src={StarIcon} alt="star" />
              </StarsWrapper>
              <p>{profile.reviewAvarage.stars}</p>
              <p>orders ({profile.order})</p>
            </RatingContainer>
          </ProfileDetail>
        </ProfileStatus>
        <ContactContainer>
          {handleFavorites(user?.favorites, profile.service.userId)}

          {/* <OutlineBtn>Contactar</OutlineBtn> */}
        </ContactContainer>
      </ProfileHeading>

      <ProfileCardDivider />

      <DescriptionContainer>
        <InfoWrapper>
          <p>País</p>
          <h1>{profile.user.country}</h1>
        </InfoWrapper>
        <InfoWrapper>
          <p>Miembro desde</p>
          <h1>{moment(profile.user.createdAt).format("DD MMM YYYY")}</h1>
        </InfoWrapper>
      </DescriptionContainer>

      <AboutContainer>
        <p>{profile.user.description}</p>
      </AboutContainer>
    </ProfileCardContainer>
  );
}

export default ProfileCard;
