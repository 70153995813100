import React, { Fragment } from "react";
import Create from "../common/servicios/Create";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";

function CreateService() {
  return (
    <Fragment>
      <Navbar />
      <Create edit={true} />
      <Footer margintop="7rem" />
    </Fragment>
  );
}

export default CreateService;
