import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnCenterCenter,
  flexColumnStartStart,
  flexRowCenterCenter,
  flexRowStartCenter,
} from "../../../common/scheme";
import { SecondaryHeading, Text } from "../../Common.styles";

export const ContactContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  padding: "7rem",
  position: "relative",
  overflow: "hidden",

  [theme.breakpoints.down("sm")]: {
    padding: "50px 4rem",
  },
}));
export const ContactWrapper = styled(Box)(({ theme }) => ({
  width: "1000px",
  zIndex: 2,
  ...flexColumnCenterCenter,
}));
export const ContactLayer1 = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  zIndex: 1,
  height: "100%",
  width: "100%",
  background: "rgba(255, 255, 255, 0.53)",
}));
export const ContactLayer2 = styled(Box)(({ theme }) => ({
  zIndex: 0,
  width: "1100px",
  height: "100%",
  opacity: "0.5",
  position: "absolute",
  top: 0,
  background: "rgba(255, 255, 255, 0.53)",

  "& .circle-1": {
    position: "absolute",
    width: 528,
    height: 528,
    left: 0,
    top: 131,
    background:
      "linear-gradient(180deg, #4DE3DE 0%, #D7FFF1 20.73%, #BFF59E 40.1%, #F8F0FB 65.62%, #FF8AC0 92.71%)",
    filter: "blur(143px)",
  },

  "& .circle-2": {
    position: "absolute",
    width: 528,
    height: 528,
    left: 350,
    top: 545,
    background:
      "linear-gradient(180deg, #4DE3DE 0%, #D7FFF1 20.73%, #BFF59E 40.1%, #F8F0FB 65.62%, #FF8AC0 92.71%)",
    filter: " blur(143px)",
  },

  "& .circle-3": {
    position: "absolute",
    width: 528,
    height: 528,
    left: 600,
    top: 131,
    background:
      "linear-gradient(180deg, #4DE3DE 0%, #D7FFF1 20.73%, #BFF59E 40.1%, #F8F0FB 65.62%, #FF8AC0 92.71%)",
    filter: "blur(143px)",
  },
}));

export const ContactLayer3 = styled(Box)(({ theme }) => ({
  zIndex: 2,
  width: "1100px",
  height: "100%",
  position: "absolute",
  top: 0,

  "& .circle-1": {
    position: "absolute",
    borderRadius: "100%",
    width: 438,
    height: 426,
    left: 60,
    top: 250,
    background: "#5627FF",
  },

  "& .circle-2": {
    position: "absolute",
    borderRadius: "100%",
    width: 438,
    height: 426,
    left: 610,
    top: 185,
    background: "#4DE3DE",
  },
}));

export const ContactTitle = styled(SecondaryHeading)(({ theme }) => ({
  textAlign: "center",
}));
export const ContactText = styled(Text)(({ theme }) => ({
  textAlign: "center",
  marginTop: "1rem",
  marginBottom: "2rem",
}));
export const ContactBox = styled(Box)(({ theme }) => ({
  ...flexColumnCenterCenter,
  maxWidth: "800px",
  width: "100%",
  zIndex: "2",
  gap: "2.5rem",
  background: "#FFFFFF",
  border: "1px solid rgba(77, 227, 222, 0.19)",
  boxShadow: "0px 0px 26px 2px rgba(197, 205, 198, 0.28)",
  borderRadius: "19px",
  padding: "65px 40px",

  [theme.breakpoints.down("lg")]: {
    ...flexColumnCenterCenter,
  },
  [theme.breakpoints.down("sm")]: {
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
}));

export const FormContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    maxWidth: "600px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
export const InputContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: "1rem",
  marginBottom: "2rem",
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
}));
export const InputWrapper = styled(Box)(({ theme }) => ({
  ...flexColumnStartStart,
  "& input": {
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.colors.border}`,
    padding: "15px 10px",
    borderRadius: ".5rem",
    width: "100%",

    "&.form-error": {
      border: `1px solid red`,

      "&:focus": {
        border: "1px solid red",
        outline: "none",
        boxShadow: "0px 0px 0px 3px rgba(255, 0, 0, 0.55)",
      },
    },
    "&:focus": {
      border: "1px solid #03ACB5",
      outline: "none",
      boxShadow: "0px 0px 0px 3px rgba(0, 213, 204, 0.55)",
    },
  },
  "& textarea": {
    fontFamily: "Poppins",
    fontSize: "16px",
    resize: "none",
    boxSizing: "border-box",
    width: "100%",
    height: "200px",
    border: `1px solid ${theme.palette.colors.border}`,
    padding: "15px 10px",
    borderRadius: ".5rem",
    "&.form-error": {
      border: `1px solid red`,

      "&:focus": {
        border: "1px solid red",
        outline: "none",
        boxShadow: "0px 0px 0px 3px rgba(255, 0, 0, 0.55)",
      },
    },
    "&:focus": {
      border: "1px solid #03ACB5",
      outline: "none",
      boxShadow: "0px 0px 0px 3px rgba(0, 213, 204, 0.55)",
    },
    "&::placeholder": {
      fontFamily: "'Poppins', sans-serif",
    },
  },
}));

export const InputLabel = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "600px",
  color: theme.palette.text.heading,
  marginBottom: ".5rem",
}));

export const TextError = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "red",
  margin: "0px 10px",
  marginTop: "5px",
}));
