import { Box, Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnCenterCenter,
  flexColumnStartCenter,
  flexColumnStartStart,
  flexRowBetweenCenter,
  flexRowCenterCenter,
  flexRowStartCenter,
} from "../../../common/scheme";
import { PrimaryBtn, TextSmall } from "../../Common.styles";

export const PaymentContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  width: "100%",
  backgroundColor: theme.palette.colors.secondary,
}));
export const PaymentWrapper = styled(Box)(({ theme }) => ({
  ...flexColumnCenterCenter,
  width: "450px",
  height: "400px",
  borderRadius: "20px",
  backgroundColor: theme.palette.text.white,
  padding: "1rem",
  marginTop: "3rem",
  marginBottom: "3rem",
  "& h1": {
    textAlign: "center",
    color: theme.palette.text.primary,
    fontSize: "20px",
    fontWeight: "700",
  },
  "& .sigin-with": {
    textAlign: "center",
    color: theme.palette.text.heading,
    "& span": {
      fontWeight: "700",
      color: theme.palette.colors.primary,
    },
  },

  [theme.breakpoints.down("sm")]: {
    padding: "1rem .5rem",
  },
}));
