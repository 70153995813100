import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { flexRowStartCenter } from "../../../common/scheme";

export const SearchInputContainer = styled(Box)(
  ({ theme, showbg, showborder }) => ({
    backgroundColor: showbg === "show" && theme.palette.colors.secondary,
    ...flexRowStartCenter,
    padding: ".25rem .5rem",
    borderRadius: ".5rem",
    border: showborder === "show" && `1px solid ${theme.palette.colors.border}`,
    "& img": {
      width: "14px",
      height: "14px",
    },
    "& input": {
      backgroundColor: showbg === "show" && theme.palette.colors.secondary,
      border: "none",
      outline: "0",
      padding: ".5rem",
      width: "100%",
      "&::placeholder": {
        fontSize: "14px",
        fontWeight: "400",
        color: theme.palette.text.detail,
      },
    },
  })
);
