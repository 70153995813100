import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnCenterCenter,
  flexColumnStartStart,
  flexRowCenterCenter,
  flexRowStartCenter,
} from "../../../common/scheme";
import { SecondaryHeading, Text } from "../../Common.styles";

export const ContactContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  marginTop: "7rem",
  backgroundImage:
    "radial-gradient(2000px circle at right 30%, rgba(180, 95, 232, 0.2), white, white, white, white)",
}));
export const ContactWrapper = styled(Box)(({ theme }) => ({
  width: "1000px",
}));

export const ContactTitle = styled(SecondaryHeading)(({ theme }) => ({
  textAlign: "center",
}));
export const ContactText = styled(Text)(({ theme }) => ({
  textAlign: "center",
  marginTop: "1rem",
  marginBottom: "2rem",
}));
export const ContactBox = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: "2.5rem",
  [theme.breakpoints.down("lg")]: {
    ...flexColumnCenterCenter,
  },
  [theme.breakpoints.down("sm")]: {
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
}));

export const FormContainer = styled(Box)(({ theme }) => ({
  width: "calc(100% - 2rem)",
  [theme.breakpoints.down("lg")]: {
    width: "600px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 2rem)",
  },
}));
export const InputContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: "2rem",
  marginBottom: "2rem",
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
}));
export const InputWrapper = styled(Box)(({ theme }) => ({
  ...flexColumnStartStart,
  "& input": {
    border: `1px solid ${theme.palette.colors.border}`,
    padding: "15px 10px",
    borderRadius: ".5rem",
    width: "250px",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)",
    },

    "&.form-error": {
      border: `1px solid red`,

      "&:focus": {
        border: "1px solid red",
        outline: "none",
        boxShadow: "0px 0px 0px 3px rgba(255, 0, 0, 0.55)",
      },
    },
    "&:focus": {
      border: "1px solid #03ACB5",
      outline: "none",
      boxShadow: "0px 0px 0px 3px rgba(0, 213, 204, 0.55)",
    },
  },
  "& textarea": {
    fontFamily: "Poppins",
    fontSize: "16px",
    resize: "none",
    width: "560px",
    height: "200px",
    border: `1px solid ${theme.palette.colors.border}`,
    padding: "15px 10px",
    borderRadius: ".5rem",
    "&.form-error": {
      border: `1px solid red`,

      "&:focus": {
        border: "1px solid red",
        outline: "none",
        boxShadow: "0px 0px 0px 3px rgba(255, 0, 0, 0.55)",
      },
    },
    "&:focus": {
      border: "1px solid #03ACB5",
      outline: "none",
      boxShadow: "0px 0px 0px 3px rgba(0, 213, 204, 0.55)",
    },
    "&::placeholder": {
      fontFamily: "'Poppins', sans-serif",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)",
    },
  },
}));

export const InputLabel = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "600px",
  color: theme.palette.text.heading,
  marginBottom: ".5rem",
}));

export const TextError = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "red",
  margin: "0px 10px",
  marginTop: "5px",
}));
