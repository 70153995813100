import { Box, Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnCenterCenter,
  flexRowAroundCenter,
  flexRowCenterCenter,
  flexRowEvenlyCenter,
} from "../../../common/scheme";
import { PrimaryBtn, TextSmall } from "../../Common.styles";

export const BecomeSellerConainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  width: "100%",
  backgroundColor: theme.palette.colors.secondary,
}));
export const BecomeSellerWrapper = styled(Box)(({ theme }) => ({
  ...flexColumnCenterCenter,
  width: "450px",
  height: "400px",
  borderRadius: "20px",
  backgroundColor: theme.palette.text.white,
  padding: "1rem",
  marginTop: "3rem",
  marginBottom: "3rem",
  "& h1": {
    textAlign: "center",
    color: theme.palette.text.primary,
    fontSize: "20px",
    fontWeight: "700",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "1rem .5rem",
  },
}));
export const BtnContainer = styled(Box)(({ theme }) => ({
  ...flexRowEvenlyCenter,
  width: "100%",
}));
