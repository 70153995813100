import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexRowBetweenCenter,
  flexRowStartCenter,
  flexColumnStartStart,
  flexRowEndCenter,
} from "../common/scheme";

// Typography
export const Heading = styled(Typography)(({ theme }) => ({
  fontFamily: "'Poppins', sans-serif",
  fontSize: "50px",
  fontWeight: "700",
  color: theme.palette.text.heading,
}));
export const SecondaryHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "'Poppins', sans-serif",
  fontSize: "30px",
  fontWeight: "700",
  color: theme.palette.text.heading,
}));
export const ThirdHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "'Poppins', sans-serif",
  fontSize: "20px",
  fontWeight: "600",
  color: theme.palette.text.heading,
}));
export const Text = styled(Typography)(({ theme }) => ({
  fontFamily: "'Poppins', sans-serif",
  fontSize: "18px",
  fontWeight: "400",
  color: theme.palette.text.detail,
}));
export const TextSmall = styled(Typography)(({ theme }) => ({
  fontFamily: "'Poppins', sans-serif",
  fontSize: "14px",
  fontWeight: "400",
  color: theme.palette.text.detail,
}));

// Flex
export const FlexRowBetweenCenter = styled(Box)(({ theme }) => ({
  ...flexRowBetweenCenter,
  gap: "1rem",
}));
export const FlexRowStartCenter = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
}));

export const FlexColumnStartStart = styled(Box)(({ theme }) => ({
  ...flexColumnStartStart,
}));
export const FlexRowEndCenter = styled(Box)(({ theme }) => ({
  ...flexRowEndCenter,
}));

//  Buttons

export const PrimaryBtn = styled(Button)(({ theme }) => ({
  padding: "10px 20px",
  borderRadius: ".5rem",
  backgroundColor: theme.palette.colors.primary,
  color: theme.palette.text.white,
  fontSize: "14px",
  fontWeight: "700",
  textTransform: "none",
  fontFamily: "'Poppins', sans-serif",
  "&:hover": {
    padding: "9px 19px",
    border: `1px solid ${theme.palette.colors.primary}`,
    backgroundColor: theme.palette.text.white,
    color: theme.palette.colors.primary,
    transition: ".1s",
  },
}));
export const OutlineBtn = styled(Button)(({ theme }) => ({
  padding: "9px 20px",
  borderRadius: ".5rem",
  border: `1px solid ${theme.palette.colors.primary}`,
  color: theme.palette.colors.primary,
  backgroundColor: theme.palette.text.white,
  fontSize: "14px",
  fontWeight: "700",
  textTransform: "none",
  fontFamily: "'Poppins', sans-serif",

  "&.text-black": {
    color: theme.palette.text.heading,
  },

  "&:hover": {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.colors.primary,
    opacity: "0.8",
  },
}));

// Divider

export const Divider = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.category.text,
}));
