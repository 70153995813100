import React from "react";
import {
  PaginationContainer,
  PageNumber,
  PrevPage,
  NextPage,
} from "../../styles/components/customs/Pagination.styles";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ArrowRightDisabled from "../../assets/icons/arrow-right-disabled.svg";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowLeftDisabled from "../../assets/icons/arrow-left-disabled.svg";
import { useNavigate } from "react-router-dom";

function Pagination({
  currentPage,
  hasNextPage,
  hasPreviousPage,
  lastPage,
  nextPage,
  previousPage,
}) {
  const navigate = useNavigate();

  return (
    <PaginationContainer>
      <PageNumber>{currentPage}</PageNumber>
      <p>De {lastPage}</p>

      <PrevPage>
        <img
          src={hasPreviousPage ? ArrowLeft : ArrowLeftDisabled}
          alt="pre-page"
          onClick={() => hasPreviousPage && navigate("?page=" + previousPage)}
        />
      </PrevPage>

      <NextPage>
        <img
          src={hasNextPage ? ArrowRight : ArrowRightDisabled}
          alt="next-page"
          onClick={() => hasNextPage && navigate("?page=" + nextPage)}
        />
      </NextPage>
    </PaginationContainer>
  );
}

export default Pagination;
