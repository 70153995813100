import React, { Fragment } from "react";
import AllServicios from "../common/servicios/AllServicios";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";

function Servicios() {
  return (
    <Fragment>
      <Navbar />
      <AllServicios />
      <Footer margintop="7rem" />
    </Fragment>
  );
}

export default Servicios;
