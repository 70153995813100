import React, { Fragment } from "react";
import BalanceDetail from "../common/earnings/BalanceDetail";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";

const Earnings = () => {
  return (
    <Fragment>
      <Navbar />
      <BalanceDetail />
      <Footer />
    </Fragment>
  );
};

export default Earnings;
