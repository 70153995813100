import { useTheme } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ServicioCard from "../../components/cards/ServicioCard";
import Filter from "../../components/customs/Filter";
import SearchInput from "../../components/customs/SearchInput";
import {
  AllServiciosContainer,
  CategoryContainer,
  ServiciosContainer,
  CategoryList,
  CategoryItem,
  NumberContainer,
  HeadingContainer,
  ServicioDivider,
  SearchFilterContainer,
  SearchWrapper,
  FilterWrapper,
  ServicioWrapper,
  BottomPagination,
  FilterLiteWrapper,
} from "../../styles/common/servicios/AllServicios.styles";
import Pagination from "../../components/customs/Pagination";
import { filterData, servicioData, categoryData } from "../../data/data";
import { useGetServices } from "../../api/service";
import { useGetCategories } from "../../api/category";
import { app } from "../../utils/config";

const getImage = (image) => {
  if (image.includes("http") || image.includes("https")) {
    return image;
  }
  return `${app.serverURL}/${image}`;
};

function AllServicios() {
  const theme = useTheme();
  const { bg, activeBg, activeText, text } = theme.palette.category;
  const { primary } = theme.palette.colors;
  const { white, heading } = theme.palette.text;
  const params = useParams();
  const navigate = useNavigate();
  const [itemId, setItemId] = useState(null);
  const [pathCategory, setPathCategory] = useState("");
  const [category, setCategory] = useState({});
  const [searchField, setSearchField] = useState("");
  const [filter, setFilter] = useState("most_recent");
  const location = useLocation();
  const queryPage = location.search;

  const { data } = useGetServices(
    `${queryPage ? `${queryPage}&filter=${filter}` : `?filter=${filter}`}`,
    itemId,
    {
      enabled: !!itemId,
    }
  );

  const { data: categories } = useGetCategories();

  useEffect(() => {
    setPathCategory(params.category);
    if (categories) {
      const categoryData = categories.find((c) => c.path === params.category);
      setItemId(categoryData._id);
      setCategory(categoryData);
    }
  }, [params.category, categories]);

  const filteredServices =
    data &&
    data.services.filter((service) => {
      return service.title.toLowerCase().includes(searchField.toLowerCase());
    });

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  const handleFilter = (e) => {
    const value = e.target.value;
    setFilter(value);
  };

  return (
    <AllServiciosContainer>
      <CategoryContainer>
        <p>Buscar por categoria</p>
        <h1>Todos</h1>
        <CategoryList>
          {categories?.map(({ _id, name, path, amount }) => (
            <CategoryItem
              key={_id}
              onClick={() => navigate("/servicios/" + path)}
              sx={{
                bgcolor: pathCategory === path && primary,
                color: pathCategory === path ? white : heading,
              }}
            >
              <h1>{name}</h1>
              <NumberContainer
                sx={{
                  bgcolor: pathCategory === path ? activeBg : bg,
                  color: pathCategory === path ? activeText : text,
                }}
              >
                {amount}
              </NumberContainer>
            </CategoryItem>
          ))}
        </CategoryList>
      </CategoryContainer>

      <ServiciosContainer>
        <HeadingContainer>
          <h2>{category?.name}</h2>
          {data && (
            <Pagination
              currentPage={data.currentPage}
              hasNextPage={data.hasNextPage}
              hasPreviousPage={data.hasPreviousPage}
              lastPage={data.lastPage}
              nextPage={data.nextPage}
              previousPage={data.previousPage}
            />
          )}
        </HeadingContainer>
        <ServicioDivider />
        <SearchFilterContainer>
          <SearchWrapper>
            <SearchInput
              showbg={"hide"}
              showborder={"show"}
              handleChange={handleChange}
            />
          </SearchWrapper>
          <FilterWrapper>
            <Filter
              filterData={filterData}
              filterWidth="200px"
              handleFilter={handleFilter}
            />
          </FilterWrapper>
        </SearchFilterContainer>
        <FilterLiteWrapper>
          <Filter filterData={categoryData} filterWidth="70%" />
        </FilterLiteWrapper>

        <ServicioWrapper>
          {data &&
            filteredServices.map(
              ({
                _id,
                name,
                userId,
                description,
                review,
                planId,
                title,
                userPic,
                profilePic,
                images,
              }) => (
                <ServicioCard
                  key={_id}
                  id={_id}
                  name={userId.name}
                  detail={userId.country}
                  description={title}
                  order={review.amount}
                  price={planId.basic.price}
                  userPic={getImage(userId.image_url)}
                  profilePic={
                    images && images.length > 0 ? getImage(images[0]) : ""
                  }
                />
              )
            )}
          {/* {servicioData.map(
            ({
              id,
              name,
              detail,
              description,
              order,
              price,
              userPic,
              profilePic,
            }) => (
              <ServicioCard
                key={id}
                id={id}
                name={name}
                detail={detail}
                description={description}
                order={order}
                price={price}
                userPic={userPic}
                profilePic={profilePic}
              />
            )
          )} */}
        </ServicioWrapper>
        <BottomPagination>
          {data && (
            <Pagination
              currentPage={data.currentPage}
              hasNextPage={data.hasNextPage}
              hasPreviousPage={data.hasPreviousPage}
              lastPage={data.lastPage}
              nextPage={data.nextPage}
              previousPage={data.previousPage}
            />
          )}
        </BottomPagination>
      </ServiciosContainer>
    </AllServiciosContainer>
  );
}

export default AllServicios;
