import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnCenterCenter,
  flexColumnStartStart,
  flexRowBetweenCenter,
  flexRowStartCenter,
} from "../../../common/scheme";
import { PrimaryBtn } from "../../Common.styles";

export const ServiceManagerContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.colors.border}`,
  padding: "20px",
  width: "calc(100% - 40px)",
  gap: "1rem",
  ...flexRowBetweenCenter,
  borderRadius: ".5rem",
  "& img:nth-of-type(1)": {
    width: "95px",
    height: "70px",
    borderRadius: "5px",
  },
  [theme.breakpoints.down("lg")]: {
    flexWrap: "wrap",
  },
  [theme.breakpoints.down("sm")]: {
    "& img:nth-of-type(1)": {
      width: "50px",
      height: "40px",
    },
  },
}));
export const DetailWrapper = styled(Box)(({ theme }) => ({
  ...flexColumnStartStart,
  "& h1": {
    margin: "0",
    fontSize: "16px",
    fontWeight: "500",
    color: theme.palette.text.heading,
  },
  "& p": {
    margin: "0",
    color: theme.palette.profile.text,
    fontSize: "14px",
    fontWeight: "400",
  },
  "& h2": {
    fontSize: "12px",
    fontWeight: "400",
    margin: "0",
    color: theme.palette.text.heading,
  },
  [theme.breakpoints.down("sm")]: {
    "& h1": {
      fontSize: "16px",
    },
    "& p": {
      fontSize: "12px",
    },
    "& h2": {
      fontSize: "10px",
    },
  },
}));
export const ServiceContent = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  "& img": {
    marginRight: "4rem",
  },
}));
export const ServiceBtn = styled(PrimaryBtn)(({ theme }) => ({
  padding: "8px 16px",
  "&:hover": {
    padding: "7px 15px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 12px",
    marginTop: "10px",
  },
}));
