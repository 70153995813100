import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnStartStart,
  flexRowBetweenCenter,
  flexRowBetweenStart,
  flexRowCenterCenter,
} from "../../common/scheme";
import FooterBg from "../../assets/footer/background.svg";

export const FooterContainer = styled(Box)(({ theme, margintop }) => ({
  ...flexRowCenterCenter,
  marginTop: margintop,
  backgroundColor: theme.palette.footer.bg,
  borderTop: `1px solid ${theme.palette.footer.border}`,
  padding: "40px 1rem",
}));
export const FooterWrapper = styled(Box)(({ theme }) => ({
  width: "1000px",
  ...flexRowBetweenStart,
  gap: "2rem",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    margin: "2rem",
  },
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
  },
}));
export const ListContainer = styled(Box)(({ theme }) => ({
  ...flexColumnStartStart,
  gap: "1rem",
}));
export const ListItems = styled(Box)(({ theme }) => ({
  ...flexColumnStartStart,
  gap: ".5rem",
}));
export const ListItem = styled(Box)(({ theme }) => ({
  ...flexRowBetweenCenter,
  gap: ".5rem",
}));
export const FooterBottom = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "180px",
  backgroundImage: `url(${FooterBg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& img": {
    width: 210,
    height: 90,
  },

  [theme.breakpoints.down("sm")]: {
    height: "100px",

    "& img": {
      width: 150,
      height: 60,
    },
  },
}));
