import React from "react";
import TestimonialCard from "../../components/cards/TestimonialCard";
import {
  TestimonialContainer,
  TestimonialWrapper,
  CardContainer,
  DetailContainer,
  QuestionContainer,
  QuestionWrapper,
  TestimonialPartnerContainer,
} from "../../styles/common/about_us/Testimonial.styles";
import MissionIcon from "../../assets/icons/mission";
import VisionIcon from "../../assets/icons/vision";
import QuestionIcon from "../../assets/icons/question";
import { SecondaryHeading, Text } from "../../styles/Common.styles";
import Partners from "./Partners";

function Testimonial() {
  return (
    <TestimonialPartnerContainer>
      <TestimonialContainer>
        <TestimonialWrapper>
          <CardContainer>
            <TestimonialCard
              icon={MissionIcon}
              titile="Misión"
              description="Democratizar el mercado literario, empoderando a los escritores en su búsqueda de publicar el libro de sus sueños y a su vez promover un repertorio de buenos profesionales en sus mejores áreas."
            />
            <TestimonialCard
              icon={VisionIcon}
              titile="Visión"
              description="Innovar y convertirnos en un referente de autopublicación en Iberoamérica, utilizando la tecnología para brindar las mejores soluciones en el mercado literario"
            />
          </CardContainer>
          <QuestionWrapper>
            <QuestionContainer>
              <img src={QuestionIcon} alt="question" />
              <DetailContainer>
                <SecondaryHeading>¿Por qué nosotros?</SecondaryHeading>
                <Text>
                  Al disponer de un grupo de profesionales freelancers,
                  brindamos una variedad de servicios ajustados a las
                  necesidades de cada escritor, de manera que puedan garantizar
                  la calidad tanto literaria como estética de sus obras
                </Text>
              </DetailContainer>
            </QuestionContainer>
          </QuestionWrapper>
        </TestimonialWrapper>
      </TestimonialContainer>
      {/* <Partners /> */}
    </TestimonialPartnerContainer>
  );
}

export default Testimonial;
