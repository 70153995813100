import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { flexColumnStartStart } from "../../../common/scheme";

export const CategoryCardContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.text.white,
  ...flexColumnStartStart,
  padding: "2rem",
  width: "275px",
  borderRadius: ".5rem",
  gap: "1rem",
  cursor: "pointer",
  borderTop: `2px solid white`,

  "& img": {
    width: "80px",
    height: "80px",
  },
  "& h1": {
    fontSize: "24px",
    margin: "0",
    fontWeight: "600",
    color: theme.palette.text.heading,
  },
  "& p": {
    fontSize: "14px",
    margin: "0",
    fontWeight: "400",
    color: theme.palette.profile.text,
  },

  [theme.breakpoints.down("sm")]: {
    // width: "calc(100% - 4rem)",
    width: "100%",
    padding: "0 10px",
  },

  "&:hover": {
    borderTop: `2px solid ${theme.palette.colors.primary}`,
    boxShadow: "0px 1px 34px #C3F1F5",

    "& h1": {
      color: theme.palette.colors.primary,
    },
  },
}));
