import React, { useState } from "react";
import { AuthWrapper } from "../styles/auth/Auth.styles";
import {
  AuthContainer,
  FormContainer,
  InputWrapper,
  InputContainer,
  ContinueBtn,
  RestoreText,
  SignupText,
} from "../styles/auth/Auth.styles";
import { Link } from "react-router-dom";
import { changePassword } from "../api/auth";
import ViewIcon from "../assets/icons/view.svg";
import { useParams, useNavigate } from "react-router";

function Change() {
  const [passwordType, setPasswordType] = useState(false);
  const [signupFields, setSignupFields] = useState({
    password: "",
    matchPassword: "",
  });
  const { password, matchPassword } = signupFields;
  const params = useParams();
  const navigate = useNavigate();

  const onChangeHandler = (e) => {
    setSignupFields({
      ...signupFields,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async () => {
    // forgotPassword
    const res = await changePassword({ password, resetToken: params.token });

    if (res.status == 204) {
      navigate("/");
    }
  };

  const togglePasswordType = () => {
    setPasswordType(!passwordType);
  };

  return (
    <AuthContainer>
      <AuthWrapper>
        <h1>Cambiar contraseña en BeeBuk</h1>
        <FormContainer>
          <InputContainer>
            <label>Contraseña </label>
            <InputWrapper>
              <input
                type={passwordType ? "text" : "password"}
                placeholder="Crear contraseña"
                name="password"
                value={password}
                onChange={onChangeHandler}
              />
              <img
                src={ViewIcon}
                alt="view-icon"
                onClick={togglePasswordType}
              />
            </InputWrapper>
          </InputContainer>
          <InputContainer sx={{ mt: "1.5rem" }}>
            <label>Escriba de nuevo su contraseña </label>
            <InputWrapper>
              <input
                type={passwordType ? "text" : "password"}
                placeholder="Escribe tu correo electrónico"
                name="matchPassword"
                value={matchPassword}
                onChange={onChangeHandler}
              />
              <img
                src={ViewIcon}
                alt="view-icon"
                onClick={togglePasswordType}
              />
            </InputWrapper>
          </InputContainer>

          <SignupText sx={{ textAlign: "justify" }}>
            8 caracteres o más. Combina letras mayúsculas y minúsculas y
            números.
          </SignupText>

          <ContinueBtn onClick={onSubmit}>Enviar</ContinueBtn>
        </FormContainer>

        <Link to="/login" style={{ textDecoration: "none" }}>
          <p className="sigin-with">
            <span>Volver a Iniciar Sesión</span>
          </p>
        </Link>
      </AuthWrapper>
    </AuthContainer>
  );
}

export default Change;
