import React, { Fragment } from "react";
import Register from "../auth/Register";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";

function Login() {
  return (
    <Fragment>
      <Navbar />
      <Register />
      <Footer margintop="0" />
    </Fragment>
  );
}

export default Login;
