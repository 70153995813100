import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnCenterCenter,
  flexColumnStartCenter,
  flexRowBetweenCenter,
  flexRowBetweenStart,
  flexRowCenterCenter,
  flexRowEndCenter,
  flexRowStartStart,
} from "../../../common/scheme";
import { Divider } from "../../Common.styles";

export const AllServiciosContainer = styled(Box)(({ theme }) => ({
  marginTop: "3rem",
  ...flexRowStartStart,
  gap: "1rem",
  [theme.breakpoints.down("md")]: {
    marginTop: "1rem",
  },
}));
export const CategoryContainer = styled(Box)(({ theme }) => ({
  marginLeft: "2rem",
  border: `1px solid ${theme.palette.colors.border}`,
  padding: "1rem 1.5rem",
  borderRadius: ".5rem",
  "& p": {
    fontSize: "1rem",
    fontWeight: "400",
  },
  "& h1": {
    fontSize: "14px",
    fontWeight: "700",
    marginTop: "1.5rem",
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
export const CategoryList = styled(Box)(({ theme }) => ({
  ...flexColumnStartCenter,
  marginTop: "1rem",
}));
export const CategoryItem = styled(Box)(({ theme }) => ({
  ...flexRowBetweenCenter,
  cursor: "pointer",
  width: "200px",
  "& h1": {
    margin: "0",
  },
  padding: ".7rem 1rem",
  borderRadius: ".5rem",
}));

export const NumberContainer = styled(Box)(({ theme }) => ({
  minWidth: "30px",
  minHeight: "30px",
  borderRadius: ".5rem",
  ...flexColumnCenterCenter,
  fontSize: "12px",
  fontWeight: "600",
  padding: ".3rem",
}));

export const ServiciosContainer = styled(Box)(({ theme }) => ({
  marginRight: "2rem",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    marginLeft: "2rem",
  },
}));

export const HeadingContainer = styled(Box)(({ theme }) => ({
  ...flexRowBetweenCenter,
  "& h2": {
    fontSize: "20px",
    fontWeight: "600",
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
export const ServicioDivider = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
export const SearchFilterContainer = styled(Box)(({ theme }) => ({
  ...flexRowBetweenCenter,
  marginTop: "2rem",
  [theme.breakpoints.down("md")]: {
    marginTop: "0",
    ...flexRowCenterCenter,
    gap: "1rem",
  },
}));
export const SearchWrapper = styled(Box)(({ theme }) => ({
  width: "350px",
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 150px)",
  },
}));
export const FilterWrapper = styled(Box)(({ theme }) => ({}));
export const ServicioWrapper = styled(Box)(({ theme }) => ({
  ...flexRowBetweenStart,
  gap: "1rem",
  marginTop: "1rem",
  flexWrap: "wrap",
  [theme.breakpoints.down("lg")]: {
    ...flexRowCenterCenter,
    gap: "2rem",
  },
}));
export const BottomPagination = styled(Box)(({ theme }) => ({
  ...flexRowEndCenter,
  marginTop: "3rem",
}));
export const FilterLiteWrapper = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    ...flexRowCenterCenter,
    marginTop: "1rem",
  },
}));
