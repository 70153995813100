import { api } from "../utils/api";
import { apiRoutes } from "../constant.routes";
import { useFetch } from "../utils/reactQuery";

export const useGetDeliveries = (body) => {
  const context = useFetch(
    apiRoutes.apiPrefix + apiRoutes.delivery.path + apiRoutes.delivery.list,
    body,
    { retry: false }
  );
  return { ...context, data: context.data?.deliveries };
};

export const completeDelivery = (id) =>
  api.post(
    apiRoutes.apiPrefix +
      apiRoutes.delivery.path +
      `/${id}` +
      apiRoutes.delivery.complete
  );

export const rejectDelivery = (id) =>
  api.post(
    apiRoutes.apiPrefix +
      apiRoutes.delivery.path +
      `/${id}` +
      apiRoutes.delivery.reject
  );

export const createDelivery = (body) =>
  api.post(
    apiRoutes.apiPrefix + apiRoutes.delivery.path + apiRoutes.delivery.create,
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
