import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { flexRowCenterCenter } from "../../../common/scheme";

export const AllCategoriesContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  backgroundColor: theme.palette.pricing.header,
  marginTop: "3rem",
  overflow: "hidden",
  position: "relative",
}));
export const AllCategoriesWrapper = styled(Box)(({ theme }) => ({
  width: "1100px",
  padding: "2rem",
  zIndex: 2,
  "& h2": {
    fontSize: "2rem",
    color: theme.palette.text.heading,
    textAlign: "center",
    marginBottom: "3rem",
  },
  [theme.breakpoints.down("lg")]: {
    width: "calc(100% - 4rem)",
  },
}));
export const CategoryCardWrapper = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  gap: "2rem",
  flexWrap: "wrap",
  width: "100%",
  "& a": {
    ...flexRowCenterCenter,
    flex: "auto",
    display: "flex",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px",
      width: "100%",
    },
  },
}));
export const LayerContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "100%",
  "& img": {
    width: "100%",
    height: "100%",
  },
}));
