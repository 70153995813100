import axios from "axios";
import { apiRoutes } from "../constant.routes";
import { app } from "./config";

const token = "Bearer " + localStorage.getItem("token");
const refresh_token = "Bearer " + localStorage.getItem("refresh_token");
const remember_me = true;

const getToken = () => {
  return "Bearer " + localStorage.getItem("token");
};

const instance = axios.create({
  baseURL: app.serverURL,
});

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const { config, message, response } = error;

    const status = error.response ? error.response.status : null;
    const originalConfig = error.config;
    originalConfig.headers = { ...originalConfig.headers };
    if (status === 403 && remember_me) {
      try {
        const refreshResult = await instance.get(
          apiRoutes.apiPrefix + apiRoutes.auth.path + apiRoutes.auth.refresh,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("refresh_token"),
            },
            undefined,
          }
        );

        if (refreshResult.data) {
          const token = refreshResult.data.user.access_token;
          localStorage.setItem("token", token);
          originalConfig.headers = {
            ...originalConfig.headers,
            Authorization: "Bearer " + token,
          };
          return instance(originalConfig);
        }
      } catch (err) {
        localStorage.removeItem("user");
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export const api = {
  get: (url, params) =>
    instance.get(url, {
      headers: {
        Authorization: getToken(),
      },
      ...params,
    }),
  post: (url, data) =>
    instance.post(url, data, {
      headers: {
        Authorization: getToken(),
      },
    }),
  put: (url, data) =>
    instance.put(url, data, {
      headers: {
        Authorization: getToken(),
      },
    }),
  patch: (url, data) =>
    instance.patch(url, data, {
      headers: {
        Authorization: getToken(),
      },
    }),
  delete: (url) =>
    instance.delete(url, {
      headers: {
        Authorization: token,
      },
    }),
};
