import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    colors: {
      primary: "#03ACB5",
      secondary: "#F4F4F4",
      border: "#BEBEC0",
    },
    text: {
      heading: "#05060F",
      detail: "#8F8F8F",
      white: "#ffffff",
    },
    category: {
      bg: "#F4F3F1",
      text: "#B4B2AF",
      activeBg: "#81D5DA",
      activeText: "#0592A7",
    },
    footer: {
      bg: "#FAFAFA",
      border: "rgba(5, 6, 15, 0.06)",
    },
    servicio: {
      border: "#E2E1DF",
      rating: "#FFBA07",
    },
    pagination: {
      border: "#232221",
      prevPage: " #F4F3F1",
    },
    profile: {
      levelBg: "#5627FF",
      levelText: "rgba(255, 255, 255, 0.9)",
      text: "#7D7E82",
    },
    pricing: {
      header: "rgba(199, 231, 234, 0.32)",
      tableBg: "rgba(77, 227, 222, 0.05)",
    },
    orders: {
      bg: "#CCFFFD",
      text: "#0592A7",
    },
  },
});
