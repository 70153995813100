import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexRowCenterCenter,
  flexRowStartCenter,
} from "../../../common/scheme";

export const PartnersContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
}));

export const PartnersWrapper = styled(Box)(({ theme }) => ({
  width: "900px",
  [theme.breakpoints.down("lg")]: {
    width: "80%",
    margin: "auto 2rem",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "auto 2rem",
  },
}));
export const ImagesContainer = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: "3rem",
  marginTop: "2rem",
  flexWrap: "wrap",
}));
