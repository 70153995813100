import React, { Fragment, useState, useEffect } from "react";
import SearchInput from "../components/customs/SearchInput";
import {
  NavbarContainer,
  MenuContainer,
  MenuListItem,
  AuthContainer,
  AuthItem,
  IconWrapper,
  SearchContainer,
  SearchWrapper,
  MobileSearch,
  MobileDrawer,
  NavWrapper,
  NavbarWrapper,
} from "../styles/layout/Navbar.styles";
import Logo from "../assets/logo.svg";
import { FlexRowEndCenter, PrimaryBtn } from "../styles/Common.styles";
import PopoverMenu from "../components/customs/PopoverMenu";
import { Box } from "@mui/material";
import MenuIcon from "../assets/icons/menu.svg";
import MenuIconVertical from "../assets/icons/menu-vertical.svg";
import { flexRowCenterCenter } from "../common/scheme";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { serviciosData, cuentaData, buyerData } from "../data/data";
import { useGetProfile, useUser, logout } from "../api/auth";

function Navbar() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const theme = useTheme();

  const activeStyle = {
    color: theme.palette.colors.primary,
    textDecoration: "none",
  };

  const navStyle = {
    color: theme.palette.text.heading,
    textDecoration: "none",
  };

  const { user, updateUser } = useUser();

  const userStore = localStorage.getItem("user");
  useEffect(() => {
    updateUser();
  }, [userStore]);

  const handleLogout = async () => {
    let result = await logout();

    if (result.status === 204) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user");
      updateUser();
    }
  };

  const handleRole = (role) => {
    switch (role) {
      case "SELLER":
        return (
          <PopoverMenu
            menuItems={cuentaData}
            title="Mi Cuenta"
            link="profile"
          />
        );
      case "BUYER":
        return (
          <PopoverMenu menuItems={buyerData} title="Mi Cuenta" link="profile" />
        );
      default:
        return;
    }
  };

  const menuList = (
    <Fragment>
      <PopoverMenu
        menuItems={serviciosData}
        title="Servicios"
        link="servicios"
      />
      <NavLink
        to="/about-us"
        style={({ isActive }) => (isActive ? activeStyle : navStyle)}
      >
        <MenuListItem>Sobre Nosotros</MenuListItem>
      </NavLink>
      <MenuListItem>Blog</MenuListItem>
      {user && handleRole(user.role)}
    </Fragment>
  );
  const authList = (
    <Fragment>
      <NavLink
        to="/login"
        style={({ isActive }) => (isActive ? activeStyle : navStyle)}
      >
        <AuthItem>Login</AuthItem>
      </NavLink>
      <NavLink
        to="/signup"
        style={({ isActive }) => (isActive ? activeStyle : navStyle)}
      >
        <PrimaryBtn>Get Started</PrimaryBtn>
      </NavLink>
    </Fragment>
  );

  const authLoggedList = (
    <Fragment>
      {user?.wallet?.ballance > 0 && (
        <AuthItem>${user?.wallet?.ballance}</AuthItem>
      )}

      <NavLink style={({ isActive }) => (isActive ? activeStyle : navStyle)}>
        <PrimaryBtn onClick={handleLogout}>Logout</PrimaryBtn>
      </NavLink>
    </Fragment>
  );

  return (
    <NavbarContainer>
      <NavbarWrapper>
        <NavWrapper>
          <SearchContainer>
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
            <SearchWrapper>
              <SearchInput showbg={"show"} showborder={"hide"} />
            </SearchWrapper>
          </SearchContainer>

          <MenuContainer>{menuList}</MenuContainer>
          {user && user ? (
            <AuthContainer>{authLoggedList}</AuthContainer>
          ) : (
            <AuthContainer>{authList}</AuthContainer>
          )}

          <IconWrapper onClick={() => setOpenDrawer(true)}>
            <img src={MenuIcon} alt="menu-icon" />
          </IconWrapper>
        </NavWrapper>
      </NavbarWrapper>

      <MobileDrawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <FlexRowEndCenter
          sx={{ mr: "1rem", mt: "1rem" }}
          onClick={() => setOpenDrawer(false)}
        >
          <img src={MenuIconVertical} alt="vertical-icon" />
        </FlexRowEndCenter>
        <Box sx={{ p: "5px 10px", ...flexRowCenterCenter }}>
          <Box>
            {menuList}
            {authList}
            <MobileSearch>
              <SearchInput showbg={"show"} showborder={"hide"} />
            </MobileSearch>
          </Box>
        </Box>
      </MobileDrawer>
    </NavbarContainer>
  );
}

export default Navbar;
