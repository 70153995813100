import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { useParams } from "react-router";
import moment from "moment";
import axios from "axios";
import fileDownload from "js-file-download";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Rating from "@mui/material/Rating";

import CloseIcon from "../../assets/icons/close.svg";
import FileIcon from "../../assets/icons/file.svg";
import {
  OrderDetailContainer,
  OrderDetailWrapper,
  OrderDetailBox,
  OrderHeader,
  OrderChat,
  ChatBox,
  ChatMessage,
  SendBtn,
  FilesBtn,
  DeliveryBtn,
  TimeContainer,
  TimeItem,
  OrderBoxContainter,
  CloseModal,
  ModalContainer,
  ModalWrapper,
  ModalTitle,
  ModalContent,
  Textarea,
  FilesButtonContainer,
  ImageContainer,
  DeliveryContainer,
  DeliveryButtons,
  FilesContainer,
  FilesWrapper,
  FileContainer,
  CloseFile,
} from "../../styles/common/orders/OrderDetail.styles";
import { PrimaryBtn } from "../../styles/Common.styles";
import { useGetOrder } from "../../api/order";
import { useUser } from "../../api/auth";
import {
  createDelivery,
  rejectDelivery,
  useGetDeliveries,
} from "../../api/delivery";
import { toast } from "react-toastify";

import { SocketContext } from "../../context/socket";
import { completeDelivery } from "../../api/delivery";
import { filesMessage } from "../../api/order";
import { app } from "../../utils/config";

const Timer = ({ created_at, timelapse }) => {
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      dueIn(created_at, timelapse);
    }, 1000);
    return () => clearInterval(interval);
  }, [created_at, timelapse]);

  const dueIn = (date, num) => {
    const time = moment(date).add(num, "days");
    const diffTime = time.diff(moment());
    const duration = moment.duration(diffTime);
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    setTime({
      days,
      hours,
      minutes,
      seconds,
    });
  };

  return (
    <TimeContainer>
      <TimeItem>
        <h4>{time.days}</h4>
        <p>Dias</p>
      </TimeItem>
      <TimeItem>
        <h4>{time.hours}</h4>
        <p>Horas</p>
      </TimeItem>
      <TimeItem>
        <h4>{time.minutes}</h4>
        <p>Minutos</p>
      </TimeItem>
      <TimeItem>
        <h4>{time.seconds}</h4>
        <p>Segundos</p>
      </TimeItem>
    </TimeContainer>
  );
};

const style = {
  position: "absolute",
  width: "100%",
  boxSizing: "border-box",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

const OrderDetail = () => {
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState();
  const [message, setMessage] = useState("");
  const [chat, setChat] = useState([]);
  const [stars, setStars] = useState(0);
  const [deliveryMessage, setDeliveryMessage] = useState("");
  const [sourceUploadedFiles, setSourceUploadedFiles] = useState([]);
  const [filesUpload, setFilesUpload] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const workFileInput = useRef(null);

  const { data, refetch } = useGetOrder(id);
  const { user } = useUser();

  const socket = useContext(SocketContext);

  const truncate = (input) =>
    input.length > 12
      ? `...${input.substring(input.length - 12, input.length)}`
      : input;

  const handleSourceUploadFile = (files) => {
    const uploaded = [...sourceUploadedFiles];

    files.some((file) => {
      //   if (uploaded.findIndex((f) => f.name === file.name) === -1) {
      uploaded.push(file);
      //   }
    });

    setSourceUploadedFiles(uploaded);
  };

  const handleSourceFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleSourceUploadFile(chosenFiles);
  };

  const deleteSourceFile = (id) => {
    const newUploadedFiles = sourceUploadedFiles.filter((f, idx) => idx !== id);
    setSourceUploadedFiles(newUploadedFiles);
  };

  useEffect(() => {
    if (!workFileInput.current || !workFileInput.current.files[0]) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(workFileInput.current.files[0]);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [workFileInput.current?.value]);

  const own = user;

  useEffect(() => {
    const handleChat = (obj) => {
      if (
        data &&
        data._id === obj.order_id &&
        (own._id === data.user_id._id || own._id === data.seller_id._id)
      ) {
        const newData = [
          ...chat,
          {
            _id: obj._id,
            type: "chat",
            value: {
              name: obj.name,
              text: obj.text,
              files: obj.files,
            },
            date: obj.created_at,
            files: obj.files,
          },
        ];
        newData.sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        });
        setChat(newData);
      }
    };

    const socketReview = (obj) => {
      if (
        data &&
        data._id === obj.order_id &&
        (own._id === data.user_id._id || own._id === data.seller_id._id)
      ) {
        data.reviews = [...data.reviews, obj];
        const newData = [
          ...chat,
          {
            _id: obj._id,
            type: "review",
            value: { message: obj.message, stars: obj.stars, name: obj.name },
            date: obj.created_at,
          },
        ];
        newData.sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        });
        setChat(newData);
      }
    };

    socket.on("newChatOrder", handleChat);
    socket.on("newReview", socketReview);

    return () => {
      socket.off("newChatOrder");
      socket.off("newReview");
    };
  }, [data, data?._id, socket, chat, own]);

  useEffect(() => {
    if (data) {
      const newData = [
        ...data.reviews.map((r) => ({
          _id: r._id,
          type: "review",
          value: { name: r.user_id.name, message: r.message, stars: r.stars },
          date: r.created_at,
        })),
        ...data.messages.map((m) => ({
          _id: m._id,
          type: "chat",
          value: { text: m.text, name: m.user_id.name, files: m.files },
          date: m.created_at,
        })),
        ...data.deliveries.map((d) => ({
          _id: d._id,
          type: "delivery",
          value: {
            message: d.message,
            name: data.seller_id.name,
            status: d.status,
            work: d.work,
            source_files: d.source_files,
          },
          date: d.created_at,
        })),
      ];
      newData.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
      setChat(newData);
    }
  }, [data]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClick = () => {
    workFileInput.current.click();
  };
  const deleteWorkFile = () => {
    setPreview(null);
  };

  const handleSend = async () => {
    if (message) {
      if (filesUpload.length > 0) {
        setLoading(true);
        const formData = new FormData();
        filesUpload.forEach((file) => {
          formData.append("files", file);
        });
        formData.append("order_id", data._id);
        formData.append("text", message);
        const res = await filesMessage(formData);

        setLoading(false);
        setMessage("");
        setFilesUpload([]);
      } else {
        socket.emit("newMessage", { order_id: data._id, text: message });
        setMessage("");
      }
    }
  };

  const handleSendFiles = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleFilesUpload(chosenFiles);
  };

  const handleFilesUpload = (files) => {
    const uploaded = [...filesUpload];

    files.some((file) => {
      uploaded.push(file);
    });

    setFilesUpload(uploaded);
  };

  const deleteFilesUpload = (id) => {
    const newUploadedFiles = filesUpload.filter((f, idx) => idx !== id);
    setFilesUpload(newUploadedFiles);
  };

  const handleReview = () => {
    if (message) {
      socket.emit("newReview", {
        order_id: data._id,
        message: message,
        stars: stars,
      });
      setMessage("");
    }
  };
  const handleComplete = async (id) => {
    const res = await completeDelivery(id);
    refetch();
  };
  const handleReject = async (id) => {
    const res = await rejectDelivery(id);
    refetch();
  };

  const handleCreateDelivery = async () => {
    const valid =
      workFileInput.current.files.length > 0 || sourceUploadedFiles.length > 0;
    if (!deliveryMessage || !valid) {
      return toast.warning("Faltan agregar algunos campos.");
    }
    const formData = new FormData();
    formData.append("work_file", workFileInput.current.files[0]);
    sourceUploadedFiles.forEach((file) => {
      formData.append("source_files", file);
    });
    formData.append("order_id", data._id);
    formData.append("message", deliveryMessage);
    const res = await createDelivery(formData);
    handleClose();
    refetch();
  };
  const getImage = (image) => {
    if (image) {
      if (
        image.includes("http") ||
        image.includes("https") ||
        image.includes(".com")
      ) {
        return image;
      }
      return `${app.serverURL}/${image}`;
    }
  };

  const chatStatus = (status) => {
    switch (status) {
      case "APPROVED":
        return (
          <ChatBox>
            <h4>{own.name}</h4>
            <textarea
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            ></textarea>
            <FilesContainer>
              <FilesWrapper>
                {filesUpload.map((file, idx) => (
                  <>
                    {file.type === "image/png" ||
                    file.type === "image/jpg" ||
                    file.type === "image/jpeg" ? (
                      <ImageContainer>
                        <div className="content">
                          <CloseFile onClick={() => deleteFilesUpload(idx)}>
                            <img src={CloseIcon} alt="close-file" />
                          </CloseFile>
                          <img
                            src={URL.createObjectURL(file)}
                            alt={"file-" + idx}
                            className="image-file"
                          />
                        </div>
                      </ImageContainer>
                    ) : (
                      <FileContainer>
                        <div className="content">
                          <CloseFile onClick={() => deleteFilesUpload(idx)}>
                            <img src={CloseIcon} alt="close-file" />
                          </CloseFile>
                          <img
                            src={FileIcon}
                            alt={"file-" + idx}
                            className="file"
                          />
                          <span>{truncate(file.name)}</span>
                        </div>
                      </FileContainer>
                    )}
                  </>
                ))}
              </FilesWrapper>
            </FilesContainer>
            <FilesBtn>
              Adjuntar archivos{" "}
              <input
                type="file"
                multiple
                onChange={handleSendFiles}
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
            </FilesBtn>

            <SendBtn onClick={handleSend} disabled={loading}>
              Enviar
            </SendBtn>
          </ChatBox>
        );
      case "COMPLETED":
        const review = data.reviews.find((r) => r.user_id._id === own._id);
        const valid = data.seller_id._id === own._id ? !data.reviewed : false;

        if (
          !review &&
          (data.user_id._id == own._id || data.seller_id._id == own._id) &&
          !valid
        ) {
          return (
            <ChatBox>
              <h4>{"Reseña"}</h4>
              <Rating
                name="simple-controlled"
                value={stars}
                onChange={(event, newValue) => {
                  setStars(newValue);
                }}
              />
              <h4>{own.name}</h4>
              <textarea
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              ></textarea>
              <SendBtn onClick={handleReview}>Enviar</SendBtn>
            </ChatBox>
          );
        }
        return <h4>La orden fue completado</h4>;

      default:
        return (
          <ChatBox>
            <h4>{own.name}</h4>
            <textarea
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            ></textarea>
            <FilesContainer>
              <FilesWrapper>
                {filesUpload.map((file, idx) => (
                  <>
                    {file.type === "image/png" ||
                    file.type === "image/jpg" ||
                    file.type === "image/jpeg" ? (
                      <ImageContainer>
                        <div className="content">
                          <CloseFile onClick={() => deleteFilesUpload(idx)}>
                            <img src={CloseIcon} alt="close-file" />
                          </CloseFile>
                          <img
                            src={URL.createObjectURL(file)}
                            alt={"file-" + idx}
                            className="image-file"
                          />
                        </div>
                      </ImageContainer>
                    ) : (
                      <FileContainer>
                        <div className="content">
                          <CloseFile onClick={() => deleteFilesUpload(idx)}>
                            <img src={CloseIcon} alt="close-file" />
                          </CloseFile>
                          <img
                            src={FileIcon}
                            alt={"file-" + idx}
                            className="file"
                          />
                          <span>{truncate(file.name)}</span>
                        </div>
                      </FileContainer>
                    )}
                  </>
                ))}
              </FilesWrapper>
            </FilesContainer>
            <FilesBtn>
              Adjuntar archivos{" "}
              <input
                type="file"
                multiple
                onChange={handleSendFiles}
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
            </FilesBtn>

            <SendBtn onClick={handleSend} disabled={loading}>
              Enviar
            </SendBtn>
          </ChatBox>
        );
    }
  };

  const handleDownload = (url, filename) => {
    if (url.indexOf("https") === -1) {
      url = "https://" + url;
    }
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const workFile = workFileInput?.current?.files[0] || false;

  return (
    <OrderDetailContainer>
      <OrderDetailWrapper>
        <OrderChat>
          {chat.map((msg) => (
            <div key={msg._id}>
              <ChatMessage>
                {msg.type == "chat" && (
                  <>
                    <h4>{msg.value.name}</h4>
                    <p>{msg.value.text}</p>

                    {msg.value?.files.length > 0 && (
                      <FilesContainer>
                        <FilesWrapper>
                          {msg.value?.files.map((file, idx) => (
                            <>
                              {file.type === "image/png" ||
                              file.type === "image/jpg" ||
                              file.type === "image/jpeg" ? (
                                <ImageContainer>
                                  <div className="content">
                                    <img
                                      src={getImage(file.image)}
                                      alt={"file-" + idx}
                                      className="image-file"
                                    />
                                  </div>
                                  <PrimaryBtn
                                    onClick={() =>
                                      handleDownload(
                                        getImage(file.image),
                                        file.name
                                      )
                                    }
                                  >
                                    Descargar
                                  </PrimaryBtn>
                                </ImageContainer>
                              ) : (
                                <FileContainer>
                                  <div className="content">
                                    <img
                                      src={FileIcon}
                                      alt={"file-" + idx}
                                      className="file"
                                    />
                                    <span>{truncate(file.name)}</span>
                                  </div>
                                  <PrimaryBtn
                                    onClick={() =>
                                      handleDownload(
                                        getImage(file.image),
                                        file.name
                                      )
                                    }
                                  >
                                    Descargar
                                  </PrimaryBtn>
                                </FileContainer>
                              )}
                            </>
                          ))}
                        </FilesWrapper>
                      </FilesContainer>
                    )}
                  </>
                )}
                {msg.type == "review" && (
                  <DeliveryContainer>
                    <h4>{msg.value.name}</h4>
                    <p>{msg.value.message}</p>
                    <p>{msg.value.stars}</p>
                  </DeliveryContainer>
                )}
                {msg.type == "delivery" && (
                  <DeliveryContainer>
                    <h4>{msg.value.name}</h4>
                    <p>{msg.value.message}</p>
                    <br />
                    <h4>Adjunto</h4>

                    <FilesContainer>
                      <FilesWrapper>
                        {msg.value.source_files.map((file, idx) => (
                          <>
                            {file.type === "image/png" ||
                            file.type === "image/jpg" ||
                            file.type === "image/jpeg" ? (
                              <ImageContainer>
                                <div className="content">
                                  <img
                                    src={getImage(file.image)}
                                    alt={"file-" + idx}
                                    className="image-file"
                                  />
                                </div>
                                <PrimaryBtn
                                  onClick={() =>
                                    handleDownload(
                                      getImage(file.image),
                                      file.name
                                    )
                                  }
                                >
                                  Descargar
                                </PrimaryBtn>
                              </ImageContainer>
                            ) : (
                              <FileContainer>
                                <div className="content">
                                  <img
                                    src={FileIcon}
                                    alt={"file-" + idx}
                                    className="file"
                                  />
                                  <span>{truncate(file.name)}</span>
                                </div>
                                <PrimaryBtn
                                  onClick={() =>
                                    handleDownload(
                                      getImage(file.image),
                                      file.name
                                    )
                                  }
                                >
                                  Descargar
                                </PrimaryBtn>
                              </FileContainer>
                            )}
                          </>
                        ))}
                      </FilesWrapper>
                    </FilesContainer>
                    <FilesContainer>
                      <FilesWrapper>
                        {msg.value.work.length > 0 && (
                          <ImageContainer>
                            <div className="content">
                              <img
                                src={getImage(msg.value.work[0])}
                                alt="work"
                                className="image-file"
                              />
                            </div>
                            <PrimaryBtn
                              onClick={() =>
                                handleDownload(
                                  getImage(msg.value.work[0]),
                                  msg.value.work[0]
                                )
                              }
                            >
                              Descargar
                            </PrimaryBtn>
                          </ImageContainer>
                        )}
                      </FilesWrapper>
                    </FilesContainer>
                    {msg.value.status === "PROGRESS" &&
                    data.user_id._id === own._id ? (
                      <DeliveryButtons>
                        <PrimaryBtn onClick={() => handleComplete(msg._id)}>
                          Sí, aprobar pedido
                        </PrimaryBtn>
                        <PrimaryBtn onClick={() => handleReject(msg._id)}>
                          No, aún no esta listo
                        </PrimaryBtn>
                      </DeliveryButtons>
                    ) : null}
                  </DeliveryContainer>
                )}
              </ChatMessage>
            </div>
          ))}
          {data && chatStatus(data.status)}
        </OrderChat>

        <OrderBoxContainter>
          {data && data.status === "PROGRESS" && (
            <OrderDetailBox>
              <OrderHeader>
                <h1>Tiempo de entrega</h1>
              </OrderHeader>
              <Timer
                created_at={data.created_at}
                timelapse={data.service.timelapse}
              />
              {data && own._id === data.seller_id._id && (
                <DeliveryBtn onClick={handleOpen}>Entregar ahora</DeliveryBtn>
              )}
            </OrderDetailBox>
          )}
          <OrderDetailBox>
            <OrderHeader>
              <h1>Detalles del pedido</h1>
            </OrderHeader>
            {data && (
              <>
                <p>{data.service.service_id.title}</p>
                <p></p>
                <p>${data.service.price}</p>
                <p>
                  {moment(data.created_at).add(3, "days").format("D MMM H:M")}
                </p>
                <p>#{data._id}</p>
              </>
            )}
          </OrderDetailBox>
        </OrderBoxContainter>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <CloseModal>
              <img src={CloseIcon} alt="close-icon" onClick={handleClose} />
            </CloseModal>
            <ModalContainer>
              <ModalWrapper>
                <ModalContent>
                  <ModalTitle>Entregar pedido</ModalTitle>
                  <Textarea>
                    <textarea
                      onChange={(e) => setDeliveryMessage(e.target.value)}
                      value={deliveryMessage}
                    ></textarea>
                  </Textarea>
                  <FilesButtonContainer>
                    <PrimaryBtn onClick={handleClick}>
                      Subir imagen de trabajo
                      <input ref={workFileInput} type="file" hidden />
                    </PrimaryBtn>
                    <PrimaryBtn>
                      Subir código fuente
                      <input
                        type="file"
                        multiple
                        onChange={handleSourceFileEvent}
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "absolute",
                          opacity: 0,
                          cursor: "pointer",
                        }}
                      />
                    </PrimaryBtn>
                  </FilesButtonContainer>
                  <FilesContainer>
                    <FilesWrapper>
                      {sourceUploadedFiles.map((file, idx) => (
                        <>
                          {file.type === "image/png" ||
                          file.type === "image/jpg" ||
                          file.type === "image/jpeg" ? (
                            <ImageContainer>
                              <div className="content">
                                <CloseFile
                                  onClick={() => deleteSourceFile(idx)}
                                >
                                  <img src={CloseIcon} alt="close-file" />
                                </CloseFile>
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={"file-" + idx}
                                  className="image-file"
                                />
                              </div>
                            </ImageContainer>
                          ) : (
                            <FileContainer>
                              <div className="content">
                                <CloseFile
                                  onClick={() => deleteSourceFile(idx)}
                                >
                                  <img src={CloseIcon} alt="close-file" />
                                </CloseFile>
                                <img
                                  src={FileIcon}
                                  alt={"file-" + idx}
                                  className="file"
                                />
                                <span>{truncate(file.name)}</span>
                              </div>
                            </FileContainer>
                          )}
                        </>
                      ))}
                    </FilesWrapper>
                  </FilesContainer>
                  {preview && (
                    <>
                      <h4>Una muestra del trabajo</h4>
                      <FilesContainer>
                        <FilesWrapper>
                          {workFile.type === "image/png" ||
                          workFile.type === "image/jpg" ||
                          workFile.type === "image/jpeg" ? (
                            <ImageContainer>
                              <div className="content">
                                <CloseFile onClick={deleteWorkFile}>
                                  <img src={CloseIcon} alt="close-file" />
                                </CloseFile>
                                <img
                                  src={preview}
                                  alt="work"
                                  className="image-file"
                                />
                              </div>
                            </ImageContainer>
                          ) : (
                            <FileContainer>
                              <div className="content">
                                <CloseFile onClick={deleteWorkFile}>
                                  <img src={CloseIcon} alt="close-file" />
                                </CloseFile>
                                <span>Imagen no valido</span>
                              </div>
                            </FileContainer>
                          )}
                        </FilesWrapper>
                      </FilesContainer>
                    </>
                  )}
                  <SendBtn onClick={handleCreateDelivery}>Enviar</SendBtn>
                </ModalContent>
              </ModalWrapper>
            </ModalContainer>
          </Box>
        </Modal>
      </OrderDetailWrapper>
    </OrderDetailContainer>
  );
};

export default OrderDetail;
