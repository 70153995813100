import React, { Fragment } from "react";
import BecomeSeller from "../common/profile/BecomeSeller";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";

function BecomeSellerPage() {
  return (
    <Fragment>
      <Navbar />
      <BecomeSeller />
      <Footer margintop="7rem" />
    </Fragment>
  );
}

export default BecomeSellerPage;
