import React from "react";
import {
  ServicioCardContainer,
  UserHeadingContainer,
  UserBodyContainer,
  UserActionContainer,
  UserDetail,
  UserInfo,
  UserRating,
  ActionBtn,
} from "../../styles/components/cards/ServicioCard.styles";
import StarIcon from "../../assets/icons/star.svg";
import { Link } from "react-router-dom";

function ServicioCard({
  id,
  name,
  detail,
  profilePic,
  userPic,
  rating,
  order,
  description,
  price,
}) {
  return (
    <ServicioCardContainer>
      <Link
        to={"/services/" + id}
        style={{ textDecoration: "none", color: "auto" }}
      >
        <img src={profilePic} alt="profile-pic" />
      </Link>

      <UserHeadingContainer>
        <UserInfo>
          <img src={userPic} alt="user-pic" />
          <UserDetail>
            <p>{name}</p>
            <p>{detail}</p>
          </UserDetail>
        </UserInfo>
        <UserRating>
          <img src={StarIcon} alt="" />
          <p>{rating}</p>
          <p>({order})</p>
        </UserRating>
      </UserHeadingContainer>
      <UserBodyContainer>
        <p>{description.slice(0, 50)}</p>
      </UserBodyContainer>
      <UserActionContainer>
        <p>${price}</p>
        <Link to={"/services/" + id} style={{ textDecoration: "none" }}>
          <ActionBtn>Ver Servicio</ActionBtn>
        </Link>
      </UserActionContainer>
    </ServicioCardContainer>
  );
}

export default ServicioCard;
