import React from "react";
import {
  BannerContainer,
  BannerWrapper,
  BannerCard,
  TextContainer,
  PicContainer,
  BannerLayer,
} from "../../styles/common/landing/Banner.styles";
import BannerImg from "../../assets/landing/banner.svg";
import BannerLayerImg from "../../assets/landing/banner-layer.svg";
import {
  Heading,
  OutlineBtn,
  PrimaryBtn,
  Text,
} from "../../styles/Common.styles";
import { useNavigate } from "react-router";

function Banner() {
  const navigate = useNavigate();

  return (
    <BannerContainer>
      <BannerWrapper>
        <BannerCard>
          <TextContainer>
            <Heading className="banner-text">
              BeeBuk es la primera <span>plataforma online</span> que permite la
              conexión entre Escritores y Profesionales Editoriales freelancers.
            </Heading>

            <PrimaryBtn
              className="banner-button"
              onClick={() => navigate("/signup")}
            >
              Registrate Ahora
            </PrimaryBtn>
          </TextContainer>
          <BannerLayer>
            <img src={BannerLayerImg} alt="banner-layer-img" />
          </BannerLayer>
          <PicContainer>
            <img src={BannerImg} alt="banner-img" />
          </PicContainer>
          {/* <TextContainer>
          <Heading className="header-text">
            Encuentra al <span>Autopublicación</span>
            <br />
            Que necesitas
          </Heading>
          <ButtonsContainer>
            <PrimaryBtn onClick={() => navigate("/signup")}>
              Encuentra talentos
            </PrimaryBtn>
            <OutlineBtn className="text-black">Ofrece tus servicios</OutlineBtn>
          </ButtonsContainer>
        </TextContainer>
        <PicContainer>
          <img src={HeaderImg} alt="header-img" />
        </PicContainer> */}
        </BannerCard>
      </BannerWrapper>
    </BannerContainer>
  );
}

export default Banner;
