import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnCenterCenter,
  flexColumnStartStart,
  flexRowCenterCenter,
  flexRowStartStart,
} from "../../../common/scheme";

export const BannerContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
}));
export const BannerWrapper = styled(Box)(({ theme }) => ({
  width: "1100px",
  padding: "90px",
  ...flexRowCenterCenter,

  [theme.breakpoints.down("lg")]: {
    padding: "50px 20px",
  },
}));

export const BannerCard = styled(Box)(({ theme }) => ({
  background: "#0592A7",
  borderRadius: "12px",
  width: "100%",
  height: "100%",
  position: "relative",
  display: "flex",

  "& img": {
    height: "100%",
  },
}));

export const TextContainer = styled(Box)(({ theme }) => ({
  marginTop: "55px",
  marginBottom: "55px",
  marginLeft: "75px",
  width: "75%",
  ...flexColumnStartStart,
  gap: "20px",
  zIndex: 2,

  "& .banner-text": {
    fontSize: "44px",
    lineHeight: "50px",
    color: "white",
    "& span": {
      background: "#F5EE9E",
      webkitBackgroundClip: "text",
      webKitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
    },
  },
  "& .banner-button": {
    zIndex: 2,
    color: theme.palette.colors.primary,
    backgroundColor: theme.palette.text.white,
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    ...flexColumnCenterCenter,
    textAlign: "center",
    marginLeft: "1rem",
    marginRight: "1rem",
  },

  [theme.breakpoints.down("sm")]: {
    "& .banner-text": {
      fontSize: "24px",
    },
  },
}));

export const BannerLayer = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: 0,
  top: "50px",
  left: "-30px",
  overflow: "hidden",
  height: "100%",

  "& img": {
    width: "100%",
  },

  [theme.breakpoints.down("sm")]: {
    top: "0px",
  },
}));

export const PicContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  display: "flex",
  zIndex: 1,
  "& img": {
    maxWidth: "500px",
    height: "100%",
    width: "100%",
  },
}));
