import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnCenterCenter,
  flexRowStartCenter,
} from "../../../common/scheme";

export const PaginationContainer = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: ".5rem",
  "& p": {
    fontSize: "10px",
    fontWeight: "400",
  },
}));
export const PageNumber = styled(Box)(({ theme }) => ({
  width: "26px",
  height: "26px",
  borderRadius: "4px",
  border: `1px solid ${theme.palette.pagination.border}`,
  fontWeight: "400",
  fontSize: "10px",
  ...flexColumnCenterCenter,
}));
export const PrevPage = styled(Box)(({ theme }) => ({
  width: "26px",
  height: "26px",
  borderRadius: "4px",
  fontWeight: "400",
  fontSize: "10px",
  ...flexColumnCenterCenter,
  backgroundColor: theme.palette.pagination.prevPage,
  cursor: "pointer",
}));
export const NextPage = styled(Box)(({ theme }) => ({
  width: "26px",
  height: "26px",
  borderRadius: "4px",
  fontWeight: "400",
  fontSize: "10px",
  backgroundColor: theme.palette.servicio.border,
  ...flexColumnCenterCenter,
  cursor: "pointer",
}));
