import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import ProfileFormCard from "../../components/cards/ProfileFormCard";
import {
  ServiceCreateContainer,
  ServiceCreateWrapper,
  ContainerBtn,
} from "../../styles/common/servicios/Create.styles";
import { ProfileCardWrapper } from "../../styles/common/profile/UserProfile.styles";
import { FlexRowStartCenter, PrimaryBtn } from "../../styles/Common.styles";
import { useUser, updateUser } from "../../api/auth";
import { app } from "../../utils/config";
import { toast } from "react-toastify";

function Create({ edit }) {
  const [userData, setUserData] = useState({});
  const [userFile, setUserFile] = useState({});

  const { user, refetch } = useUser();

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const userFormData = new FormData();
    userFormData.append("name", userData.name);
    userFormData.append("description", userData.description);
    userFormData.append("info", userData.info);
    userFormData.append("country", userData.country);
    userFormData.append("image_url", userFile);
    await updateUser(userFormData);
    toast.success("El perfil se actualizó con éxito.");
    refetch();
  };

  useEffect(() => {
    if (user) {
      setUserData({
        name: user.name,
        info: user.info,
        country: user.country,
        description: user.description,
      });

      if (user.image_url) {
        fetch(getImage(user.image_url))
          .then(function (response) {
            return response.blob();
          })
          .then(function (blob) {
            return new File([blob], `${user.image_url}`, {
              type: blob.type,
            });
          })
          .then((file) => {
            setUserFile(file);
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  }, [user]);

  const getImage = (image) => {
    if (image.includes("http") || image.includes("https")) {
      return image;
    }
    return `${app.serverURL}/${image}`;
  };

  const handleUser = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUserFile = (e) => {
    setUserFile(e.target.files[0]);
  };

  return (
    <ServiceCreateContainer>
      <ServiceCreateWrapper>
        <ProfileCardWrapper>
          <ProfileFormCard
            country={userData.country}
            name={userData.name}
            profesion={userData.info}
            description={userData.description}
            created_at={userData.created_at}
            handleUser={handleUser}
            userFile={userFile}
            handleUserFile={handleUserFile}
          />
          <ContainerBtn>
            <PrimaryBtn onClick={handleSubmit}>{"Actualizar"}</PrimaryBtn>
          </ContainerBtn>
        </ProfileCardWrapper>
      </ServiceCreateWrapper>
    </ServiceCreateContainer>
  );
}

export default Create;
