import React from "react";
import { Text } from "../../styles/Common.styles";
import { TestimonialCardContainer } from "../../styles/components/cards/TestimonialCard";

function TestimonialCard({ icon, titile, description }) {
  return (
    <TestimonialCardContainer>
      <img src={icon} alt="icon" />
      <h1>{titile}</h1>
      <Text>{description}</Text>
    </TestimonialCardContainer>
  );
}

export default TestimonialCard;
