import React from "react";
import {
  OrderCardContainer,
  UserDetailContainer,
  DetailWrapper,
  StatusBox,
  OrderBtn,
} from "../../styles/components/cards/OrderCard.styles";
import { Link } from "react-router-dom";

function OrderCard({
  id,
  profilePic,
  userPic,
  userName,
  userLocation,
  price,
  dueIn,
  status,
  link,
  deliveryAt,
}) {
  return (
    <OrderCardContainer>
      <img src={profilePic} alt="profile-pic" />
      <UserDetailContainer>
        <img src={userPic} alt="user-pic" />
        <DetailWrapper>
          <h1>{userName}</h1>
          <h2>{userLocation}</h2>
        </DetailWrapper>
      </UserDetailContainer>
      <DetailWrapper>
        <p>Precio</p>
        <h1>{price}</h1>
      </DetailWrapper>
      {dueIn && (
        <DetailWrapper>
          <p>Previsto en</p>
          <h1>{dueIn}</h1>
        </DetailWrapper>
      )}
      {deliveryAt && (
        <DetailWrapper>
          <p>Entregado en</p>
          <h1>{deliveryAt}</h1>
        </DetailWrapper>
      )}

      <DetailWrapper>
        <p>Estado</p>
        <StatusBox>{status}</StatusBox>
      </DetailWrapper>
      <Link to={link} style={{ textDecoration: "none" }}>
        <OrderBtn>Ver Pedido</OrderBtn>
      </Link>
    </OrderCardContainer>
  );
}

export default OrderCard;
