import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnCenterCenter,
  flexColumnStartStart,
  flexRowCenterCenter,
  flexRowStartStart,
} from "../../../common/scheme";

export const HeaderContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
}));
export const HeaderWrapper = styled(Box)(({ theme }) => ({
  width: "1100px",
  height: "700px",
  ...flexRowCenterCenter,
  alignItem: "flex-start",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    ...flexColumnCenterCenter,
    flexDirection: "column-reverse",
    height: "100%",
  },
}));

export const TextContainer = styled(Box)(({ theme }) => ({
  marginTop: "5rem",
  marginBottom: "5rem",
  width: "58%",
  ...flexColumnStartStart,
  gap: "1rem",

  "& .header-text": {
    "& span": {
      background: "linear-gradient(131.49deg, #03ACB5 26.53%, #684FFF 100.48%)",
      webkitBackgroundClip: "text",
      webKitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
    },
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    ...flexColumnCenterCenter,
    textAlign: "center",
    marginLeft: "1rem",
    marginTop: "0",
    marginRight: "1rem",
  },

  [theme.breakpoints.down("sm")]: {
    "& .header-text": {
      fontSize: "24px",
    },
  },
}));
export const PicContainer = styled(Box)(({ theme }) => ({
  width: "45%",
  "& img": {
    width: "500px",
    height: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90vw",
    margin: "20px",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  ...flexRowStartStart,
  alignItems: "center",
  gap: "1rem",
}));
