import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PrimaryBtn } from "../../Common.styles";

export const TableHeading = styled(Box)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "700",
  color: theme.palette.text.heading,
  textTransform: "uppercase",
}));
export const TablePricing = styled(Box)(({ theme }) => ({
  fontSize: "25px",
  fontWeight: "400",
  color: theme.palette.text.heading,
  marginTop: ".25rem",
}));
export const TableText = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "400",
  color: theme.palette.text.heading,
}));
export const SelectBtn = styled(PrimaryBtn)(({ theme }) => ({
  "&:hover": {
    padding: "8px 18px",
  },
}));
