import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Pic1 from "../../assets/slider/pic1.jpeg";
import Pic2 from "../../assets/slider/pic2.jpeg";
import Pic3 from "../../assets/slider/pic3.png";
import Pic4 from "../../assets/slider/pic4.png";
import NextArrowIcon from "../../assets/slider/next-arrow.svg";
import PrevArrowIcon from "../../assets/slider/prev-arrow.svg";
import {
  FormContainer,
  InputContainer,
  InputWrapper,
  InputLabel,
  ImageSliderContainer,
} from "../../styles/common/servicios/Create.styles";
import { PrimaryBtn } from "../../styles/Common.styles";

function SamplePrevArrow(props) {
  const { onClick, imgSrc, imgAlt, className } = props;

  return (
    <img src={imgSrc} alt={imgAlt} onClick={onClick} className={className} />
  );
}

function SampleNextArrow(props) {
  const { className, onClick, imgSrc, imgAlt } = props;
  return (
    <img src={imgSrc} alt={imgAlt} onClick={onClick} className={className} />
  );
}

class UserSlider extends Component {
  state = {
    picsData: [],
  };

  render() {
    const {
      title,
      setTitle,
      handleFileEvent,
      uploadedFiles,
      deleteFile,
      edit,
      data,
    } = this.props;

    const picsData = [
      { id: 1, pic: Pic1 },
      { id: 2, pic: Pic2 },
      { id: 3, pic: Pic3 },
      { id: 4, pic: Pic4 },
    ];

    const settings = {
      customPaging: function (i) {
        return (
          <img
            // src={picsData[i].pic}
            src={uploadedFiles[i] && URL.createObjectURL(uploadedFiles[i])}
            className="bottom-pics"
            alt="pics"
            style={{ borderRadius: ".5rem" }}
          />
        );
      },
      dots: true,
      infinite: true,
      speed: 250,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <SampleNextArrow imgSrc={NextArrowIcon} imgAlt="next-icon" />,
      prevArrow: <SamplePrevArrow imgSrc={PrevArrowIcon} imgAlt="prev-icon" />,
    };

    const style = { borderRadius: ".5rem" };

    return (
      <div>
        <FormContainer>
          <InputWrapper>
            <h2>Titulo del servicio</h2>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </InputWrapper>
        </FormContainer>
        <br />

        <PrimaryBtn>
          <input
            type="file"
            multiple
            onChange={handleFileEvent}
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              opacity: 0,
              cursor: "pointer",
            }}
          />
          Agregar Imagen
        </PrimaryBtn>
        {/* <br /> */}
        <Slider {...settings}>
          {uploadedFiles.map(
            (file, idx) =>
              file && (
                <ImageSliderContainer>
                  <PrimaryBtn onClick={() => deleteFile(idx)}>
                    Borrar
                  </PrimaryBtn>
                  <img
                    src={URL.createObjectURL(file)}
                    width="100%"
                    height="340px"
                    style={style}
                    alt={file.name}
                  />
                  {/* <img
                src={data.pic}
                alt="pic1"
                width="100%"
                height="340px"
                style={style}
              /> */}
                  <br />
                </ImageSliderContainer>
              )
          )}
        </Slider>
      </div>
    );
  }
}

export default UserSlider;
