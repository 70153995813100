import React from "react";
import {
  HeaderContainer,
  TextContainer,
  PicContainer,
  HeaderWrapper,
} from "../../styles/common/about_us/Header.styles";
import HeaderImg from "../../assets/about_us/header-pic.svg";
import HeaderVideo from "../../assets/about_us/header-video.mov";
import { Heading, PrimaryBtn, Text } from "../../styles/Common.styles";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <HeaderWrapper>
        <TextContainer>
          <Heading className="header-text">
            Somos Una Startup Que Ofrece Una Opción Digital, Fácil Y Rápida De
            <br />
            <span>Autopublicación</span>
          </Heading>
          {/* <Text>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </Text> */}

          <PrimaryBtn onClick={() => navigate("/signup")}>
            Get Started
          </PrimaryBtn>
        </TextContainer>

        <PicContainer>
          <video muted autoPlay loop>
            <source src={HeaderVideo} type="video/mp4" />
          </video>
          {/* <img src={HeaderImg} alt="header-img" /> */}
        </PicContainer>
      </HeaderWrapper>
    </HeaderContainer>
  );
}

export default Header;
