import React, { useState, useRef, useEffect, useMemo } from "react";
import Select from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import { useParams, useNavigate } from "react-router";
import ProfileFormCard from "../../components/cards/ProfileFormCard";
import {
  ServiceCreateContainer,
  ServiceCreateWrapper,
  SliderContainer,
  DescriptionContainer,
  FormContainer,
  InputContainer,
  InputWrapper,
  InputLabel,
  ContainerBtn,
} from "../../styles/common/servicios/Create.styles";
import { ProfileCardWrapper } from "../../styles/common/profile/UserProfile.styles";
import UserSlider from "./UserSlider";
import PricingTable from "./PricingTable";
import { FlexRowStartCenter, PrimaryBtn } from "../../styles/Common.styles";
import { createService, useGetService, updateService } from "../../api/service";
import { useUser, updateUser } from "../../api/auth";
import { useGetCategories } from "../../api/category";
import { app } from "../../utils/config";
import { toast } from "react-toastify";

function Create({ edit }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [plans, setPlans] = useState([]);
  const [newData2, setNewData2] = useState([]);
  const [userData, setUserData] = useState({});
  const [userFile, setUserFile] = useState({});
  const [categoryId, setCategoryId] = useState("");

  const { user, refetch } = useUser();
  const { data: categories } = useGetCategories();

  const params = useParams();
  const { data, refetch: refetchService } = useGetService(params.id);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    let obj = {};
    ["basic", "standard", "premium"].map((p) => {
      newData2.map((plan) => {
        if (plan.id === "price" || plan.id === "delivery_time") {
          obj[p] = { ...obj[p], [plan.id]: plan[p] };
        } else {
          if (obj[p] && obj[p].features) {
            obj[p] = {
              ...obj[p],
              features: [
                ...obj[p].features,
                { name: plan.name, value: plan[p], id: plan.id },
              ],
            };
          } else {
            obj[p] = {
              ...obj[p],
              features: [{ name: plan.name, value: plan[p], id: plan.id }],
            };
          }
        }
      });
    });

    if (uploadedFiles.length <= 0 || !title || !description || !categoryId) {
      return toast.warning("Faltan agregar algunos campos.");
    }

    if (
      obj.basic.delivery_time <= 0 ||
      obj.basic.price <= 0 ||
      obj.standard.delivery_time <= 0 ||
      obj.standard.price <= 0 ||
      obj.premium.delivery_time <= 0 ||
      obj.premium.price <= 0
    ) {
      return toast.warning(
        "Deben ser mayores a 0 el precio y el tiempo de entrega."
      );
    }

    if (
      !obj.basic.features ||
      obj.basic.features.length <= 0 ||
      obj.standard.features.length <= 0 ||
      obj.premium.features.length <= 0
    ) {
      return toast.warning("Debes agregar un plan por lo menos.");
    }

    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append("images", file);
    });
    formData.append("title", title);
    formData.append("description", description);
    formData.append("plan", JSON.stringify(obj));
    formData.append("category_id", categoryId);

    let res;
    let res1;
    if (edit) {
      res = await updateService(data._id, formData);
      const userFormData = new FormData();
      userFormData.append("name", userData.name);
      userFormData.append("description", userData.description);
      userFormData.append("info", userData.info);
      userFormData.append("country", userData.country);
      userFormData.append("image_url", userFile);
      res1 = await updateUser(userFormData);
    } else {
      res1 = await createService(formData);
    }
    refetch();
    refetchService();
    navigate("/services/manager");
  };

  const handleUploadFile = (files) => {
    const uploaded = [...uploadedFiles];

    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });

    setUploadedFiles(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFile(chosenFiles);
  };

  const deleteFile = (id) => {
    const newUploadedFiles = uploadedFiles.filter((f, idx) => idx !== id);
    setUploadedFiles(newUploadedFiles);
  };

  const getImage = (image) => {
    if (image.includes("http") || image.includes("https")) {
      return image;
    }
    return `${app.serverURL}/${image}`;
  };

  useEffect(() => {
    if (user) {
      setUserData({
        name: user.name,
        info: user.info,
        country: user.country,
        description: user.description,
      });
      fetch(getImage(user.image_url))
        .then(function (response) {
          return response.blob();
        })
        .then(function (blob) {
          return new File([blob], `${user.image_url}`, {
            type: blob.type,
          });
        })
        .then((file) => {
          setUserFile(file);
        });
    }
  }, [user]);

  useEffect(() => {
    if (data) {
      const fetchData = async () => {
        setTitle(data.title);
        setDescription(data.description);
        setCategoryId(data.category_id);

        const images = await Promise.all(
          data.images.map(async (image) => {
            return fetch(getImage(image))
              .then(function (response) {
                return response.blob();
              })
              .then(function (blob) {
                return new File([blob], `${image}`, {
                  type: blob.type,
                });
              })
              .then((file) => {
                return file;
              });
          })
        );
        setUploadedFiles(images);

        let arr = {};
        const { basic, standard, premium } = data.planId;
        const plansData = { basic, standard, premium };
        Object.keys(plansData).map((p) => {
          arr["0"] = {
            ...arr["0"],
            name: "Precio",
            id: "price",
            [p]: plansData[p].price,
          };
          arr["1"] = {
            ...arr["1"],
            name: "Tiempo de entrega",
            id: "delivery_time",
            [p]: plansData[p].delivery_time,
          };
          plansData[p].features.map((feat) => {
            arr[feat.id] = {
              ...arr[feat.id],
              id: feat.id,
              name: feat.name,
              [p]: feat.value,
            };

            return { id: feat.id, name: feat.name, [p]: feat.value };
          });
        });
        setNewData2(Object.values(arr));
        setPlans(Object.values(arr));
      };
      fetchData();
    }
  }, [data]);

  const handleUser = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUserFile = (e) => {
    setUserFile(e.target.files[0]);
  };

  return (
    <ServiceCreateContainer>
      <ServiceCreateWrapper edit={edit}>
        {edit && (
          <ProfileCardWrapper>
            <ProfileFormCard
              country={userData.country}
              name={userData.name}
              profesion={userData.info}
              description={userData.description}
              created_at={userData.created_at}
              handleUser={handleUser}
              userFile={userFile}
              handleUserFile={handleUserFile}
            />
          </ProfileCardWrapper>
        )}

        <SliderContainer edit={edit}>
          <UserSlider
            title={title}
            setTitle={setTitle}
            handleFileEvent={handleFileEvent}
            uploadedFiles={uploadedFiles}
            deleteFile={deleteFile}
            edit={edit}
            data={data}
          />

          <h1 className="price-heading">Precio</h1>
          <PricingTable
            plans={plans}
            setPlans={setPlans}
            newData2={newData2}
            setNewData2={setNewData2}
            edit={edit}
          />
          <DescriptionContainer>
            <h1>Descripción del servicio</h1>

            <FormContainer>
              <InputWrapper>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </InputWrapper>
            </FormContainer>
          </DescriptionContainer>

          <DescriptionContainer>
            <h1>Categoria</h1>
            {/* <select
              value={categoryId}
              onChange={(e) => setCategoryId(e.target.value)}
            >
              {categories &&
                categories.map((category) => (
                  <option value={category._id}>{category.name}</option>
                ))}
            </select> */}
            {categories && (
              <Select
                value={categoryId}
                displayEmpty
                onChange={(e) => setCategoryId(e.target.value)}
                sx={{ borderRadius: ".5rem" }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <span className="placeholder">
                        Selecciona una categoría
                      </span>
                    );
                  }

                  // return selected.join(", ");
                  return categories.find((c) => c._id === selected).name;
                }}
              >
                <MenuItem disabled value="">
                  Selecciona una categoría
                </MenuItem>
                {categories.map(({ _id, value, name }) => (
                  <MenuItem key={_id} value={_id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </DescriptionContainer>
          <ContainerBtn>
            <PrimaryBtn onClick={handleSubmit}>
              {edit ? "Actualizar" : "Crear"}
            </PrimaryBtn>
          </ContainerBtn>
        </SliderContainer>
      </ServiceCreateWrapper>
    </ServiceCreateContainer>
  );
}

export default Create;
