import React, { Fragment } from "react";
import CheckoutDetail from "../common/checkout/CheckoutDetail";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";

function Checkout() {
  return (
    <Fragment>
      <Navbar />
      <CheckoutDetail />
      <Footer margintop="7rem" />
    </Fragment>
  );
}

export default Checkout;
