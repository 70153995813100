import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDown from "../../assets/icons/arrow-down.svg";

function Filter({ filterData, filterWidth, handleFilter }) {
  const [filterValue, setFilterValue] = React.useState("most_recent");

  const handleChange = (event) => {
    setFilterValue(event.target.value);
    handleFilter(event);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: filterWidth }} size="small">
      <InputLabel id="demo-select-small">{"Filtro"}</InputLabel>
      <Select
        labelId="demo-select-small"
        value={filterValue}
        label="Filtro"
        onChange={handleChange}
        sx={{ borderRadius: ".5rem" }}
        IconComponent={() => (
          <img
            src={ArrowDown}
            alt="arrow-down"
            style={{
              marginRight: ".5rem",
            }}
          />
        )}
      >
        {filterData.map(({ id, value, title }) => (
          <MenuItem key={id} value={value}>
            {title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Filter;
