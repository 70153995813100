import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  HeaderContainer,
  TextContainer,
  PicContainer,
  HeaderWrapper,
  ButtonsContainer,
} from "../../styles/common/landing/Header.styles";
import HeaderImg from "../../assets/landing/header.svg";
import {
  Heading,
  OutlineBtn,
  PrimaryBtn,
  Text,
} from "../../styles/Common.styles";
import { serviceData } from "../../data/data";

function Header() {
  const [service, setService] = useState(serviceData[0]);
  const navigate = useNavigate();

  const shuffle = useCallback(() => {
    const index = serviceData.length > service.id ? service.id : 0;
    setService(serviceData[index]);
  }, [service]);

  useEffect(() => {
    const intervalID = setInterval(shuffle, 3000);
    return () => clearInterval(intervalID);
  }, [shuffle]);

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <TextContainer>
          <Heading className="header-text" style={{ whiteSpace: "nowrap" }}>
            Encuentra al <span>{service.title}</span>
            <br />
            que necesitas para <br /> tu libro
          </Heading>
          <ButtonsContainer>
            <PrimaryBtn onClick={() => navigate("/signup")}>
              Encuentra talentos
            </PrimaryBtn>
            <OutlineBtn
              className="text-black"
              onClick={() => navigate("/signup")}
            >
              Ofrece tus servicios
            </OutlineBtn>
          </ButtonsContainer>
        </TextContainer>
        <PicContainer>
          <img src={HeaderImg} alt="header-img" />
        </PicContainer>
      </HeaderWrapper>
    </HeaderContainer>
  );
}

export default Header;
