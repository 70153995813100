import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexRowBetweenCenter,
  flexRowCenterCenter,
  flexRowStartStart,
} from "../../../common/scheme";
import { PrimaryBtn } from "../../Common.styles";

export const CheckoutDetailContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  marginTop: "3rem",
  [theme.breakpoints.down("sm")]: {
    marginTop: "1rem",
  },
}));
export const CheckoutDetailWrapper = styled(Box)(({ theme }) => ({
  ...flexRowStartStart,
  gap: "2rem",
  width: "1100px",
  [theme.breakpoints.down("lg")]: {
    ...flexRowCenterCenter,
    flexWrap: "wrap",
    width: "100%",
  },
}));
export const OrderDetail = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.colors.border}`,
  padding: "20px",
  width: "550px",
  borderRadius: "10px",
  "& h1": {
    textAlign: "center",
    fontSize: "20px",
    weight: "700",
    marginTop: "0",
  },
  "& h1:nth-of-type(2)": {
    textAlign: "left",
    fontSize: "24px",
    weight: "600",
    marginBottom: "2rem",
    marginTop: "1rem",
  },
  "& img": {
    width: "100%",
    height: "300px",
    borderRadius: ".5rem",
  },
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 40px)",
    margin: "1rem",
  },
}));

export const PackageDetail = styled(Box)(({ theme }) => ({
  ...flexRowBetweenCenter,
  gap: "1rem",
  "& h2": {
    fontSize: "16px",
    fontWeight: "700",
    textTransform: "uppercase",
    marginTop: ".3rem",
    color: theme.palette.text.heading,
  },
  "& h3": {
    fontSize: "16px",
    fontWeight: "400",
    marginTop: ".3rem",
    color: theme.palette.text.heading,
  },
  "& h4": {
    color: theme.palette.text.heading,
    fontSize: "14px",
    fontWeight: "600",
    marginTop: ".3rem",
  },
  "& p": {
    color: theme.palette.text.heading,
    fontSize: "14px",
    fontWeight: "400",
    marginTop: ".3rem",
    maxWidth: "400px",
  },
  "& img": {
    width: "18px",
    height: "18px",
  },
}));

export const PriceSummary = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.colors.border}`,
  padding: "20px",
  width: "500px",
  borderRadius: "10px",
  "& h1": {
    textAlign: "center",
    fontSize: "20px",
    weight: "700",
    marginTop: "0",
  },
  "& h5": {
    fontSize: "16px",
    fontWeight: "700",
    marginTop: ".3rem",
    color: theme.palette.text.heading,
  },
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 40px)",
  },
}));
export const CheckoutBtn = styled(PrimaryBtn)(({ theme }) => ({
  width: "100%",
}));
export const ImagesContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  gap: "1rem",
  marginTop: "1rem",
}));
export const PriceSummaryContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "1rem",
  },
}));
