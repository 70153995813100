import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnStartCenter,
  flexRowBetweenCenter,
  flexRowCenterCenter,
} from "../../../common/scheme";

export const OrderManagerContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  marginTop: "3rem",

  [theme.breakpoints.down("sm")]: {
    marginTop: "2rem",
  },
}));
export const OrderManagerWrapper = styled(Box)(({ theme }) => ({
  width: "1110px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    margin: "auto 1rem",
  },
}));

export const OrderHeader = styled(Box)(({ theme }) => ({
  ...flexRowBetweenCenter,
  marginBottom: "1rem",
  "& h1": {
    margin: "0",
    fontSize: "20px",
    fontWeight: "600",
    color: theme.palette.text.heading,
  },

  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
    ...flexRowCenterCenter,
    "& h1": {
      marginBottom: "1rem",
    },
  },
}));

export const OrderCardWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2rem",
  width: "100%",
  ...flexColumnStartCenter,
  gap: "1rem",
}));
