import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnStartCenter,
  flexRowBetweenCenter,
  flexRowCenterCenter,
  flexRowStartCenter,
} from "../../../common/scheme";
import { SecondaryHeading, Text } from "../../Common.styles";

export const FAQContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  marginTop: "5rem",
  backgroundImage:
    "radial-gradient(circle at 0% 60%, #C9E8EC, white, white, white, white)",
}));
export const FAQWrapper = styled(Box)(({ theme }) => ({
  width: "900px",
  [theme.breakpoints.down("lg")]: {
    width: "calc(100% - 2rem)",
    margin: "1rem 2rem",
  },
}));
export const FAQItems = styled(Box)(({ theme }) => ({
  ...flexColumnStartCenter,
}));
export const FAQItem = styled(Box)(({ theme }) => ({
  width: "calc(100% - 2rem)",
  borderRadius: "10px",
  borderTop: `1px solid ${theme.palette.colors.border}`,
  borderRight: `1px solid ${theme.palette.colors.border}`,
  borderLeft: `1px solid ${theme.palette.colors.border}`,
  padding: "5px 30px",
  cursor: "pointer",
  "&:nth-last-of-type(1)": {
    borderBottom: `1px solid ${theme.palette.colors.border}`,
  },
}));
export const TitleWrapper = styled(Box)(({ theme }) => ({
  ...flexRowBetweenCenter,
  marginBottom: "1rem",
}));
export const IconWrapper = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: "2rem",
  "& h1": {
    fontSize: "25px",
    margin: "0",
    fontWeight: "400",
    color: theme.palette.text.heading,
    [theme.breakpoints.down("lg")]: {
      fontSize: "20px",
    },
  },
}));
export const DetailWrapper = styled(Box)(({ theme }) => ({
  marginBottom: "1rem",
}));
export const FAQTitle = styled(SecondaryHeading)(({ theme }) => ({
  textAlign: "center",
}));
export const FAQText = styled(Text)(({ theme }) => ({
  textAlign: "center",
  marginTop: "1rem",
  marginBottom: "2rem",
}));
