import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexRowCenterCenter,
  flexRowBetweenCenter,
  flexColumnStartCenter,
  flexRowStartStart,
  flexColumnStartStart,
  flexColumnCenterCenter,
} from "../../../common/scheme";
import { SecondaryHeading, PrimaryBtn, Text } from "../../Common.styles";

export const OrderDetailContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  marginTop: "3rem",

  [theme.breakpoints.down("sm")]: {
    marginTop: "2rem",
  },
}));
export const OrderDetailWrapper = styled(Box)(({ theme }) => ({
  ...flexRowStartStart,
  width: "1110px",
  gap: "2rem",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    flexDirection: "column-reverse",
    margin: "auto 1rem",
  },
}));
export const OrderHeader = styled(Box)(({ theme }) => ({
  ...flexRowBetweenCenter,
  marginBottom: "1rem",
  "& h1": {
    margin: "0",
    fontSize: "16px",
    fontWeight: "600",
    color: theme.palette.text.heading,
  },

  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
    ...flexRowCenterCenter,
    "& h1": {
      marginBottom: "1rem",
    },
  },
}));

export const OrderChat = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.colors.border}`,
  padding: "40px",
  maxWidth: "780px",
  width: "100%",
  gap: "1rem",
  boxSizing: "border-box",

  [theme.breakpoints.down("lg")]: {
    padding: "20px",
    maxWidth: "100%",
  },
}));

export const OrderDetailBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.colors.border}`,
  padding: "15px",
  gap: "1rem",
  boxSizing: "border-box",
}));

export const OrderBoxContainter = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "480px",
  gap: "2rem",

  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

export const TimeContainer = styled(Box)(({ theme }) => ({
  ...flexRowBetweenCenter,
  margin: "25px 0",
}));

export const TimeItem = styled(Box)(({ theme }) => ({
  ...flexColumnStartCenter,
  flex: 1,

  "& h4": {
    margin: 0,
  },

  "& p": {
    margin: 0,
    color: `${theme.palette.colors.border}`,
    fontSize: "14px",
  },
}));

export const ChatBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "0px",
  width: "100%",
  boxSizing: "border-box",

  "& textarea": {
    fontFamily: "Poppins",
    fontSize: "16px",
    boxSizing: "border-box",
    outline: "none",
    width: "100%",
    height: "300px",
    resize: "none",
    border: `1px solid ${theme.palette.colors.border}`,
    padding: "20px",
  },

  "& button": {
    marginLeft: "auto",
    boxSizing: "border-box",
  },
}));

export const SendBtn = styled(PrimaryBtn)(({ theme }) => ({
  marginLeft: "auto",
  marginTop: "20px",
  padding: "8px 16px",
  "&:hover": {
    padding: "7px 15px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 12px",
    marginTop: "10px",
  },
}));

export const FilesBtn = styled(PrimaryBtn)(({ theme }) => ({
  marginLeft: "0px !important",
  marginRight: "auto",
  marginTop: "20px",
  padding: "8px 16px",
  "&:hover": {
    padding: "7px 15px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 12px",
    marginTop: "10px",
  },
}));

export const DeliveryBtn = styled(PrimaryBtn)(({ theme }) => ({
  width: "100%",
  padding: "8px 16px",
  "&:hover": {
    padding: "7px 15px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 12px",
    marginTop: "10px",
  },
}));

export const CloseModal = styled(Box)(({ theme }) => ({
  position: "relative",
  "& img": {
    top: "0px",
    right: "0px",
    position: "absolute",
    width: "30px",
    cursor: "pointer",
  },
}));

export const ModalContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
}));

export const ModalWrapper = styled(Box)(({ theme }) => ({
  gap: "2rem",
  margin: "30px 0",
  width: "100%",
}));

export const ModalContent = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

export const ModalTitle = styled(SecondaryHeading)(({ SecondaryHeading }) => ({
  fontSize: "20px",
  marginBottom: "2rem",
}));

export const Textarea = styled(Box)(({ theme }) => ({
  "& textarea": {
    fontFamily: "Poppins",
    fontSize: "16px",
    boxSizing: "border-box",
    outline: "none",
    width: "100%",
    height: "150px",
    resize: "none",
    border: `1px solid ${theme.palette.colors.border}`,
    padding: "20px",
  },
}));

export const FilesButtonContainer = styled(Box)(({ theme }) => ({
  ...flexRowStartStart,
  marginTop: "15px",
  gap: "2rem",
  boxSizing: "border-box",

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    gap: "1rem",
  },
}));

export const FilesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  overflow: "hidden",
}));

export const FilesWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  overflowX: "auto",
  gap: "1rem",
}));

export const ImageContainer = styled(Box)(({ theme }) => ({
  padding: "15px 0",

  "& button": {
    width: "100%",
    marginTop: "5px",
  },

  "& .image-file": {
    display: "flex",
    width: "130px",
    height: "130px",
  },
  "& .content": {
    position: "relative",
  },
}));

export const FileContainer = styled(Box)(({ theme }) => ({
  padding: "15px 0",

  "& button": {
    width: "100%",
    marginTop: "5px",
  },
  "& .content": {
    ...flexColumnCenterCenter,
    width: "130px",
    height: "130px",
    backgroundColor: theme.palette.colors.secondary,
    gap: ".8rem",
    position: "relative",

    "& span": {
      textAlign: "center",
    },
  },

  "& .file": {
    width: "40px",
    height: "40px",
  },
}));

export const CloseFile = styled(Box)(({ theme }) => ({
  top: "12px",
  right: "12px",
  position: "absolute",
  "& img": {
    width: "20px",
    cursor: "pointer",
  },
}));

export const DeliveryContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.colors.border}`,
  boxSizing: "border-box",
  padding: "20px",
  gap: "1rem",
  width: "100%",
  minHeight: "100px",
}));

export const DeliveryButtons = styled(Box)(({ theme }) => ({
  ...flexRowStartStart,
  padding: "15px 0",
  gap: "1rem",
  boxSizing: "border-box",
}));

export const ChatMessage = styled(Box)(({ theme }) => ({
  ...flexColumnStartStart,
  marginBottom: "20px",
  "& h4": {
    margin: 0,
  },
  "& p": {
    margin: 0,
  },
}));
