import { apiRoutes } from "../constant.routes";
import { useFetch } from "../utils/reactQuery";

export const useGetCategories = () => {
  const context = useFetch(
    apiRoutes.apiPrefix + apiRoutes.category.path + apiRoutes.category.list,
    undefined,
    { retry: false }
  );
  return { ...context, data: context.data?.categories };
};
