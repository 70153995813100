import React from "react";
import { CategoryCardContainer } from "../../styles/components/cards/CategoryCard.styles";
import { Link } from "react-router-dom";

function CategoryCard({ title, icon, detail, link }) {
  return (
    <Link to={link} style={{ textDecoration: "none" }}>
      <CategoryCardContainer>
        <img src={icon} alt="category" />
        <h1>{title}</h1>
        <p>{detail}</p>
      </CategoryCardContainer>
    </Link>
  );
}

export default CategoryCard;
