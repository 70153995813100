import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import {
  CheckoutDetailContainer,
  CheckoutDetailWrapper,
  OrderDetail,
  PriceSummary,
  PackageDetail,
  CheckoutBtn,
  ImagesContainer,
  PriceSummaryContainer,
} from "../../styles/common/checkout/CheckoutDetail.styles";
import Pic3 from "../../assets/slider/pic3.png";
import TickIcon from "../../assets/icons/tick.svg";
import { Divider } from "../../styles/Common.styles";
import VisaImg from "../../assets/images/visa.svg";
import MasterImg from "../../assets/images/master-card.svg";
import PaypalImg from "../../assets/images/paypal.svg";
import AEImg from "../../assets/images/american-express.svg";
import { createOrder } from "../../api/payment";
import { useGetCheckout } from "../../api/checkout";
import { useUser } from "../../api/auth";
import { app } from "../../utils/config";

function CheckoutDetail() {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const params = useParams();
  const { data } = useGetCheckout(params.id);
  const { user } = useUser();

  const handleSubmit = async () => {
    if (user) {
      const obj = {
        referenceId: data._id,
      };
      setLoading(false);
      try {
        const result = await createOrder(obj);

        if (result.data) {
          const link = result.data.links.find((e) => e.rel === "approve");
          window.location = link.href;
        }
      } catch (err) {
        setLoading(true);
      }
    } else {
      navigate("/login");
    }
  };

  const getFee = (total, fee) => {
    return ParseFloat(total * (fee / 100), 2);
  };

  const getTotal = (total, fee) => {
    return ParseFloat(total + getFee(total, fee), 2);
  };

  const ParseFloat = (str, val) => {
    str = str.toString();
    if (str.indexOf(".") > 0) {
      str = str.slice(0, str.indexOf(".") + val + 1);
    }
    return Number(str);
  };

  const constant = {
    basic: "Paquete basico",
    standard: "Paquete standard",
    premium: "Paquete premium",
  };

  if (!data) {
    return;
  }

  const getImage = (image) => {
    if (image.includes("http") || image.includes("https")) {
      return image;
    }
    return `${app.serverURL}/${image}`;
  };

  return (
    <CheckoutDetailContainer>
      <CheckoutDetailWrapper>
        <OrderDetail>
          <h1>Detalle del pedido</h1>
          <img src={getImage(data.service.images[0])} alt="user-profile-pic" />
          <h1>{data.service.title}</h1>
          <PackageDetail>
            <h2>{constant[data.name]}</h2>
            <h3>US $ {data.price}</h3>
          </PackageDetail>
          <PackageDetail>
            <p>Fecha y Hora</p>
            <h4>{moment(Date.now()).format("DD MMM YYYY")}</h4>
          </PackageDetail>
          {data.features.map(
            (feat) =>
              feat.value && (
                <PackageDetail>
                  <p>{feat.name}</p>
                  <img src={TickIcon} alt="tick-icon" />
                </PackageDetail>
              )
          )}
          {/* <PackageDetail>
            <p>Revisiones</p>
            <h4>3</h4>
          </PackageDetail> */}
          <PackageDetail>
            <p>Tiempo entrega</p>
            <h4>{data.delivery_time} Dias</h4>
          </PackageDetail>
        </OrderDetail>

        <PriceSummaryContainer>
          <PriceSummary>
            <h1>Price Summary </h1>
            <PackageDetail>
              <p>Subtotal</p>
              <h5>${data.price}</h5>
            </PackageDetail>
            <PackageDetail>
              <p>Impuesto de servicio</p>
              <h5>${getFee(data.price, 10)}</h5>
            </PackageDetail>
            <Divider sx={{ mb: "1rem" }} />
            <PackageDetail>
              <p>Total</p>
              <h5>${getTotal(data.price, 10)}</h5>
            </PackageDetail>
            {loading ? (
              <CheckoutBtn onClick={handleSubmit}>
                Continuar Checkout
              </CheckoutBtn>
            ) : (
              <CheckoutBtn>Cargando</CheckoutBtn>
            )}
            <PackageDetail sx={{ justifyContent: "center", mt: "1rem" }}>
              <p>You won't be charged yet</p>
            </PackageDetail>
          </PriceSummary>

          <ImagesContainer>
            <img src={VisaImg} alt="visa" />
            <img src={MasterImg} alt="master" />
            <img src={PaypalImg} alt="paypal" />
            <img src={AEImg} alt="express" />
          </ImagesContainer>
        </PriceSummaryContainer>
      </CheckoutDetailWrapper>
    </CheckoutDetailContainer>
  );
}

export default CheckoutDetail;
