import * as React from "react";
import Typography from "@mui/material/Typography";
import { Popover, Box } from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

import ArrowDownIcon from "../../assets/icons/arrow-down.svg";
import { flexRowStartCenter } from "../../common/scheme";
import { NavLink } from "react-router-dom";
import { useTheme } from "@emotion/react";

function PopoverMenu({ title, menuItems, link }) {
  const theme = useTheme();

  const activeStyle = {
    color: theme.palette.colors.primary,
    textDecoration: "none",
  };

  const navStyle = {
    color: theme.palette.text.heading,
    textDecoration: "none",
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <Box
            sx={{
              ...flexRowStartCenter,
              gap: ".3rem",
              cursor: "pointer",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            <NavLink
              to={`/${link}`}
              style={({ isActive }) => (isActive ? activeStyle : navStyle)}
            >
              <p>{title}</p>
            </NavLink>
            <img src={ArrowDownIcon} alt="icon" {...bindTrigger(popupState)} />
          </Box>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{
              ".MuiPopover-paper": { padding: "1rem .5rem" },
            }}
          >
            {menuItems.map(({ id, item, link }) => (
              <NavLink
                to={link}
                style={({ isActive }) => (isActive ? activeStyle : navStyle)}
                key={id}
              >
                <Typography
                  key={id}
                  sx={{
                    p: ".3rem 1rem",
                    cursor: "pointer",
                  }}
                >
                  {item}
                </Typography>
              </NavLink>
            ))}
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

export default PopoverMenu;
