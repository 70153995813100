import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnStartCenter,
  flexColumnStartStart,
  flexRowCenterCenter,
  flexRowStartCenter,
} from "../../../common/scheme";

export const TestimonialContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  padding: "8rem 1rem",
}));
export const TestimonialWrapper = styled(Box)(({ theme }) => ({}));
export const CardContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  gap: "2rem",
  flexWrap: "wrap",
}));

export const QuestionContainer = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: "2rem",
  width: "900px",
  marginTop: "2rem",
  "& img": {
    width: "135px",
    height: "115px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    margin: "auto 2rem",
  },
  [theme.breakpoints.down("sm")]: {
    ...flexColumnStartCenter,
    margin: "auto 1rem",
  },
}));
export const DetailContainer = styled(Box)(({ theme }) => ({
  ...flexColumnStartStart,
  gap: "2rem",
}));
export const QuestionWrapper = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  marginTop: "2rem",
}));
export const TestimonialPartnerContainer = styled(Box)(({ theme }) => ({
  backgroundImage:
    "radial-gradient(circle at 0% 0%, rgba(180, 95, 232, 0.15), white, white,white, white, white, #edfefb)",
}));
