import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnCenterCenter,
  flexColumnStartStart,
  flexRowCenterCenter,
  flexRowEndCenter,
  flexRowStartCenter,
} from "../../../common/scheme";
import MapIcon from "../../../assets/icons/map.svg";
import { Heading } from "../../Common.styles";

export const UsersContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  backgroundColor: theme.palette.colors.secondary,
}));
export const UsersWrapper = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  width: "1100px",
  gap: "4rem",
  [theme.breakpoints.down("lg")]: {
    ...flexColumnCenterCenter,
    flexDirection: "column-reverse",
    paddingBottom: "5rem",
    width: "100%",
  },
}));

export const UsersSigned = styled(Box)(({ theme }) => ({
  width: "40%",
  ...flexRowEndCenter,
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    ...flexRowCenterCenter,
    margin: "auto",
  },
}));
export const MapContainer = styled(Box)(({ theme }) => ({
  width: "60%",
  position: "relative",
  "& img": {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  "& .users-text": {
    zIndex: "999",
    position: "absolute",
    right: "50px",
    top: "80px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    "& .users-text": {
      zIndex: "999",
      position: "absolute",
      left: "10%",
      top: "150px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    "& .users-text": {
      fontSize: "1.5rem",
      top: "50px",
    },
  },
}));
export const MapWrapper = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${MapIcon})`,
  backgroundRepeat: "no-repeat",
  height: "400px",
  ...flexColumnCenterCenter,
  marginRight: "5rem",
  [theme.breakpoints.down("lg")]: {
    width: "calc(100% - 2rem)",
    padding: "1rem",
    marginRight: "auto",
    height: "200px",
    "& .users-text": {
      fontSize: "2rem",
      textAlign: "center",
    },
  },
}));
export const CardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.text.white,
  ...flexColumnStartStart,
  padding: "1rem",
  width: "400px",
  borderRadius: ".5rem",
}));
export const ToatalUsers = styled(Heading)(({ theme }) => ({
  fontSize: "70px",
}));
