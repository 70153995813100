import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnCenterCenter,
  flexColumnStartCenter,
  flexColumnStartStart,
  flexRowBetweenCenter,
  flexRowStartCenter,
  flexRowStartStart,
} from "../../../common/scheme";
import { Divider } from "../../Common.styles";

export const ProfileCardContainer = styled(Box)(({ theme }) => ({
  padding: "20px",
  border: `1px solid ${theme.palette.colors.border}`,
  width: "calc(100% - 40px)",
  borderRadius: ".5rem",
}));
export const ProfileHeading = styled(Box)(({ theme }) => ({
  ...flexRowBetweenCenter,
  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
  },
}));
export const ProfileStatus = styled(Box)(({ theme }) => ({
  ...flexRowStartStart,
  "& img": {
    width: "80px",
    height: "80px",
    borderRadius: ".5rem",
  },
}));
export const ContactContainer = styled(Box)(({ theme }) => ({
  ...flexColumnStartCenter,
  alignItems: "flex-end",
  gap: "1rem",
  "& img": {
    width: "20ox",
    height: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    ...flexRowBetweenCenter,
    marginTop: "1rem",
    width: "100%",
  },
}));

export const ProfileDetail = styled(Box)(({ theme }) => ({
  ...flexColumnStartStart,
  marginLeft: "1rem",
  "& h1": {
    margin: "0",
    fontSize: "16px",
    fontWeight: "700",
  },
}));
export const LevelContainer = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: "3rem",
  "& p": {
    fontSize: "14px",
    fontWeight: "400",
  },
  [theme.breakpoints.down("sm")]: {
    gap: "1rem",
    flexWrap: "wrap",
  },
}));
export const LevelBox = styled(Box)(({ theme }) => ({
  background: theme.palette.profile.levelBg,
  color: theme.palette.profile.levelText,
  width: "60px",
  height: "20px",
  padding: "5px 10px",
  fontSize: "12px",
  fontWeight: "700",
  borderRadius: "4px",
  ...flexColumnCenterCenter,
}));
export const RatingContainer = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: "1rem",
  "& p": {
    margin: "0",
  },
  "& p:nth-of-type(1)": {
    color: theme.palette.servicio.rating,
    fontSize: "12px",
    fontWeight: "700",
  },
  "& p:nth-of-type(2)": {
    fontSize: "12px",
    fontWeight: "400",
  },
  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
    gap: ".5rem",
  },
}));
export const StarsWrapper = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: ".1rem",
  "& img": {
    width: "16px",
    height: "16px",
  },
}));
export const ProfileCardDivider = styled(Divider)(({ theme }) => ({
  marginTop: "1rem",
  marginBottom: "1rem",
}));
export const DescriptionContainer = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: "14rem",

  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
    gap: "3rem",
  },
}));
export const InfoWrapper = styled(Box)(({ theme }) => ({
  ...flexColumnStartStart,
  "& p": {
    fontSize: "14px",
    fontWeight: "400",
    margin: "0",
    color: theme.palette.profile.text,
  },
  "& h1": {
    fontSize: "14px",
    fontWeight: "600",
    margin: "0",
    color: theme.palette.text.heading,
  },
}));
export const AboutContainer = styled(Box)(({ theme }) => ({
  marginTop: "1rem",
  "& p": {
    fontSize: "12px",
    fontWeight: "400",
    margin: "0",
    color: theme.palette.profile.text,
  },
}));
