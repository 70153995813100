import React from "react";
import { Heading, TextSmall } from "../../styles/Common.styles";
import {
  UsersContainer,
  UsersSigned,
  MapContainer,
  MapWrapper,
  CardContainer,
  ToatalUsers,
  UsersWrapper,
} from "../../styles/common/about_us/Users.styles";
import UserIcon from "../../assets/icons/users.svg";
import MapIcon from "../../assets/icons/map.svg";

function Users() {
  return (
    <UsersContainer>
      <UsersWrapper>
        {/* <UsersSigned>
          <CardContainer>
            <img src={UserIcon} alt="users" />
            <ToatalUsers>6,392</ToatalUsers>
            <TextSmall>Users signed in this month</TextSmall>
          </CardContainer>
        </UsersSigned> */}
        <MapContainer>
          <img src={MapIcon} alt="map-icon" />
          <Heading className="users-text">
            Crea tu cuenta y únete a nosotros
          </Heading>
        </MapContainer>
      </UsersWrapper>
    </UsersContainer>
  );
}

export default Users;
