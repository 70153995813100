import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnStartStart,
  flexRowBetweenCenter,
  flexRowStartCenter,
} from "../../../common/scheme";
import { PrimaryBtn } from "../../Common.styles";

export const ServicioCardContainer = styled(Box)(({ theme }) => ({
  width: "216px",
  height: "290px",
  border: `1px solid ${theme.palette.servicio.border}`,
  borderRadius: ".5rem",
  padding: ".5rem",
  marginTop: "1rem",
  "& img": {
    width: "100%",
    height: "150px",
  },
  "&:hover": {
    border: `1px solid ${theme.palette.colors.primary}`,
    cursor: "pointer",
  },
}));

export const UserHeadingContainer = styled(Box)(({ theme }) => ({
  ...flexRowBetweenCenter,
}));

export const UserBodyContainer = styled(Box)(({ theme }) => ({
  "& p": {
    margin: "5px 0 5px 0",
    fontSize: "12px",
    fontWeight: "600",
    color: theme.palette.text.heading,
  },
}));

export const UserInfo = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: ".5rem",
  "& img": {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
  },
}));
export const UserRating = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: ".3rem",
  "& img": {
    width: "14px",
    height: "14px",
  },
  "& p:nth-of-type(1)": {
    fontSize: "10px",
    fontWeight: "700",
    color: theme.palette.servicio.rating,
    color: theme.palette.text.heading,
  },
  "& p": {
    fontSize: "10px",
    fontWeight: "400",
    color: theme.palette.text.heading,
  },
}));
export const UserDetail = styled(Box)(({ theme }) => ({
  ...flexColumnStartStart,
  "& p:nth-of-type(1)": {
    fontSize: "10px",
    fontWeight: "400",
    margin: "0",
    color: theme.palette.text.heading,
  },
  "& p:nth-of-type(2)": {
    fontSize: "10px",
    fontWeight: "400",
    margin: "0",
    color: theme.palette.text.heading,
  },
}));

export const UserActionContainer = styled(Box)(({ theme }) => ({
  ...flexRowBetweenCenter,
  gap: "1rem",
  "& p": {
    fontSize: "18px",
    fontWeight: "400",
    color: theme.palette.text.heading,
  },
}));
export const ActionBtn = styled(PrimaryBtn)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "700px",
  padding: ".4rem 1rem",
}));
