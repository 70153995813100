import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexRowCenterCenter,
  flexRowBetweenCenter,
  flexColumnStartCenter,
  flexRowStartStart,
} from "../../../common/scheme";
import { SecondaryHeading, PrimaryBtn, Text } from "../../Common.styles";

export const BalanceDetailContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  marginTop: "3rem",

  [theme.breakpoints.down("sm")]: {
    marginTop: "2rem",
  },
}));
export const BalanceDetailWrapper = styled(Box)(({ theme }) => ({
  width: "1110px",
  minHeight: "500px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    margin: "auto 1rem",
  },
}));

export const BalanceHeader = styled(Box)(({ theme }) => ({
  ...flexRowBetweenCenter,
  marginBottom: "1rem",
  width: "100%",
  "& h1": {
    margin: "0",
    fontSize: "20px",
    fontWeight: "600",
    color: theme.palette.text.heading,
  },

  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
    ...flexRowCenterCenter,
    "& h1": {
      marginBottom: "1rem",
    },
  },
}));

export const CloseModal = styled(Box)(({ theme }) => ({
  position: "relative",
  "& img": {
    top: "0px",
    right: "0px",
    position: "absolute",
    width: "30px",
    cursor: "pointer",
  },
}));

export const ModalContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
}));

export const ModalWrapper = styled(Box)(({ theme }) => ({
  gap: "2rem",
  margin: "30px 0",
  width: "100%",
}));

export const ModalContent = styled(Box)(({ theme }) => ({
  width: "calc(100% - 40px)",
}));

export const ModalTitle = styled(SecondaryHeading)(({ SecondaryHeading }) => ({
  fontSize: "20px",
  marginBottom: "2rem",
}));
