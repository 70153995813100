import React from "react";
import { FlexRowStartCenter } from "../../styles/Common.styles";
import {
  ReviewCardContainer,
  ReviewDetailContainer,
  ReviewHeader,
  RatingWrapper,
} from "../../styles/components/cards/ReviewCard.styles";
import HelpingIcon from "../../assets/icons/helping.svg";
import NotHelpingIcon from "../../assets/icons/not-helping.svg";

function ReviewCard({
  id,
  userProfilePic,
  userReviewPic,
  userName,
  buyerReview,
  orders,
  averageRating,
  userLocation,
  starIcon,
}) {
  return (
    <ReviewCardContainer>
      <img
        src={userProfilePic}
        alt="user-profile-pic"
        className="profile-pic"
      />
      <ReviewDetailContainer>
        <ReviewHeader>
          <h1>{userName}</h1>
          <RatingWrapper>
            <img src={starIcon} alt="stars" />
            <img src={starIcon} alt="stars" />
            <img src={starIcon} alt="stars" />
            <img src={starIcon} alt="stars" />
            <img src={starIcon} alt="stars" />
          </RatingWrapper>
          <p>{averageRating}</p>
        </ReviewHeader>

        <p className="review-text">{buyerReview}</p>
        <FlexRowStartCenter gap="1rem">
          <FlexRowStartCenter gap=".5rem">
            <p className="helping-text">helping</p>
            <img src={HelpingIcon} alt="helping-icon" />
          </FlexRowStartCenter>
          <FlexRowStartCenter gap=".5rem">
            <p className="helping-text">no helping</p>
            <img src={NotHelpingIcon} alt="not-helping-icon" />
          </FlexRowStartCenter>
        </FlexRowStartCenter>
      </ReviewDetailContainer>

      {userReviewPic && (
        <img src={userReviewPic} alt="user-review-pic" className="review-pic" />
      )}
    </ReviewCardContainer>
  );
}

export default ReviewCard;
