import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  BalanceDetailContainer,
  BalanceDetailWrapper,
  BalanceHeader,
  CloseModal,
  ModalContainer,
  ModalWrapper,
  ModalContent,
  ModalTitle,
} from "../../styles/common/earnings/BalanceDetail.styles";
import {
  FormContainer,
  InputContainer,
  InputWrapper,
} from "../../styles/common/servicios/Create.styles";
import { Divider, PrimaryBtn } from "../../styles/Common.styles";
import { withdrawPaypal } from "../../api/payment";
import { useUser } from "../../api/auth";
import CloseIcon from "../../assets/icons/close.svg";

const BalanceDetail = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const { refetch, user } = useUser();

  useEffect(() => {
    if (user) {
      setEmail(user?.paypal_email);
    }
  }, [user]);

  const onWithdrawPaypal = async () => {
    if (email) {
      setLoading(true);
      try {
        const result = await withdrawPaypal({ email });
        if (result.data) {
          handleClose();
          setLoading(false);
          refetch();
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 4,
    p: 4,
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <BalanceDetailContainer>
      <BalanceDetailWrapper>
        <BalanceHeader>
          <h1>Ganancias</h1>
        </BalanceHeader>
        <Divider />
        <br />
        <p>Retirar fondos</p>

        <PrimaryBtn
          onClick={handleOpen}
          disabled={user.wallet ? user?.wallet?.ballance <= 0 : true}
        >
          Paypal
        </PrimaryBtn>

        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <CloseModal>
              <img src={CloseIcon} alt="close-icon" onClick={handleClose} />
            </CloseModal>
            <ModalContainer>
              <ModalWrapper>
                <ModalContent>
                  <ModalTitle>Retirar fondos</ModalTitle>
                </ModalContent>

                <FormContainer>
                  <label>Email de paypal</label>
                  <InputWrapper>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email de paypal"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </InputWrapper>
                  <br />
                  <PrimaryBtn onClick={onWithdrawPaypal} disabled={loading}>
                    Retirar
                  </PrimaryBtn>
                </FormContainer>
              </ModalWrapper>
            </ModalContainer>
          </Box>
        </Modal>
      </BalanceDetailWrapper>
    </BalanceDetailContainer>
  );
};

export default BalanceDetail;
