import React, { Fragment } from "react";
import UserProfile from "../common/profile/UserProfile";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";

function Servicio() {
  return (
    <Fragment>
      <Navbar />
      <UserProfile />
      <Footer margintop="7rem" />
    </Fragment>
  );
}

export default Servicio;
