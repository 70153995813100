import React, { Fragment } from "react";
import Signin from "../auth/SIignin";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";

function Login() {
  return (
    <Fragment>
      <Navbar />
      <Signin />
      <Footer margintop="0" />
    </Fragment>
  );
}

export default Login;
