import React from "react";
import { useNavigate } from "react-router";
import {
  OrderManagerContainer,
  OrderManagerWrapper,
  OrderHeader,
  OrderCardWrapper,
} from "../../styles/common/orders/OrderManager.styles";
import Filter from "../../components/customs/Filter";
import { filterData } from "../../data/data";
import { Divider, PrimaryBtn } from "../../styles/Common.styles";
import ServicesManagerCard from "../../components/cards/ServicesManagerCard";

import moment from "moment";
import { useUser } from "../../api/auth";
import { useGetServicesManager } from "../../api/service";
import { app } from "../../utils/config";

function ServicesManager() {
  const { user } = useUser();
  const navigate = useNavigate();
  const { data } = useGetServicesManager();

  const getImage = (image) => {
    if (image.includes("http") || image.includes("https")) {
      return image;
    }
    return `${app.serverURL}/${image}`;
  };

  return (
    <OrderManagerContainer>
      <OrderManagerWrapper>
        <OrderHeader>
          <h1>Tu administrador de servicios</h1>
          <PrimaryBtn onClick={() => navigate("/services/create")}>
            Agregar Servicio
          </PrimaryBtn>
        </OrderHeader>
        <Divider />

        <OrderCardWrapper>
          {data &&
            data.map(
              ({
                _id,
                userName,
                title,
                profilePic,
                price,
                status,
                dueIn,
                userLocation,
                link,
                images,
              }) => (
                <ServicesManagerCard
                  key={_id}
                  id={_id}
                  profilePic={getImage(images[0])}
                  // userPic={userPic}
                  userName={title}
                  // price={price}
                  // status={status}
                  // dueIn={dueIn}
                  // userLocation={userLocation}
                  link={"/services/edit/" + _id}
                />
              )
            )}
        </OrderCardWrapper>
      </OrderManagerWrapper>
    </OrderManagerContainer>
  );
}

export default ServicesManager;
