import { Box, Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnCenterCenter,
  flexColumnStartStart,
  flexRowBetweenCenter,
  flexRowCenterCenter,
  flexRowStartCenter,
} from "../../common/scheme";
import { PrimaryBtn, TextSmall } from "../Common.styles";

export const AuthContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  width: "100%",
  backgroundColor: theme.palette.colors.secondary,
}));
export const AuthWrapper = styled(Box)(({ theme }) => ({
  width: "450px",
  borderRadius: "20px",
  backgroundColor: theme.palette.text.white,
  padding: "1rem",
  marginTop: "3rem",
  marginBottom: "3rem",
  "& h1": {
    textAlign: "center",
    color: theme.palette.text.primary,
    fontSize: "20px",
    fontWeight: "700",
  },
  "& .sigin-with": {
    textAlign: "center",
    color: theme.palette.text.heading,
    "& span": {
      fontWeight: "700",
      color: theme.palette.colors.primary,
    },
  },

  [theme.breakpoints.down("sm")]: {
    padding: "1rem .5rem",
  },
}));
export const FormContainer = styled(Box)(({ theme }) => ({
  margin: "2rem 1rem 1rem 1rem",
  padding: "1rem 1rem 2rem 1rem",
  width: "calc(100% - 4rem)",
  backgroundColor: theme.palette.colors.secondary,
  borderRadius: "10px",

  [theme.breakpoints.down("sm")]: {
    margin: "2rem .5rem 1rem 1rem",
  },
}));
export const InputContainer = styled(Box)(({ theme }) => ({
  ...flexColumnStartStart,
  gap: ".5rem",
  marginTop: "1rem",
  "& label": {
    fontSize: "14px",
    fontWeight: "400",
  },
}));
export const InputWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.text.white,
  width: "calc(100% - 2rem)",
  borderRadius: "8px",
  ...flexRowBetweenCenter,
  "& img": {
    cursor: "pointer",
  },
  padding: "1rem",
  "& input": {
    width: "100%",
    border: "none",
    color: theme.palette.text.primary,
    fontSize: "16px",
    fontWeight: "400",
    "&:focus": {
      border: "none",
      outline: "0",
    },
    "&::placeholder": {
      color: theme.palette.colors.border,
      fontSize: "14px",
      fontWeight: "400",
    },
  },
}));
export const InfoContainer = styled(Box)(({ theme }) => ({
  ...flexRowBetweenCenter,
  marginTop: ".5rem",
  "& p": {
    fontSize: "12px",
    fontWeight: "400",
    color: theme.palette.text.heading,
  },
  "& h3": {
    fontSize: "12px",
    fontWeight: "700",
    color: theme.palette.colors.primary,
  },
  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
  },
}));
export const CheckBoxWrapper = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  marginRight: "1rem",
}));
export const ContinueBtn = styled(PrimaryBtn)(({ theme }) => ({
  width: "100%",
  marginTop: "1rem",
}));
export const IconsContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  gap: "1rem",
}));
export const IconWrapper = styled(Box)(({ theme }) => ({
  ...flexColumnCenterCenter,
  width: "41px",
  height: "41px",
  backgroundColor: theme.palette.text.white,
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07)",
  borderRadius: "10px",
  padding: "8px",
  cursor: "pointer",
  "& img": {
    width: "25px",
    height: "25px",
  },
}));
export const RestoreText = styled(TextSmall)(({ theme }) => ({
  color: theme.palette.text.heading,
  marginTop: "1.5rem",
  marginBottom: "1rem",
}));
export const SignupText = styled(TextSmall)(({ theme }) => ({
  color: theme.palette.text.heading,
  fontSize: "12px",
  fontWeight: "400",
  textAlign: "center",
  marginTop: "1.2rem",
}));
export const JoinText = styled(TextSmall)(({ theme }) => ({
  color: theme.palette.text.heading,
  fontSize: "12px",
  fontWeight: "400",
  textAlign: "justify",
  margin: "1.2rem 1.5rem 2rem 1.5rem",
}));
export const BackWrapper = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: "6.5rem",
  "& img": {
    width: "25px",
    transform: "rotate(180deg)",
    marginLeft: "1rem",
    cursor: "pointer",
  },
}));
export const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
  color: "white",

  "& svg": {
    backgroundColor: "white",
    width: "20px",
    height: "20px",
    borderRadius: "5px",
  },
}));
