import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnStartCenter,
  flexRowBetweenCenter,
  flexRowCenterCenter,
  flexRowStartCenter,
  flexRowStartStart,
} from "../../../common/scheme";

export const UserProfileContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  marginTop: "2rem",
  width: "100%",
}));
export const UserProfileWrapper = styled(Box)(({ theme }) => ({
  width: "1125px",
  ...flexRowStartStart,
  gap: "2rem",
  [theme.breakpoints.down("lg")]: {
    flexWrap: "wrap",
    ...flexRowCenterCenter,
    alignItems: "flex-start",
    width: "calc(100% - 2rem)",
    margin: "0 1rem",
  },
}));
export const SliderContainer = styled(Box)(({ theme }) => ({
  width: "600px",
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 1rem)",
  },
  "& .price-heading": {
    marginTop: "7rem",
    fontSize: "20px",
    fontWeight: "700",
    color: theme.palette.text.heading,
  },
}));
export const ProfileCardWrapper = styled(Box)(({ theme }) => ({
  width: "550px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
export const DescriptionContainer = styled(Box)(({ theme }) => ({
  marginTop: "2rem",
  "& h1": {
    fontSize: "20px",
    fontWeight: "700",
    color: theme.palette.text.heading,
  },
  "& h2": {
    fontSize: "14px",
    fontWeight: "700",
    color: theme.palette.text.heading,
  },
  "& p": {
    fontSize: "12px",
    fontWeight: "400",
    color: theme.palette.profile.text,
  },
  "& ul": {
    listStyleType: "none",
    padding: "0",
    margin: "0",
    fontSize: "12px",
    fontWeight: "400",
    color: theme.palette.profile.text,
  },
}));
export const ReviewHeader = styled(Box)(({ theme }) => ({
  ...flexRowBetweenCenter,
  "& h1": {
    fontSize: "20px",
    fontWeight: "700",
    color: theme.palette.text.heading,
  },
  marginTop: "1rem",
}));
export const OrderContainer = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: ".5rem",
  "& img": {
    width: "16px",
    height: "16px",
  },
  "& p": {
    margin: "0",
  },
  "& p:nth-of-type(1)": {
    color: theme.palette.servicio.rating,
    fontSize: "12px",
    fontWeight: "700",
  },
  "& p:nth-of-type(2)": {
    fontSize: "12px",
    fontWeight: "400",
  },
}));
export const ReviewCardWrapper = styled(Box)(({ theme }) => ({
  ...flexColumnStartCenter,
  gap: "1rem",
}));
