import React, { useState } from "react";
import { AuthWrapper } from "../styles/auth/Auth.styles";
import {
  AuthContainer,
  FormContainer,
  InputWrapper,
  InputContainer,
  InfoContainer,
  CheckBoxWrapper,
  ContinueBtn,
  IconsContainer,
  IconWrapper,
  CustomCheckBox,
} from "../styles/auth/Auth.styles";
import ViewIcon from "../assets/icons/view.svg";
import { Link } from "react-router-dom";
import FacebookIcon from "../assets/social/facebook.svg";
import AppleIcon from "../assets/social/apple.svg";
import TwitterIcon from "../assets/social/twitter.svg";
import GoogleIcon from "../assets/social/google.png";
import { useNavigate } from "react-router-dom";
import {
  getTokenByPassword,
  useGetProfile,
  useUser,
  loginGoogle,
} from "../api/auth";
import { toast } from "react-toastify";
import { app } from "../utils/config";

function Signin() {
  const [passwordType, setPasswordType] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const togglePasswordType = () => {
    setPasswordType(!passwordType);
  };

  const { updateUser, refetch } = useUser();

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      const resp = await getTokenByPassword(email, password);
      if (resp.data.user) {
        localStorage.setItem("token", resp.data.user.access_token);
        localStorage.setItem("refresh_token", resp.data.user.refresh_token);
        refetch();
        navigate("/");
      } else {
      }
    } catch (err) {
      const status = err.response.status;
      if (status === 401) {
        toast.error("Email o contraseña invalido.");
      }
      if (status === 400) {
        toast.error("Faltan agregar algunos campos.");
      }
    }
  };

  return (
    <AuthContainer>
      <AuthWrapper>
        <h1>Inicia sesión en BeeBuk</h1>
        <form onSubmit={onSubmit}>
          <FormContainer>
            <InputContainer>
              <label>Correo Electrónico </label>
              <InputWrapper>
                <input
                  type="text"
                  placeholder="Tu correo electrónico/nombre de usuario"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </InputWrapper>
            </InputContainer>
            <InputContainer sx={{ mt: "1.5rem" }}>
              <label>Contraseña </label>
              <InputWrapper>
                <input
                  type={passwordType ? "text" : "password"}
                  placeholder="Tu contraseña"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <img
                  src={ViewIcon}
                  alt="view-icon"
                  onClick={togglePasswordType}
                />
              </InputWrapper>
            </InputContainer>
            <InfoContainer>
              <CheckBoxWrapper>
                <CustomCheckBox />
                <p>Recuérdame</p>
              </CheckBoxWrapper>
              <Link to="/restore-password" style={{ textDecoration: "none" }}>
                <h3>Has olvidado tu contraseña ?</h3>
              </Link>
            </InfoContainer>
            <ContinueBtn type="submit">Continuar</ContinueBtn>
          </FormContainer>
        </form>
        <p className="sigin-with">O inicia sesión con</p>
        <IconsContainer>
          <a href={`${app.serverURL}/${app.apiURL}/auth/facebook`}>
            <IconWrapper>
              <img src={FacebookIcon} alt="facebook-icon" />
            </IconWrapper>
          </a>
          <a href={`${app.serverURL}/${app.apiURL}/auth/google`}>
            <IconWrapper>
              <img src={GoogleIcon} alt="google-icon" />
            </IconWrapper>
          </a>

          {/* <IconWrapper>
            <img src={AppleIcon} alt="apple-icon" />
          </IconWrapper> */}

          <a href={`${app.serverURL}/${app.apiURL}/auth/twitter`}>
            <IconWrapper>
              <img src={TwitterIcon} alt="twitter-icon" />
            </IconWrapper>
          </a>
        </IconsContainer>
        <Link to="/signup" style={{ textDecoration: "none" }}>
          <p className="sigin-with">
            ¿No tienes una cuenta? <span>Únete ahora</span>
          </p>
        </Link>
      </AuthWrapper>
    </AuthContainer>
  );
}

export default Signin;
