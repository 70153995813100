import React, { Fragment } from "react";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import Change from "../auth/Change";

function ChangePassword() {
  return (
    <Fragment>
      <Navbar />
      <Change />
      <Footer margintop="0" />
    </Fragment>
  );
}

export default ChangePassword;
