import React, { Fragment } from "react";
import Success from "../common/payment/Success";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";

function PaymenSuccess() {
  return (
    <Fragment>
      <Navbar />
      <Success />
      <Footer />
    </Fragment>
  );
}

export default PaymenSuccess;
