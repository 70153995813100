import { Box, Drawer, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexRowAroundCenter,
  flexRowBetweenCenter,
  flexRowEvenlyCenter,
  flexRowStartCenter,
} from "../../common/scheme";

export const NavbarContainer = styled(Box)(({ theme }) => ({
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
  ...flexRowEvenlyCenter,
  [theme.breakpoints.down("lg")]: {
    ...flexRowAroundCenter,
  },
}));
export const MenuContainer = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: "2rem",
  marginLeft: "1rem",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));
export const MenuListItem = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "400",
  [theme.breakpoints.down("lg")]: {
    margin: ".5rem 0 1rem 0",
  },
}));
export const AuthContainer = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: "1rem",
  marginLeft: "1rem",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));
export const AuthItem = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "700",
  color: theme.palette.colors.primary,
  marginLeft: "1rem",
  marginRight: "1rem",
  cursor: "pointer",
  [theme.breakpoints.down("lg")]: {
    margin: "1.5rem auto 1rem 0",
    border: `1px solid ${theme.palette.colors.primary}`,
    borderRadius: ".5rem",
    textAlign: "center",
    padding: ".5rem",
    maxWidth: "122px",
  },
}));
export const IconWrapper = styled(Box)(({ theme }) => ({
  display: "none",
  marginLeft: "1rem",
  [theme.breakpoints.down("lg")]: {
    display: "flex",
  },
}));
export const SearchContainer = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: "2rem",
}));
export const SearchWrapper = styled(Box)(({ theme }) => ({
  width: "300px",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));
export const MobileSearch = styled(Box)(({ theme }) => ({
  display: "none",
  marginTop: "1rem",
  [theme.breakpoints.down("sm")]: {
    display: "block",
    width: "80vw",
  },
}));
export const MobileDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "350px",
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDrawer-paper": {
      width: "100%",
    },
  },
}));
export const NavWrapper = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: "1rem",
  [theme.breakpoints.down("sm")]: {
    ...flexRowBetweenCenter,
  },
}));
export const NavbarWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "auto 1rem",
  },
}));
