import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { flexRowStartStart } from "../../../common/scheme";

export const ReviewCardContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.servicio.border}`,
  width: "calc(100% - 2rem)",
  padding: "1rem",
  borderRadius: ".5rem",
  ...flexRowStartStart,
  gap: "1rem",
  "& .profile-pic": {
    width: "50px",
    height: "50px",
    borderRadius: ".5rem",
  },
  "& .review-pic": {
    width: "70px",
    height: "50px",
    borderRadius: "5px",
    marginLeft: "auto",
  },
  "& .review-text": {
    fontSize: "12px",
    fontWeight: "400",
    marginTop: ".5rem",
    color: theme.palette.profile.text,
  },
  "& .helping-text": {
    fontSize: "12px",
    fontWeight: "400",
    marginTop: ".5rem",
    color: theme.palette.profile.text,
  },
  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
    "& .review-pic": {
      display: "none",
    },
  },
}));
export const ReviewDetailContainer = styled(Box)(({ theme }) => ({
  "& h3": {
    fontSize: "12px",
    fontWeight: "700",
    marginTop: ".5rem",
    color: theme.palette.profile.text,
  },
}));
export const ReviewHeader = styled(Box)(({ theme }) => ({
  ...flexRowStartStart,
  gap: ".5rem",
  "& h1": {
    fontSize: "20px",
    fontWeight: "600",
    margin: "0",
    padding: "0",
  },
  "& p": {
    margin: "0",
    marginTop: ".3rem",
  },
  "& p:nth-of-type(1)": {
    color: theme.palette.servicio.rating,
    fontSize: "12px",
    fontWeight: "700",
  },
}));
export const RatingWrapper = styled(Box)(({ theme }) => ({
  ...flexRowStartStart,
  "& img": {
    width: "16px",
    height: "16px",
  },
  marginTop: ".35rem",
  marginLeft: ".5rem",
  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
  },
}));
