export const apiRoutes = {
  apiPrefix: "/api/v1",
  auth: {
    path: "/auth",
    profile: "/profile",
    login: "/login",
    logout: "/logout",
    changePassword: "/password",
    forgotPassword: "/forgot-password",
    register: "/register",
    refresh: "/refresh",
    update: "/update",
    updateFavorite: "/update-favorite",
    becomeSeller: "/become-seller",
  },
  category: {
    path: "/category",
    list: "/list",
  },
  service: {
    path: "/service",
    list: "/list",
    create: "/create",
    update: "/update",
    get: "/",
    manager: "/manager",
    profile: "/profile",
  },
  order: {
    path: "/order",
    list: "/list",
    get: "/",
    filesMessage: "/message/files",
  },
  checkout: {
    path: "/checkout",
    get: "/",
  },
  payment: {
    path: "/payment",
    createOrder: "/create-order",
    captureOrder: "/capture-order",
    withdrawPaypal: "/withdraw-paypal",
  },
  delivery: {
    path: "/delivery",
    list: "/list",
    complete: "/complete",
    reject: "/reject",
    create: "/create",
  },
  contact: {
    path: "/contact",
    email: "/email",
  },
};
