import React, { useState, useEffect } from "react";
import {
  ContactContainer,
  ContactText,
  ContactTitle,
  ContactWrapper,
  ContactBox,
  FormContainer,
  InputWrapper,
  InputLabel,
  InputContainer,
  ContactLayer1,
  ContactLayer2,
  ContactLayer3,
  TextError,
} from "../../styles/common/landing/Contact.styles";
import { PrimaryBtn, Text } from "../../styles/Common.styles";
import { contactEmail } from "../../api/contact";
import * as yup from "yup";
import "yup-phone";
import { useFormik } from "formik";
import { toast } from "react-toastify";

export const validationSchema = yup.object({
  name: yup.string().required("El nombre es requerido."),
  email: yup
    .string()
    .email("Introduce un correo valido.")
    .required("El correo es requerido."),
  phone: yup
    .string()
    .required("El teléfono es requerido.")
    .phone(true, true, "Introduce un teléfono valido."),
  company: yup.string().required("La compañia es requerido."),
  message: yup.string().required("El mensaje es requerido."),
});

function Contact() {
  const [loading, setLoading] = useState(false);

  let initialValues = {
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        console.log("values", values);

        const res = await contactEmail(values);
        console.log("res", res);
        toast.success("El mensaje se envió con exito.");
        formik.resetForm();
        setLoading(false);
      } catch (error) {
        toast.error("Hubo un problema con el envío.");
        setLoading(false);
      }
    },
  });

  return (
    <ContactContainer>
      <ContactLayer1></ContactLayer1>
      <ContactLayer2>
        <div className="circle-1"></div>
        <div className="circle-2"></div>
        <div className="circle-3"></div>
      </ContactLayer2>
      <ContactLayer3>
        <div className="circle-1"></div>
        <div className="circle-2"></div>
      </ContactLayer3>
      <ContactWrapper>
        <ContactTitle>Contáctanos</ContactTitle>
        <br />
        <ContactBox>
          <FormContainer>
            <form onSubmit={formik.handleSubmit}>
              <InputContainer>
                <InputWrapper>
                  <InputLabel>Nombre</InputLabel>
                  <input
                    type="text"
                    placeholder="Introduzca su nombre"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    className={`${
                      (formik.touched.name && Boolean(formik.errors.name)) ||
                      Boolean(formik.errors.name)
                        ? "form-error"
                        : ""
                    }`}
                  />
                  {(formik.touched.name && formik.errors.name) ||
                  formik.errors.name ? (
                    <TextError>{formik.errors.name}</TextError>
                  ) : null}
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>Email</InputLabel>
                  <input
                    type="email"
                    placeholder="Introduzca su email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className={`${
                      (formik.touched.email && Boolean(formik.errors.email)) ||
                      Boolean(formik.errors.email)
                        ? "form-error"
                        : ""
                    }`}
                  />
                  {(formik.touched.email && formik.errors.email) ||
                  formik.errors.email ? (
                    <TextError>{formik.errors.email}</TextError>
                  ) : null}
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>Teléfono</InputLabel>
                  <input
                    type="text"
                    placeholder="Introduzca su teléfono"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    className={`${
                      (formik.touched.phone && Boolean(formik.errors.phone)) ||
                      Boolean(formik.errors.phone)
                        ? "form-error"
                        : ""
                    }`}
                  />
                  {(formik.touched.phone && formik.errors.phone) ||
                  formik.errors.phone ? (
                    <TextError>{formik.errors.phone}</TextError>
                  ) : null}
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>Compañia</InputLabel>
                  <input
                    type="text"
                    placeholder="Introduzca su compañia"
                    name="company"
                    value={formik.values.company}
                    onChange={formik.handleChange}
                    className={`${
                      (formik.touched.company &&
                        Boolean(formik.errors.company)) ||
                      Boolean(formik.errors.company)
                        ? "form-error"
                        : ""
                    }`}
                  />
                  {(formik.touched.company && formik.errors.company) ||
                  formik.errors.company ? (
                    <TextError>{formik.errors.company}</TextError>
                  ) : null}
                </InputWrapper>
              </InputContainer>
              <InputWrapper>
                <InputLabel>Mensaje</InputLabel>
                <textarea
                  placeholder="Introduzca su mensaje"
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  className={`${
                    (formik.touched.message &&
                      Boolean(formik.errors.message)) ||
                    Boolean(formik.errors.message)
                      ? "form-error"
                      : ""
                  }`}
                />
                {(formik.touched.message && formik.errors.message) ||
                formik.errors.message ? (
                  <TextError>{formik.errors.message}</TextError>
                ) : null}
              </InputWrapper>
              <br />
              <PrimaryBtn type="submit" disabled={loading}>
                Get in Touch
              </PrimaryBtn>
            </form>
          </FormContainer>
        </ContactBox>
      </ContactWrapper>
    </ContactContainer>
  );
}

export default Contact;
