import React, { Fragment } from "react";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import Restore from "../auth/Restore";

function ResetPassword() {
  return (
    <Fragment>
      <Navbar />
      <Restore />
      <Footer margintop="0" />
    </Fragment>
  );
}

export default ResetPassword;
