import React, { Fragment, useEffect } from "react";
import Navbar from "../layout/Navbar";
import Header from "../common/landing/Header";
import Services from "../common/landing/Services";
import Footer from "../layout/Footer";
import { useSearchParams, useNavigate } from "react-router-dom";
import Banner from "../common/landing/Banner";
import Contact from "../common/landing/Contact";

function Landing() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const refresh_token = searchParams.get("refresh_token");
  const access_token = searchParams.get("access_token");

  useEffect(() => {
    if (refresh_token && access_token) {
      localStorage.setItem("token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      navigate("/");
    }
  }, [refresh_token, access_token, navigate]);

  return (
    <Fragment>
      <Navbar />
      <Header />
      <Services />
      <Banner />
      <Contact />
      <Footer />
    </Fragment>
  );
}

export default Landing;
