import React from "react";
import UserProfilePic from "../../assets/images/user-profile-pic.svg";
import HeartIcon from "../../assets/icons/heart.svg";
import {
  ProfileCardContainer,
  ProfileHeading,
  ProfileStatus,
  ContactContainer,
  ProfileDetail,
  LevelContainer,
  LevelBox,
  RatingContainer,
  StarsWrapper,
  ProfileCardDivider,
  DescriptionContainer,
  InfoWrapper,
  AboutContainer,
} from "../../styles/components/cards/ProfileCard.styles";
import {
  ServiceCreateContainer,
  ServiceCreateWrapper,
  SliderContainer,
  FormContainer,
  InputContainer,
  InputWrapper,
  InputLabel,
  ContainerBtn,
} from "../../styles/common/servicios/Create.styles";
import StarIcon from "../../assets/icons/star.svg";
import { OutlineBtn } from "../../styles/Common.styles";
import moment from "moment";

function ProfileFormCard({
  name,
  profesion,
  country,
  description,
  handleUser,
  userFile,
  handleUserFile,
  created_at,
}) {
  return (
    <ProfileCardContainer>
      <ProfileHeading>
        <ProfileStatus>
          <img
            src={userFile.name && URL.createObjectURL(userFile)}
            alt="profile-pic"
          />
          <ProfileDetail>
            <FormContainer>
              <InputContainer>
                <InputWrapper>
                  <InputLabel>Nombre</InputLabel>
                  <input
                    name="name"
                    type="text"
                    value={name}
                    onChange={handleUser}
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>Profesión</InputLabel>
                  <input
                    name="info"
                    type="text"
                    value={profesion}
                    onChange={handleUser}
                  />
                </InputWrapper>
              </InputContainer>
            </FormContainer>
            <LevelContainer>
              <OutlineBtn>
                <input
                  type="file"
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    opacity: 0,
                    cursor: "pointer",
                  }}
                  onChange={handleUserFile}
                />
                Cambiar imagen
              </OutlineBtn>
            </LevelContainer>
          </ProfileDetail>
        </ProfileStatus>
      </ProfileHeading>

      <ProfileCardDivider />

      <DescriptionContainer>
        <FormContainer>
          <InputContainer style={{ alignItems: "center" }}>
            <InfoWrapper>
              <InputWrapper>
                <InputLabel>Pais</InputLabel>
                <input
                  name="country"
                  type="text"
                  value={country}
                  onChange={handleUser}
                />
              </InputWrapper>
            </InfoWrapper>
            <InfoWrapper>
              <p>Miembro desde</p>
              <h1>{moment(created_at).format("DD MMM YYYY")}</h1>
            </InfoWrapper>
          </InputContainer>
        </FormContainer>
      </DescriptionContainer>

      <AboutContainer>
        <FormContainer>
          <InputWrapper>
            <InputLabel>Descripción</InputLabel>
            <textarea
              name="description"
              value={description}
              onChange={handleUser}
            ></textarea>
          </InputWrapper>
        </FormContainer>
      </AboutContainer>
    </ProfileCardContainer>
  );
}

export default ProfileFormCard;
