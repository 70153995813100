import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { becomeSeller, useUser } from "../../api/auth";
import { OutlineBtn, PrimaryBtn } from "../../styles/Common.styles";
import {
  BecomeSellerConainer,
  BecomeSellerWrapper,
  BtnContainer,
} from "../../styles/common/profile/BecomeSeller.styles.js";

const BecomeSeller = () => {
  const { refetch } = useUser();
  const navigate = useNavigate();

  const handleYes = async () => {
    await becomeSeller();
    refetch();
    navigate("/");
  };

  const handleNo = () => {
    navigate("/");
  };

  return (
    <BecomeSellerConainer>
      <BecomeSellerWrapper>
        <h1>¿Realmente desea convertise en vendedor?</h1>
        <br />
        <BtnContainer>
          <PrimaryBtn onClick={handleYes}>Si</PrimaryBtn>
          <OutlineBtn onClick={handleNo}>No</OutlineBtn>
        </BtnContainer>
      </BecomeSellerWrapper>
    </BecomeSellerConainer>
  );
};

export default BecomeSeller;
