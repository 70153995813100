import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import NextArrowIcon from "../../assets/slider/next-arrow.svg";
import PrevArrowIcon from "../../assets/slider/prev-arrow.svg";
import { app } from "../../utils/config";

function SamplePrevArrow(props) {
  const { onClick, imgSrc, imgAlt, className } = props;

  return (
    <img src={imgSrc} alt={imgAlt} onClick={onClick} className={className} />
  );
}

function SampleNextArrow(props) {
  const { className, onClick, imgSrc, imgAlt } = props;
  return (
    <img src={imgSrc} alt={imgAlt} onClick={onClick} className={className} />
  );
}

const getImage = (image) => {
  if (image.includes("http") || image.includes("https")) {
    return image;
  }
  return `${app.serverURL}/${image}`;
};

class UserSlider extends Component {
  render() {
    const { service } = this.props;

    const picsData = service.images.map((image, idx) => {
      return { id: idx, pic: getImage(image) };
    });

    const settings = {
      customPaging: function (i) {
        return (
          <img
            src={picsData[i] ? picsData[i].pic : ""}
            className="bottom-pics"
            alt="pics"
            style={{ borderRadius: ".5rem" }}
          />
        );
      },
      dots: true,
      infinite: true,
      speed: 250,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <SampleNextArrow imgSrc={NextArrowIcon} imgAlt="next-icon" />,
      prevArrow: <SamplePrevArrow imgSrc={PrevArrowIcon} imgAlt="prev-icon" />,
    };

    const style = { borderRadius: ".5rem" };

    return (
      <div>
        <h2>{service.title}</h2>
        <Slider {...settings}>
          {picsData.map((image, idx) => (
            <div key={idx}>
              <img
                src={image.pic}
                alt="pic1"
                width="100%"
                height="340px"
                style={style}
              />
            </div>
          ))}
          {/* <div>
            <img
              src={Pic1}
              alt="pic1"
              width="100%"
              height="340px"
              style={style}
            />
          </div>
          <div>
            <img
              src={Pic2}
              alt="pic2"
              width="100%"
              height="340px"
              style={style}
            />
          </div>
          <div>
            <img
              src={Pic3}
              alt="pic3"
              width="100%"
              height="340px"
              style={style}
            />
          </div>
          <div>
            <img
              src={Pic4}
              alt="pic4"
              width="100%"
              height="340px"
              style={style}
            />
          </div> */}
        </Slider>
      </div>
    );
  }
}

export default UserSlider;
