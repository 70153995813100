import { apiRoutes } from "../constant.routes";
import { api } from "../utils/api";
import { useFetch } from "../utils/reactQuery";
import { useQueryClient, useQuery } from "@tanstack/react-query";

export const getTokenByPassword = (email, password) =>
  api.post(apiRoutes.apiPrefix + apiRoutes.auth.path + apiRoutes.auth.login, {
    email,
    password,
  });

export const updateUser = (body) =>
  api.put(
    apiRoutes.apiPrefix + apiRoutes.auth.path + apiRoutes.auth.update,
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const becomeSeller = () =>
  api.put(
    apiRoutes.apiPrefix + apiRoutes.auth.path + apiRoutes.auth.becomeSeller
  );

export const loginGoogle = () => {
  return api.get("/api/v1/auth/google", {});
};

export const register = (fields) =>
  api.post(
    apiRoutes.apiPrefix + apiRoutes.auth.path + apiRoutes.auth.register,
    fields
  );

export const logout = () =>
  api.post(apiRoutes.apiPrefix + apiRoutes.auth.path + apiRoutes.auth.logout);

export const forgotPassword = (email) =>
  api.post(
    apiRoutes.apiPrefix + apiRoutes.auth.path + apiRoutes.auth.forgotPassword,
    { email }
  );

export const updateFavorite = (body) =>
  api.put(
    apiRoutes.apiPrefix + apiRoutes.auth.path + apiRoutes.auth.updateFavorite,
    body
  );

export const changePassword = (fields) =>
  api.post(
    apiRoutes.apiPrefix + apiRoutes.auth.path + apiRoutes.auth.changePassword,
    fields
  );

export const useUser = () => {
  const queryClient = useQueryClient();

  const getStoredUser = () => {
    let storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  };

  const setStoredUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
  };

  const context = useFetch(
    apiRoutes.apiPrefix + apiRoutes.auth.path + apiRoutes.auth.profile,
    undefined,
    {
      refetchOnMount: true,
      placeholderData: () => {
        return getStoredUser();
      },
      onSuccess: (received) => {
        setStoredUser(received);
      },
      onError: (received) => {
        console.log("received", received);
        updateUser(null);
        // console.log("received 2", received);
      },
      enabled: false,
      retry: false,
    }
  );

  const updateUser = async (newUser) => {
    // console.log("UPDATE");
    queryClient.setQueryData(
      [
        apiRoutes.apiPrefix + apiRoutes.auth.path + apiRoutes.auth.profile,
        undefined,
      ],
      getStoredUser()
    );
  };

  return { ...context, user: context.data?.user, updateUser };
};
