import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { flexColumnStartStart } from "../../../common/scheme";

export const TestimonialCardContainer = styled(Box)(({ theme }) => ({
  width: "420px",
  height: "350px",
  padding: "20px 30px",
  border: `1px solid ${theme.palette.colors.border}`,
  backgroundColor: theme.palette.text.white,
  ...flexColumnStartStart,
  borderRadius: "20px",
  "& img": {
    width: "135px",
    height: "115px",
  },
  "& h1": {
    fontSize: "30px",
    margin: "5px 0",
    fontWeight: "700",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
  },
}));
