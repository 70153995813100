import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@emotion/react";
import {
  TableHeading,
  TablePricing,
  TableText,
  SelectBtn,
} from "../../styles/common/profile/PricingTable.styles";
import TickIcon from "../../assets/icons/tick.svg";
import {
  FormContainer,
  InputContainer,
  InputWrapper,
  InputLabel,
} from "../../styles/common/servicios/Create.styles";
import { PrimaryBtn } from "../../styles/Common.styles";

function createData(name, basic, standard, premium, req4, id) {
  return { name, basic, standard, premium, req4, id };
}

export default function BasicTable({
  newData2,
  setNewData2,
  plans,
  setPlans,
  edit,
}) {
  const theme = useTheme();
  const { primary } = theme.palette.colors;
  const { header, tableBg } = theme.palette.pricing;
  const [newData, setNewData] = useState({});
  // const [newData2, setNewData2] = useState([]);

  const borderBottomRight = {
    borderBottom: `1px solid ${primary}`,
    borderRight: `1px solid ${primary}`,
  };
  const borderTopRightLeft = {
    borderTop: `1px solid ${primary}`,
    borderRight: `1px solid ${primary}`,
    borderLeft: `1px solid ${primary}`,
  };

  useEffect(() => {
    if (!edit) {
      setPlans([
        createData("Precio", 0, 0, 0, "USD", "price"),
        createData("Tiempo de entrega", 0, 0, 0, "Días", "delivery_time"),
      ]);
      setNewData2([
        {
          name: "Precio",
          basic: 0,
          standard: 0,
          premium: 0,
          id: "price",
        },
        {
          name: "Tiempo de entrega",
          basic: 0,
          standard: 0,
          premium: 0,
          id: "delivery_time",
        },
      ]);
    }
  }, []);

  const deletePlan = (id, planIndex) => {
    const otherData = newData2.filter((d, idx) => d.id !== id);
    const newOtherData = otherData.map((d, idx) => {
      if (d.id == "price" || d.id == "delivery_time") {
        return d;
      }
      return { ...d, id: idx };
    });

    const otherPlan = plans.filter((d, idx) => d.id !== id);
    const newOtherPlan = otherPlan.map((d, idx) => {
      if (d.id == "price" || d.id == "delivery_time") {
        return d;
      }
      return { ...d, id: idx };
    });
    setNewData2([...newOtherData]);
    setPlans([...newOtherPlan]);
  };

  const addPlan = () => {
    setNewData2([
      ...newData2,
      {
        name: "",
        basic: false,
        standard: false,
        premium: false,
        id: plans.length,
      },
    ]);
    setPlans((prevPlan) => [
      ...prevPlan,
      createData("", false, false, false, "", plans.length),
    ]);
  };

  return (
    <>
      <TableContainer sx={{ ...borderTopRightLeft, borderRadius: ".5rem" }}>
        <Table sx={{ minWidth: 550 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ background: header }}>
              <TableCell
                sx={{
                  ...borderBottomRight,
                  minWidth: "100px",
                }}
              >
                <TableHeading>Plans</TableHeading>
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  ...borderBottomRight,
                  minWidth: "40px",
                }}
              >
                <TableHeading>BÁSICO</TableHeading>
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  ...borderBottomRight,
                  minWidth: "40px",
                }}
              >
                <TableHeading>STANDARD</TableHeading>
              </TableCell>
              <TableCell
                align="left"
                // sx={{
                //   borderBottom: `1px solid ${primary}`,
                //   minWidth: "100px",
                // }}
                sx={{
                  ...borderBottomRight,
                  minWidth: "40px",
                }}
              >
                <TableHeading>PREMIUM</TableHeading>
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  borderBottom: `1px solid ${primary}`,
                  minWidth: "40px",
                }}
              >
                <TableHeading>Acciones</TableHeading>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plans.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ background: index % 2 === 0 ? "transparent" : tableBg }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ ...borderBottomRight }}
                >
                  <TableText>
                    {row.name ? (
                      row.name
                    ) : (
                      <InputWrapper>
                        <input
                          type="text"
                          onChange={(e) => {
                            const myData = newData2.find(
                              (d) => d.id === row.id
                            );
                            const otherData = newData2.filter(
                              (d) => d.id !== row.id
                            );
                            setNewData2([
                              ...otherData,
                              { ...myData, name: e.target.value },
                            ]);
                          }}
                          value={newData2.find((d) => d.id === row.id).name}
                        />
                      </InputWrapper>
                    )}
                  </TableText>
                </TableCell>
                <TableCell align="center" sx={{ ...borderBottomRight }}>
                  <TableText>
                    {row.basic === false || row.basic === true ? (
                      <InputWrapper>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            const myData = newData2.find(
                              (d) => d.id === row.id
                            );
                            const otherData = newData2.filter(
                              (d) => d.id !== row.id
                            );
                            setNewData2([
                              ...otherData,
                              { ...myData, basic: !myData.basic },
                            ]);
                          }}
                          checked={newData2.find((d) => d.id === row.id).basic}
                        />
                      </InputWrapper>
                    ) : (
                      <InputWrapper>
                        <input
                          type="number"
                          onChange={(e) => {
                            const myData = newData2.find(
                              (d) => d.id === row.id
                            );
                            const otherData = newData2.filter(
                              (d) => d.id !== row.id
                            );

                            if (e.target.value.match(`^[0-9]*[1-9][0-9]*$`)) {
                              setNewData2([
                                ...otherData,
                                { ...myData, basic: e.target.value },
                              ]);
                            }
                          }}
                          value={newData2.find((d) => d.id === row.id).basic}
                        />
                      </InputWrapper>
                    )}
                  </TableText>
                </TableCell>
                <TableCell align="center" sx={{ ...borderBottomRight }}>
                  {row.standard === false || row.standard === true ? (
                    <InputWrapper>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          const myData = newData2.find((d) => d.id === row.id);
                          const otherData = newData2.filter(
                            (d) => d.id !== row.id
                          );
                          setNewData2([
                            ...otherData,
                            { ...myData, standard: !myData.standard },
                          ]);
                        }}
                        checked={newData2.find((d) => d.id === row.id).standard}
                      />
                    </InputWrapper>
                  ) : (
                    <InputWrapper>
                      <input
                        type="number"
                        onChange={(e) => {
                          const myData = newData2.find((d) => d.id === row.id);
                          const otherData = newData2.filter(
                            (d) => d.id !== row.id
                          );
                          if (e.target.value.match(`^[0-9]*[1-9][0-9]*$`)) {
                            setNewData2([
                              ...otherData,
                              { ...myData, standard: e.target.value },
                            ]);
                          }
                        }}
                        value={newData2.find((d) => d.id === row.id).standard}
                      />
                    </InputWrapper>
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  // sx={{ borderBottom: `1px solid ${primary}` }}
                  sx={{ ...borderBottomRight }}
                >
                  <TableText>
                    <TableText>
                      {row.premium === false || row.premium === true ? (
                        <InputWrapper>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              const myData = newData2.find(
                                (d) => d.id === row.id
                              );
                              const otherData = newData2.filter(
                                (d) => d.id !== row.id
                              );

                              setNewData2([
                                ...otherData,
                                { ...myData, premium: !myData.premium },
                              ]);
                            }}
                            checked={
                              newData2.find((d) => d.id === row.id).premium
                            }
                          />
                        </InputWrapper>
                      ) : (
                        <InputWrapper>
                          <input
                            type="number"
                            onChange={(e) => {
                              const myData = newData2.find(
                                (d) => d.id === row.id
                              );
                              const otherData = newData2.filter(
                                (d) => d.id !== row.id
                              );

                              if (e.target.value.match(`^[0-9]*[1-9][0-9]*$`)) {
                                setNewData2([
                                  ...otherData,
                                  { ...myData, premium: e.target.value },
                                ]);
                              }
                            }}
                            value={
                              newData2.find((d) => d.id === row.id).premium
                            }
                          />
                        </InputWrapper>
                      )}
                    </TableText>
                  </TableText>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ borderBottom: `1px solid ${primary}` }}
                >
                  {row.req4 ? (
                    row.req4
                  ) : (
                    <PrimaryBtn onClick={() => deletePlan(row.id, index)}>
                      Borrar
                    </PrimaryBtn>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <PrimaryBtn onClick={addPlan}>Agregar Plan</PrimaryBtn>
    </>
  );
}
