import React, { Fragment, useEffect } from "react";
import Navbar from "../layout/Navbar";
import Header from "../common/about_us/Header";
import Users from "../common/about_us/Users";
import Testimonial from "../common/about_us/Testimonial";
import FAQ from "../common/about_us/FAQ";
import Contact from "../common/about_us/Contact";
import Footer from "../layout/Footer";

function AboutUs() {
  return (
    <Fragment>
      <Navbar />
      <Header />
      <Users />
      <Testimonial />
      <FAQ />
      <Contact />
      <Footer margintop="7rem" />
    </Fragment>
  );
}

export default AboutUs;
