import React, { Fragment } from "react";
import ServicesManager from "../common/servicios/ServicesManager";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";

function MyServices() {
  return (
    <Fragment>
      <Navbar />
      <ServicesManager />
      <Footer margintop="7rem" />
    </Fragment>
  );
}

export default MyServices;
