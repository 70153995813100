import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ProfileCard from "../../components/cards/ProfileCard";
import {
  UserProfileContainer,
  UserProfileWrapper,
  SliderContainer,
  DescriptionContainer,
  ReviewHeader,
  OrderContainer,
  ProfileCardWrapper,
  ReviewCardWrapper,
} from "../../styles/common/profile/UserProfile.styles";
import UserSlider from "./UserSlider";
import PricingTable from "./PricingTable";
import ReviewCard from "../../components/cards/ReviewCard";
import StarIcon from "../../assets/icons/star.svg";
import { FlexRowStartCenter } from "../../styles/Common.styles";
import { listData, notesData, reviewData } from "../../data/data";
import { useGetServiceProfile } from "../../api/service";
import { app } from "../../utils/config";

const getImage = (image) => {
  if (image.includes("http") || image.includes("https")) {
    return image;
  }
  return `${app.serverURL}/${image}`;
};

function UserProfile() {
  const [plans, setPlans] = useState([]);
  const params = useParams();
  const { data } = useGetServiceProfile(params.id);

  useEffect(() => {
    if (data && plans.length <= 0) {
      let arr = {};
      const { basic, standard, premium } = data.service.planId;
      const plansData = { basic, standard, premium };

      Object.keys(plansData).map((p) => {
        arr["0"] = {
          ...arr["0"],
          name: "Precio",
          id: "price",
          [p]: plansData[p].price,
        };
        arr["1"] = {
          ...arr["1"],
          name: "Tiempo de entrega",
          id: "delivery_time",
          [p]: plansData[p].delivery_time,
        };
        plansData[p].features.map((feat) => {
          arr[feat.id] = {
            ...arr[feat.id],
            id: feat.id,
            name: feat.name,
            [p]: feat.value,
          };

          return { id: feat.id, name: feat.name, [p]: feat.value };
        });
      });
      setPlans(Object.values(arr));
    }
  }, [data]);

  if (!data) {
    return;
  }

  return (
    <UserProfileContainer>
      <UserProfileWrapper>
        <ProfileCardWrapper>
          {data && <ProfileCard profile={data} />}

          <h1 className="price-heading">Precio</h1>
          {data && (
            <PricingTable plans={plans} plansObj={data.service.planId} />
          )}
          <DescriptionContainer>
            <h1>Descripción del servicio</h1>
            {data && data.service.description}
            {/* 
            <h2>Hello</h2>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quam,
              exercitationem dolorem fugit corrupti accusamus consequuntur at
              consectetur totam similique tenetur id incidunt pariatur, a illo
              eligendi provident aliquid sed adipisci.
            </p>
            <h2>What I need to start the project:</h2>
            <p>
              - Project discussion before ordering, so just text me first if you
              interest. I will reply you.
            </p>
            <h2>Why choose me ? </h2>
            <ul>
              {listData.map(({ id, text }) => (
                <li key={id}>- {text}</li>
              ))}
            </ul>
            <h2>Data that I offer? </h2>
            <p>
              I work with Figma, Adobe XD. The data you get is PNG, JPG and
              Source File so you or your website developer can convert it into
              HTML & CSS / WIX / Shopify / WordPress / GoDaddy / any other
              Programming Language and CMS Program.
            </p>
            <h2>Notes:</h2>
            <ul>
              {notesData.map(({ id, text }) => (
                <li key={id}>- {text}</li>
              ))}
            </ul>
            <ul>
              <li>So what are you waiting for?</li>
              <li>place your order now!</li>
            </ul>
            <p>Thank you:)</p> */}
          </DescriptionContainer>

          <ReviewHeader>
            <h1>Reseñas</h1>
            <OrderContainer>
              <FlexRowStartCenter>
                <img src={StarIcon} alt="stars" />
                <img src={StarIcon} alt="stars" />
                <img src={StarIcon} alt="stars" />
                <img src={StarIcon} alt="stars" />
                <img src={StarIcon} alt="stars" />
              </FlexRowStartCenter>
              <p>{data && data.reviewAvarage.stars}</p>
              <p>({data && data.reviewAvarage.amount})</p>
            </OrderContainer>
          </ReviewHeader>

          <ReviewCardWrapper>
            {data &&
              data.review.map(
                ({
                  _id,
                  userName,
                  buyerReview,
                  avarageRating,
                  stars,
                  message,
                  userProfilePic,
                  user_id,
                  work,
                }) => (
                  <>
                    <ReviewCard
                      key={_id}
                      id={_id}
                      userProfilePic={getImage(user_id.image_url)}
                      userReviewPic={work && getImage(work)}
                      userName={user_id.name}
                      buyerReview={message}
                      averageRating={stars}
                      userLocation={user_id?.country}
                      starIcon={StarIcon}
                    />
                  </>
                )
              )}
            {/* {reviewData.map(
              ({
                id,
                userName,
                buyerReview,
                orders,
                avarageRating,
                starIcon,
                userLocation,
                userProfilePic,
                userReviewPic,
              }) => (
                <ReviewCard
                  key={id}
                  id={id}
                  userProfilePic={userProfilePic}
                  userReviewPic={userReviewPic}
                  userName={userName}
                  buyerReview={buyerReview}
                  // orders={orders}
                  averageRating={avarageRating}
                  userLocation={userLocation}
                  starIcon={starIcon}
                />
              )
            )} */}
          </ReviewCardWrapper>
        </ProfileCardWrapper>

        <SliderContainer>
          {data && <UserSlider service={data.service} />}
        </SliderContainer>
      </UserProfileWrapper>
    </UserProfileContainer>
  );
}

export default UserProfile;
