import ProfilePic from "../assets/images/profile-pic.svg";
import UserPic from "../assets/images/user-pic.svg";
import StarIcon from "../assets/icons/star.svg";
import UserProfilePic from "../assets/images/user-profile-pic.svg";
import UserReviewPic from "../assets/slider/pic3.png";
import Icon1 from "../assets/faq/icon1.svg";
import Icon2 from "../assets/faq/icon2.svg";
import Icon3 from "../assets/faq/icon3.svg";
import Icon4 from "../assets/faq/icon4.svg";
import Icon5 from "../assets/faq/icon5.svg";
import Icon6 from "../assets/faq/icon6.svg";
import Icon7 from "../assets/faq/icon7.svg";

export const serviciosData = [
  { id: 1, value: 1, item: "Editing", link: "/servicios/editing" },
  {
    id: 2,
    value: 2,
    item: "Corrección Ortográfica",
    link: "/servicios/corrección",
  },
  { id: 3, value: 3, item: "Maquetación", link: "/servicios/maquetación" },
  { id: 4, value: 4, item: "Ilustración", link: "/servicios/ilustración" },
  { id: 5, value: 5, item: "Diseño de Portada", link: "/servicios/diseño" },
  { id: 6, value: 6, item: "Traducción", link: "/servicios/traducción" },
  { id: 7, value: 7, item: "Book trailer", link: "/servicios/book" },
];

export const buyerData = [
  { id: 1, value: 1, item: "Editar Perfil", link: "/profile" },
  { id: 2, value: 2, item: "Pedidos", link: "/orders" },
  { id: 3, value: 3, item: "Ser Vendedor", link: "/become-seller" },
];

export const cuentaData = [
  { id: 1, value: 1, item: "Editar Perfil", link: "/profile" },
  { id: 2, value: 2, item: "Mis servicios", link: "/services/manager" },
  { id: 3, value: 3, item: "Pedidos", link: "/orders" },
  { id: 4, value: 4, item: "Ganancias", link: "/balance" },
];

export const filterData = [
  { id: 1, value: "most_recent", title: "Más recientes" },
  { id: 2, value: "oldest", title: "Más antiguos" },
];

export const categoryData = [
  { id: 1, title: "Category 1", value: "44" },
  { id: 2, title: "Category 2", value: "84" },
  { id: 3, title: "Category 3", value: "94" },
  { id: 4, title: "Category 4", value: "454" },
  { id: 5, title: "Category 5", value: "4" },
  { id: 6, title: "Category 6", value: "442" },
  { id: 7, title: "Category 7", value: "1449" },
];

export const orderData = [
  {
    id: 1,
    userPic: UserPic,
    profilePic: ProfilePic,
    price: "$600",
    status: "In Progress",
    dueIn: "8h, 7m",
    userName: "Budi Santoso",
    userLocation: "England",
    link: "/checkout",
  },
  {
    id: 2,
    userPic: UserPic,
    profilePic: ProfilePic,
    price: "$500",
    status: "Complated",
    dueIn: "4d, 4h, 7m",
    userName: "Budi Santoso",
    userLocation: "Panama",
    link: "/checkout",
  },
  {
    id: 3,
    userPic: UserPic,
    profilePic: ProfilePic,
    price: "$300",
    status: "In Progress",
    dueIn: "8h",
    userName: "Budi Santoso",
    userLocation: "Australia",
    link: "/checkout",
  },
  {
    id: 4,
    userPic: UserPic,
    profilePic: ProfilePic,
    price: "$100",
    status: "in revision",
    dueIn: "7m",
    userName: "Budi Santoso",
    userLocation: "Indonasia",
    link: "/checkout",
  },
  {
    id: 5,
    userPic: UserPic,
    profilePic: ProfilePic,
    price: "$600",
    status: "In Progress",
    dueIn: "8h, 7m",
    userName: "Budi Santoso",
    userLocation: "Malaysia",
    link: "/checkout",
  },
];

export const servicioData = [
  {
    id: 1,
    name: "Karata",
    detail: "User sub detail",
    profilePic: ProfilePic,
    userPic: UserPic,
    order: "23",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    rating: "4.9",
    price: "600",
  },
  {
    id: 2,
    name: "Romi",
    detail: "User sub detail",
    profilePic: ProfilePic,
    userPic: UserPic,
    order: "15",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    rating: "4.9",
    price: "400",
  },
  {
    id: 3,
    name: "User",
    detail: "User sub detail",
    profilePic: ProfilePic,
    userPic: UserPic,
    order: "20",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    rating: "4.9",
    price: "500",
  },
  {
    id: 4,
    name: "New ",
    detail: "User sub detail",
    profilePic: ProfilePic,
    userPic: UserPic,
    order: "50",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    rating: "4.9",
    price: "60",
  },
  {
    id: 5,
    name: "Programming",
    detail: "User sub detail",
    profilePic: ProfilePic,
    userPic: UserPic,
    order: "70",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    rating: "4.9",
    price: "50",
  },
  {
    id: 6,
    name: "Coding",
    detail: "User sub detail",
    profilePic: ProfilePic,
    userPic: UserPic,
    order: "200",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    rating: "4.9",
    price: "60",
  },
  {
    id: 7,
    name: "Again",
    detail: "User sub detail",
    profilePic: ProfilePic,
    userPic: UserPic,
    order: "10",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    rating: "4.9",
    price: "60",
  },
  {
    id: 8,
    name: "Newa",
    detail: "User sub detail",
    profilePic: ProfilePic,
    userPic: UserPic,
    order: "10",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    rating: "4.9",
    price: "6600",
  },
  {
    id: 9,
    name: "Romi1107",
    detail: "User sub detail",
    profilePic: ProfilePic,
    userPic: UserPic,
    order: "10",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    rating: "4.9",
    price: "1600",
  },
  {
    id: 10,
    name: "User123",
    detail: "User sub detail",
    profilePic: ProfilePic,
    userPic: UserPic,
    order: "100",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    rating: "4.9",
    price: "200",
  },
  {
    id: 11,
    name: "User2",
    detail: "User sub detail",
    profilePic: ProfilePic,
    userPic: UserPic,
    order: "80",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    rating: "4.9",
    price: "100",
  },
  {
    id: 12,
    name: "New New",
    detail: "User sub detail",
    profilePic: ProfilePic,
    userPic: UserPic,
    order: "10",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    rating: "4.9",
    price: "300",
  },
  {
    id: 13,
    name: "Last",
    detail: "User sub detail",
    profilePic: ProfilePic,
    userPic: UserPic,
    order: "10",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    rating: "4.9",
    price: "1200",
  },
];

export const reviewData = [
  {
    id: 1,
    userProfilePic: UserProfilePic,
    userReviewPic: UserReviewPic,
    userName: "Samsul Solehudin",
    buyerReview:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s",
    orders: 100,
    avarageRating: 4.8,
    userLocation: "London England",
    starIcon: StarIcon,
  },
  {
    id: 2,
    userProfilePic: UserProfilePic,
    userReviewPic: UserReviewPic,
    userName: "Second  Review",
    buyerReview:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s",
    orders: 100,
    avarageRating: 4.8,
    userLocation: "London England",
    starIcon: StarIcon,
  },
  {
    id: 3,
    userProfilePic: UserProfilePic,
    userReviewPic: UserReviewPic,
    userName: "Third  Review",
    buyerReview:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s",
    orders: 100,
    avarageRating: 4.8,
    userLocation: "London England",
    starIcon: StarIcon,
  },
];

export const listData = [
  { id: 1, text: " Understand your business goal  " },
  { id: 2, text: "Proficient in Figma and Adobe XD  " },
  { id: 3, text: "Prototype with effects (Hover etc.)  " },
  { id: 4, text: " Designing according to design guidelines" },
  { id: 5, text: "Intuitive userflow" },
  { id: 6, text: "Unlimited revition" },
  {
    id: 7,
    text: "Understand typography hierarchy, grid, coloring and eye catching design",
  },
  { id: 8, text: "of course, focus on customer satisfaction" },
];

export const notesData = [
  {
    id: 1,
    text: "I fully only made UI UX design, did not do coding and did not make animation in this project.",
  },
  { id: 2, text: "I designing with respect to copyright  " },
];

export const serviceData = [
  {
    id: 1,
    title: "Editor",
  },
  {
    id: 2,
    title: "Corrector",
  },
  {
    id: 3,
    title: "Maquetador",
  },
  {
    id: 4,
    title: "Diseñador",
  },
  {
    id: 5,
    title: "Traductor",
  },
  {
    id: 6,
    title: "Ilustrador",
  },
];

export const faqData = [
  {
    id: 1,
    title: "Editing",
    icon: Icon1,
    link: "/servicios/editing",
    detail:
      "También conocido como edición literaria, se refiere al proceso de mejoramiento de la obra —ya se sea de ficción o no— trabajado de la mano de un profesional quien busca pulir a fondo dicho escrito. Va un paso más allá de la corrección ortográfica y de estilo, esta analiza a profundidad el contenido de la obra: trama, personajes, narrativa, ritmo, etc.",
  },

  {
    id: 2,
    title: "Corrección ortográfica y estilo",
    icon: Icon2,
    link: "/servicios/corrección",
    detail:
      "Consiste en la revisión y mejoramiento del lenguaje en que se ha redactado obra. La corrección ortográfica apela al cumplimiento de las normas básicas de ortografía y gramática; mientras que la corrección de estilo enriquece el lenguaje tomando en cuenta la precisión, la cohesión, eliminando vicios de escritura más comunes.",
  },
  {
    id: 3,
    title: "Maquetación",
    icon: Icon3,
    link: "/servicios/maquetación",

    detail:
      "Es el proceso de colocación del texto de acuerdo con el formato correspondiente. Busca la armonía visual, la estética y a la fluidez del texto previo a su publicación. Consiste en además de maquetar, también componer y diagramar, aparte de ciertas nociones de diseño gráfico.",
  },
  {
    id: 4,
    title: "Ilustración",
    icon: Icon4,
    link: "/servicios/ilustración",
    detail:
      "Se refiere al componente gráfico que se crean a partir del texto y que busca complementar, acompañar o realzar la obra. Es un factor crucial en la literatura infantil, la novela gráfica, el manga o el comic.",
  },
  {
    id: 5,
    title: "Diseño de portada",
    icon: Icon5,
    link: "/servicios/diseño",
    detail:
      "Se trata de lo primero que visualiza el lector al tomar el libro, busca causar un impacto a primera vista y para ello es necesario el trabajo de un profesional que, con todo el conocimiento del diseño gráfico, logre transmitir la esencia de la obra en su cubierta.",
  },
  {
    id: 6,
    title: "Traducción",
    icon: Icon6,
    link: "/servicios/traducción",

    detail:
      "Más allá de una traducción común o técnica, la traducción editorial apunta a producir el texto de un idioma a otro sin perder ni diluir elementos tan profundos como el sentido o significado de la lengua de la obra original.",
  },
  {
    id: 7,
    title: "Book trailer",
    icon: Icon7,
    link: "/servicios/book",

    detail:
      "Es una producción audiovisual clave a la hora de captar lectores. Como si fuese el tráiler de una película de cine, el booktrailer es una primera experiencia que transmite la esencia y la sinopsis de la obra.",
  },
];
