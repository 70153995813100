import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  flexColumnCenterCenter,
  flexColumnStartCenter,
  flexRowBetweenCenter,
  flexRowCenterCenter,
  flexRowStartCenter,
  flexRowStartStart,
  flexColumnStartStart,
} from "../../../common/scheme";
import { FlexRowEndCenter } from "../../Common.styles";

export const ServiceCreateContainer = styled(Box)(({ theme }) => ({
  ...flexRowCenterCenter,
  marginTop: "2rem",
  width: "100%",
}));
export const ServiceCreateWrapper = styled(Box)(({ theme, edit }) => ({
  width: "1125px",
  display: "flex",
  flexDirection: edit ? "row" : "column",
  alignItems: !edit && "center",
  gap: "2rem",
  [theme.breakpoints.down("lg")]: {
    flexWrap: "wrap",
    ...flexRowCenterCenter,
    alignItems: "flex-start",
    width: "calc(100% - 2rem)",
    margin: "0 1rem",
  },
}));
export const SliderContainer = styled(Box)(({ theme, edit }) => ({
  width: edit ? "600px" : "650px",
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 1rem)",
  },
  "& .price-heading": {
    marginTop: "7rem",
    fontSize: "20px",
    fontWeight: "700",
    color: theme.palette.text.heading,
  },
}));
export const ServiceCreateCardWrapper = styled(Box)(({ theme }) => ({
  width: "550px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
export const DescriptionContainer = styled(Box)(({ theme }) => ({
  marginTop: "2rem",
  "& h1": {
    fontSize: "20px",
    fontWeight: "700",
    color: theme.palette.text.heading,
  },
  "& h2": {
    fontSize: "14px",
    fontWeight: "700",
    color: theme.palette.text.heading,
  },
  "& p": {
    fontSize: "12px",
    fontWeight: "400",
    color: theme.palette.profile.text,
  },
  "& ul": {
    listStyleType: "none",
    padding: "0",
    margin: "0",
    fontSize: "12px",
    fontWeight: "400",
    color: theme.palette.profile.text,
  },
}));
export const OrderContainer = styled(Box)(({ theme }) => ({
  ...flexRowStartCenter,
  gap: ".5rem",
  "& img": {
    width: "16px",
    height: "16px",
  },
  "& p": {
    margin: "0",
  },
  "& p:nth-of-type(1)": {
    color: theme.palette.servicio.rating,
    fontSize: "12px",
    fontWeight: "700",
  },
  "& p:nth-of-type(2)": {
    fontSize: "12px",
    fontWeight: "400",
  },
}));

export const FormContainer = styled(Box)(({ theme }) => ({
  width: "calc(100% - 2rem)",
  [theme.breakpoints.down("lg")]: {
    maxWidth: "600px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 2rem)",
  },
}));
export const InputContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: "2rem",
  marginBottom: "2rem",
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
}));
export const InputWrapper = styled(Box)(({ theme }) => ({
  ...flexColumnStartStart,
  "& input": {
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.colors.border}`,
    padding: "15px 10px",
    borderRadius: ".5rem",
    width: "100%",
    minHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)",
    },
  },
  "& textarea": {
    fontFamily: "Poppins",
    fontSize: "16px",
    outline: "none !impotant",
    width: "100% !important",
    height: "300px !important",
    border: `1px solid ${theme.palette.colors.border}`,
    padding: "15px 10px",
    borderRadius: ".5rem",
    "&::placeholder": {
      fontFamily: "'Poppins', sans-serif",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)",
    },
  },
}));

export const InputLabel = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "600px",
  color: theme.palette.text.heading,
  marginBottom: ".5rem",
}));

export const ImageSliderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingTop: "2rem",
  display: "flex",
  position: "relative",

  "& button": {
    position: "absolute",
    top: "50px",
    right: "20px",
  },
}));

export const ContainerBtn = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "2rem",
}));
