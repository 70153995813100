import React, { useState } from "react";
import { AuthWrapper } from "../styles/auth/Auth.styles";
import {
  AuthContainer,
  FormContainer,
  InputWrapper,
  InputContainer,
  ContinueBtn,
  RestoreText,
} from "../styles/auth/Auth.styles";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from "../api/auth";
import { toast } from "react-toastify";

function Restore() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const onSubmit = async () => {
    // forgotPassword
    try {
      const res = await forgotPassword(email);
      if (res.status === 201) {
        navigate("/");
      }
    } catch (err) {
      const status = err.response.status;
      if (status === 400) {
        toast.error("Faltan agregar algunos campos.");
      }
    }
  };
  return (
    <AuthContainer>
      <AuthWrapper>
        <h1>Inicia sesión en BeeBuk</h1>
        <FormContainer>
          <InputContainer>
            <label>Correo Electrónico </label>
            <InputWrapper>
              <input
                type="text"
                placeholder="Tu correo electrónico/numbre de usuario"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete={"off"}
              />
            </InputWrapper>
          </InputContainer>

          <RestoreText>
            Introduce tu dirección correo electrónico para que te enviemos un
            enlace para restablecer tu contraseña
          </RestoreText>

          <ContinueBtn onClick={onSubmit}>Enviar</ContinueBtn>
        </FormContainer>

        <Link to="/login" style={{ textDecoration: "none" }}>
          <p className="sigin-with">
            <span>Volver a Iniciar Sesión</span>
          </p>
        </Link>
      </AuthWrapper>
    </AuthContainer>
  );
}

export default Restore;
