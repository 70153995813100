import { api } from "../utils/api";
import { apiRoutes } from "../constant.routes";
import { useFetch } from "../utils/reactQuery";

export const useGetOrder = (id) => {
  const context = useFetch(
    apiRoutes.apiPrefix + apiRoutes.order.path + apiRoutes.order.get + id,
    undefined,
    { retry: false }
  );
  return { ...context, data: context.data?.order };
};

export const useGetOrders = () => {
  const context = useFetch(
    apiRoutes.apiPrefix + apiRoutes.order.path + apiRoutes.order.list,
    undefined,
    { retry: false }
  );
  return { ...context, data: context.data?.orders };
};

export const filesMessage = (body) =>
  api.post(
    apiRoutes.apiPrefix + apiRoutes.order.path + apiRoutes.order.filesMessage,
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
