import React from "react";
import CategoryCard from "../../components/cards/CategoryCard";
import {
  AllCategoriesContainer,
  AllCategoriesWrapper,
  CategoryCardWrapper,
} from "../../styles/common/categories/AllCategories.styles";
import { faqData } from "../../data/data";

function AllCategories() {
  return (
    <AllCategoriesContainer>
      <AllCategoriesWrapper>
        <h2>Explora los Servicios</h2>
        <CategoryCardWrapper>
          {faqData.map(({ id, title, icon, detail, link }) => (
            <CategoryCard
              key={id}
              title={title}
              icon={icon}
              // detail={detail}
              link={link}
            />
          ))}
        </CategoryCardWrapper>
      </AllCategoriesWrapper>
    </AllCategoriesContainer>
  );
}

export default AllCategories;
