import React, { Fragment } from "react";
import {
  FlexRowStartCenter,
  TextSmall,
  ThirdHeading,
} from "../styles/Common.styles";
import {
  FooterContainer,
  FooterWrapper,
  ListContainer,
  ListItems,
  ListItem,
  FooterBottom,
} from "../styles/layout/Footer.styles";
import LinkedInIcon from "../assets/social/in.svg";
import InstaIcon from "../assets/social/insta.svg";
import FbIcon from "../assets/social/fb.svg";
import TwitterIcon from "../assets/social/twitter.svg";
import ArrowLeftAccount from "../assets/icons/arrow-left-account.svg";
import FooterLogo from "../assets/footer/logo.svg";

function Footer({ margintop }) {
  return (
    <Fragment>
      <FooterContainer margintop={margintop}>
        <FooterWrapper>
          <ListContainer>
            <ThirdHeading>Social</ThirdHeading>
            <ListItems>
              <ListItem>
                <img src={LinkedInIcon} alt="linked-in" />
                <TextSmall>LinkedIn</TextSmall>
              </ListItem>
              <ListItem>
                <img src={InstaIcon} alt="insta" />
                <TextSmall>Instagram</TextSmall>
              </ListItem>
              <ListItem>
                <img src={FbIcon} alt="fb" />
                <TextSmall>Facebook</TextSmall>
              </ListItem>
              <ListItem>
                <img src={TwitterIcon} alt="twitter" />
                <TextSmall>Twitter</TextSmall>
              </ListItem>
            </ListItems>
          </ListContainer>
          <ListContainer>
            <ThirdHeading>Services</ThirdHeading>
            <ListItems>
              <TextSmall>Placeholder</TextSmall>
              <TextSmall>Placeholder</TextSmall>
              <TextSmall>Placeholder</TextSmall>
            </ListItems>
          </ListContainer>
          <ListContainer>
            <ThirdHeading>Account</ThirdHeading>
            <ListItems>
              <FlexRowStartCenter gap="1rem">
                <TextSmall>Signup</TextSmall>
                <img src={ArrowLeftAccount} alt="arrrow-left-account" />
              </FlexRowStartCenter>
              <FlexRowStartCenter gap="1rem">
                <TextSmall>Register</TextSmall>
                <img src={ArrowLeftAccount} alt="arrrow-left-account" />
              </FlexRowStartCenter>
              <FlexRowStartCenter gap="1rem">
                <TextSmall>Contact</TextSmall>
                <img src={ArrowLeftAccount} alt="arrrow-left-account" />
              </FlexRowStartCenter>
            </ListItems>
          </ListContainer>
          <ListContainer>
            <ThirdHeading>Help</ThirdHeading>
            <ListItems>
              <TextSmall>Placeholder</TextSmall>
              <TextSmall>Placeholder</TextSmall>
              <TextSmall>Placeholder</TextSmall>
            </ListItems>
          </ListContainer>
          <ListContainer>
            <ThirdHeading>Resources</ThirdHeading>
            <ListItems>
              <TextSmall>Placeholder</TextSmall>
              <TextSmall>Placeholder</TextSmall>
              <TextSmall>Placeholder</TextSmall>
              <TextSmall>Placeholder</TextSmall>
            </ListItems>
          </ListContainer>
        </FooterWrapper>
      </FooterContainer>

      <FooterBottom>
        <img src={FooterLogo} alt="footer-logo" />
      </FooterBottom>
    </Fragment>
  );
}

export default Footer;
