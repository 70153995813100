import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import AboutUs from "./pages/AboutUs";
import Servicios from "./pages/Servicios";
import Servicio from "./pages/Servicio";
import Checkout from "./pages/Checkout";
import Orders from "./pages/Orders";
import Categories from "./pages/Categories";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ResetPassword from "./pages/ResetPassword";
import ChangePassword from "./pages/ChangePassword";
import PaymenSuccess from "./pages/PaymentSuccess";
import PaymenCancel from "./pages/PaymentCancel";
import Order from "./pages/Order";
import Earnings from "./pages/Earnings";
import MyServices from "./pages/MyServices";
import CreateService from "./pages/CreateService";
import EditService from "./pages/EditService";
import BecomSeller from "./pages/BecomeSeller";
import Profile from "./pages/Profile";
import ProtectedRoute from "./ProtectedRoute";
import { useUser } from "./api/auth";
import { useEffect } from "react";

function App() {
  const { refetch } = useUser();

  useEffect(() => {
    refetch();
  }, [localStorage.getItem("user")]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/restore-password" element={<ResetPassword />} />
        <Route path="/change-password/:token" element={<ChangePassword />} />

        <Route path="/servicios" element={<Categories />} />
        <Route path="/servicios/:category" element={<Servicios />} />
        <Route path="/services/:id" element={<Servicio />} />
        <Route path="/checkout/:id" element={<Checkout />} />

        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/become-seller"
          element={
            <ProtectedRoute>
              <BecomSeller />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <ProtectedRoute>
              <Orders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment/cancel"
          element={
            <ProtectedRoute>
              <PaymenCancel />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment/success"
          element={
            <ProtectedRoute>
              <PaymenSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders/:id"
          element={
            <ProtectedRoute>
              <Order />
            </ProtectedRoute>
          }
        />
        <Route
          path="/balance"
          element={
            <ProtectedRoute>
              <Earnings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/services/manager"
          element={
            <ProtectedRoute>
              <MyServices />
            </ProtectedRoute>
          }
        />
        <Route
          path="/services/create"
          element={
            <ProtectedRoute>
              <CreateService />
            </ProtectedRoute>
          }
        />
        <Route
          path="/services/edit/:id"
          element={
            <ProtectedRoute>
              <EditService />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
